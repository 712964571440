<template>
  <div>
    <div v-if="entity !== null && activeTab !== null && hasPerm('payments.view_invoice')">
      <div class="field-group-block">
        <div class="field-group-header">
          <b-row>
            <b-col cols="2">
              <b>Facturation</b>
            </b-col>
            <b-col cols="2">
              <span
                class="badge badge-warning"
                v-if="availableCreditsTotal"
              >
                Avoirs en cours: {{ currency(availableCreditsTotal) }}
              </span>
            </b-col>
            <b-col
              cols="4"
              class="text-right"
              v-if="showNewSales"
            >
              <a
                class="btn btn-primary btn-sm btn-edit-child"
                href
                @click.prevent="onCreateSale()"
                v-if="hasPerm('payments.add_invoice')"
                :class="editDisabled ? 'disabled' : ''"
              >
                <i class="fa fa-money-bill"></i> Nouvelle vente
              </a>
            </b-col>
            <b-col :cols="showNewSales ? 4 : 8"  class="text-right btns">
              <a
                v-if="!showForm && showAll"
                class="btn btn-secondary btn-sm"
                href
                @click.prevent="printInvoicing()"
              >
                <i class="fas fa-file-pdf"></i> Pdf
              </a>
              <a
                v-if="!showForm && showAll"
                :class="hasXlsElements ? 'btn btn-secondary btn-sm' : 'btn btn-secondary btn-sm disabled'"
                href
                @click.prevent="excelInvoicing()"
              >
                <i class="fas fa-file-excel"></i> Excel
              </a>
              <a
                v-if="showSales && !showForm && showAll"
                href
                @click.prevent="toggleSalesByGroup"
                class="btn btn-sm btn-secondary"
              >
                <i class="fas fa-arrows-spin"></i>&nbsp;
                <span v-if="bySalesGroup">Par période</span>
                <span v-else-if="bySalesMonth">Par mois de vente</span>
                <span v-else>Par mois</span>
              </a>
              <a
                class="btn btn-primary btn-sm btn-edit-child"
                href
                @click.prevent="onEdit()"
                v-if="hasPerm('payments.add_invoice') && showSales && !showForm && showAll"
                :class="editDisabled ? 'disabled' : ''"
              >
                <i class="fas fa-calculator"></i> Facturer
              </a>
            </b-col>
          </b-row>
        </div>
        <div ref="printInvoicing">
          <div ref="excelInvoicing">
            <div class="hide-here field-line">
              <b>{{ entity.fullName() }}</b>
            </div>
            <div v-if="!showForm">
              <div class="field-line field-line-no-border no-print">
                <tabs-bar
                  :tabs="tabs"
                  :active="activeTab"
                  :enabled="!editDisabled"
                  secondary
                  @change="tabChanged($event)"
                ></tabs-bar>
              </div>
              <div v-if="showList">
                <loading-gif :loading-name="invoicesLoadingName"></loading-gif>
                <div v-if="!isLoading(invoicesLoadingName)" class="field-line">
                  <div
                    class="header-line"
                    :class="{ 'no-print': !showInvoices, 'inactive': !showInvoices, }"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b class="clickable" @click.prevent="showInvoices = !showInvoices">
                          <counter-label :counter="visibleInvoices.length" label="facture"></counter-label>
                          <span v-if="cancelledInvoicesCount && !showCancelledInvoices">
                            -
                            <counter-label
                              :counter="cancelledInvoicesCount"
                              label="annulée"
                            ></counter-label>
                          </span>
                          <span class="badge badge-light">
                            Total: {{ invoicesTotal | currency }}
                          </span>
                          <span class="badge badge-danger" v-if="toBePaidTotal">
                            À payer: {{ toBePaidTotal | currency }}
                          </span>
                        </b>
                      </b-col>
                      <b-col cols="3" class="text-right">
                        <b-form-checkbox
                          id="show-cancelled-invoices"
                          v-model="showCancelledInvoices"
                          name="show-cancelled-invoices"
                          class="no-print"
                        >
                          Factures annulées
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </div>
                  <div v-if="showInvoices">
                    <table v-show="false" title="Factures">
                      <tr v-for="invoice in visibleInvoices" :key="invoice.id">
                        <td>{{ invoice.createdOn|dateToString('DD/MM/YYYY') }}</td>
                        <td class="number">{{ invoice.totalPrice|currency }}</td>
                        <td class="number">{{ invoice.paidPrice|currency }}</td>
                      </tr>
                    </table>
                    <div v-if="invoices && visibleInvoices.length === 0" class="field-line">
                      <i>Aucune facture</i>
                    </div>
                    <div
                      v-for="invoice in visibleInvoices"
                      :key="invoice.id"
                      class="field-line"
                      :class="showAll ? '' : 'small-row'"
                    >
                      <b-row>
                        <b-col :cols="memberShown ? 6 : 2">
                          <span v-b-tooltip="invoice.creationTime()">
                            {{ invoice.createdOn|dateToString('DD/MM/YYYY') }}
                          </span>
                        </b-col>
                        <b-col :cols="memberShown ? 6 : 3">
                          <div>Total: {{ invoice.totalPrice|currency }}</div>
                          <div>Payé: {{ invoice.paidPrice|currency }}</div>
                        </b-col>
                        <b-col :cols="memberShown ? 6 : 5">
                          <analytics-detail-view :analytics="invoice.analytics"></analytics-detail-view>
                        </b-col>
                        <b-col :cols="memberShown ? 12 : 2" class="text-right">
                          <invoice-badge :invoice="invoice" :entity="entity" allow-send></invoice-badge>
                          <div v-if="invoice && (invoice.toBePaidPrice() > 0)">
                            <payment-button
                              css-class="btn btn-secondary btn-sm"
                              :invoice="invoice"
                              :entity="entity"
                              @paid="refresh()"
                            ></payment-button>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>

                <div class="field-line">
                  <div
                    class="header-line"
                    :class="{ 'no-print': !showPayments, 'inactive': !showPayments, }"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b class="clickable" @click.prevent="showPayments = !showPayments">
                          <counter-label :counter="payments.length" label="paiement"></counter-label>
                          <span class="badge badge-light">
                            Total: {{ paymentsTotal | currency }}
                          </span>
                        </b>
                      </b-col>
                      <b-col cols="3" class="text-right">
                      </b-col>
                    </b-row>
                  </div>
                  <div v-if="showPayments">
                    <loading-gif :loading-name="paymentsLoadingName"></loading-gif>
                    <div v-if="!isLoading(paymentsLoadingName)">
                      <div v-if="payments && payments.length === 0" class="field-line">
                        <i>Aucun paiement</i>
                      </div>
                      <div class="field-line no-print" v-if="hasPerm('payments.add_payment')">
                        <b-row>
                          <b-col><b>Saisir un paiement</b></b-col>
                          <b-col class="text-right" cols="2">
                            <payment-button
                              css-class="btn btn-secondary btn-sm btn-block"
                              text="Payer"
                              :invoice="null"
                              :entity="entity"
                              @paid="refresh()"
                            ></payment-button>
                          </b-col>
                        </b-row>
                      </div>
                      <table v-show="false" title="Paiements">
                        <tr v-for="payment in payments" :key="payment.id">
                          <td v-if="payment.paymentDate">{{ payment.paymentDate|dateToString('DD/MM/YYYY') }}</td>
                          <td v-else>{{ payment.createdOn|dateToString('DD/MM/YYYY') }}</td>
                          <td class="number">{{ payment.amount|currency }}</td>
                          <td>{{ payment.paymentMode.name }}</td>
                          <td>{{ payment.bankName }}</td>
                          <td>{{ payment.bankNumber }}</td>
                          <td>{{ payment.emitter }}</td>
                          <td>{{ payment.comments }}</td>
                        </tr>
                      </table>
                      <div
                        v-for="payment in payments"
                        :key="payment.id"
                        class="field-line"
                        :class="showAll ? '' : 'small-row'"
                      >
                        <b-row class="hover-me">
                          <b-col :cols="memberShown ? 6 : 2">
                            <span
                              v-if="payment.paymentDate"
                              v-b-tooltip="'Créé le ' + creationDate(payment)"
                            >
                              {{ payment.paymentDate|dateToString('DD/MM/YYYY') }}
                            </span>
                            <span v-else>
                              {{ payment.createdOn|dateToString('DD/MM/YYYY') }}
                            </span>
                            <span v-if="!payment.returned && !payment.deposit.id" class="no-print">
                              <a
                                v-if="canChangePayment"
                                @click.prevent="editPayment(payment)"
                                href
                                class="show-on-hover"
                              >
                                <i class="fa fa-edit" title="Modifier ou supprimer" v-b-tooltip></i>
                              </a>
                            </span>
                            <span class="no-print" v-if="!payment.returned && !payment.deposit.id">
                              <a
                                v-if="canDeletePayment"
                                @click.prevent="deletePayment(payment)"
                                href
                                class="show-on-hover no-print"
                              >
                                <i class="fa fa-hand-point-left"
                                   :title="payment.deposit.id ? 'Refus de la banque' : 'Rendre'"
                                   v-b-tooltip
                                ></i>
                              </a>
                            </span>
                            <span v-if="payment.returned" class="badge badge-danger">
                              {{ payment.getReturnedStatus() }}
                            </span>
                            <span
                              v-else-if="payment.deposit.id"
                              class="badge badge-dark"
                              :title="payment.deposit.label()"
                              v-b-tooltip
                            >
                              déposé
                            </span>
                          </b-col>
                          <b-col :cols="memberShown ? 6 : 2">{{ payment.amount|currency }}</b-col>
                          <b-col :cols="memberShown ? 6 : 2">
                            <span v-if="payment.emitter">émis par {{ payment.emitter }}</span>
                            {{ payment.paymentMode.name }} {{ payment.bankName }} {{ payment.bankNumber }}
                          </b-col>
                          <b-col :cols="memberShown ? 6 : 4">
                            <analytics-detail-view :analytics="payment.analytics"></analytics-detail-view>
                          </b-col>
                          <b-col :cols="memberShown ? 12 : 2" class="text-right">
                            <div v-for="invoice in payment.invoices" :key="invoice.id">
                              <invoice-badge :invoice="invoice"></invoice-badge>
                              <div class="small-text" v-if="invoice.entity.id !== entity.id">
                                {{ invoice.entity.id }} {{ invoice.entity.name }}
                              </div>
                            </div>
                            <credits-badge :credits="payment.credits" :returned="payment.returned"></credits-badge>
                          </b-col>
                        </b-row>
                        <b-row v-if="payment.comments">
                          <b-col>
                            <div class="payment-comments">
                              {{ payment.comments }}
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field-line">
                  <div
                    class="header-line"
                    :class="{ 'no-print': !showCredits, 'inactive': !showCredits, }"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b class="clickable" @click.prevent="showCredits = !showCredits">
                          <counter-label label="avoir" :counter="credits.length"></counter-label>
                        </b>
                      </b-col>
                      <b-col cols="3" class="text-right">
                        <b-form-checkbox
                          id="show-all-credits"
                          v-model="showAllCredits"
                          name="show-all-credits"
                          :value="true"
                          :unchecked-value="false"
                          class="no-print"
                        >
                          Avoirs consommés
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </div>
                  <div v-if="showCredits">
                    <loading-gif :loading-name="creditsLoadingName"></loading-gif>
                    <div v-if="!isLoading(creditsLoadingName)">
                      <div v-if="credits.length === 0" class="field-line">
                        <i>Aucun avoir</i>
                      </div>
                      <div v-else-if="availableCredits.length" class="field-line">
                        <b-row>
                          <b-col>
                            <b>
                              <counter-label
                                :counter="availableCredits.length"
                                label="avoir en cours"
                                label-n="avoirs en cours"
                              >
                              </counter-label>
                              pour un montant de {{ availableCreditsTotal | currency }}
                            </b>
                          </b-col>
                          <b-col cols="3">
                            <a
                              v-if="hasPerm('payments.add_refund')"
                              href @click.prevent="showRefundModal"
                              class="btn btn-secondary btn-sm btn-block"
                            >
                              <i class="fa fa-hand-holding-usd fa-flip-horizontal"></i> Rembourser
                            </a>
                            <refund-modal
                              css-class="btn btn-secondary btn-sm btn-block"
                              :credits="availableCredits"
                              :entity="entity"
                              id="refund-modal"
                              :changer="refundCounter"
                              @refund="refresh()"
                            ></refund-modal>
                          </b-col>
                        </b-row>
                      </div>
                      <table v-show="false" title="Avoirs" v-if="credits.length">
                        <tr v-for="credit in credits" :key="credit.id">
                          <td>{{ credit.createdOn|dateToString('DD/MM/YYYY') }}</td>
                          <td class="number">{{ credit.amount|currency }}</td>
                          <td class="number">{{ credit.remainingAmount|currency }}</td>
                          <td>{{ credit.source }}</td>
                        </tr>
                      </table>
                      <div v-for="credit in credits" :key="credit.id" class="ut-credit field-line">
                        <b-row>
                          <b-col cols="2">
                            <span v-b-tooltip="credit.creationTime()">
                              {{ credit.createdOn|dateToString('DD/MM/YYYY') }}
                            </span>
                          </b-col>
                          <b-col cols="3">
                            <div>
                              Total: {{ credit.amount|currency }}
                              <span
                                v-if="credit.childrenAmount"
                                class="badge badge-dark"
                                title="Montant rendu"
                                v-b-tooltip
                              >
                                {{ credit.childrenAmount|currency }}
                              </span>
                            </div>
                            <div>Restant: {{ credit.remainingAmount|currency }}</div>
                          </b-col>
                          <b-col cols="2" class="small-text">
                            <div v-if="credit.source">
                              {{ credit.source }}
                            </div>
                          </b-col>
                          <b-col cols="3" class="small-text">
                            <analytics-detail-view
                              :analytics="credit.fromAnalytics"
                              from
                              label="Source"
                            >
                            </analytics-detail-view>
                            <analytics-detail-view
                              :analytics="credit.analytics"
                              label="Destination"
                            >
                            </analytics-detail-view>
                          </b-col>
                          <b-col cols="2" class="text-right">
                            <div v-if="credit.refund">
                              <refund-badge :credit="credit"></refund-badge>
                            </div>
                            <div v-for="invoice in credit.invoices" :key="invoice.id">
                              <invoice-badge :invoice="invoice"></invoice-badge>
                              <div class="small-text" v-if="invoice.entity.id !== entity.id">
                                {{ invoice.entity.id }} {{ invoice.entity.name }}
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <div v-if="refunds.length > 0" class="header-line">
                        <b v-if="refunds.length === 1">Remboursement effectué</b>
                        <b v-else>Remboursements effectués</b>
                      </div>
                      <table v-show="false" title="Remboursements" v-if="refunds.length">
                        <tr v-for="refund in refunds" :key="refund.id">
                          <td>{{ refund.createdOn|dateToString('DD/MM/YYYY') }}</td>
                          <td class="number">{{ refund.amount|currency }}</td>
                          <td>{{ refund.refundMode.name }}</td>
                          <td>{{ refund.bankName }}</td>
                          <td>{{ refund.bankNumber }}</td>
                          <td>{{ refund.comments }}</td>
                        </tr>
                      </table>
                      <div v-for="refund in refunds" :key="refund.id" class="ut-refund field-line">
                        <b-row>
                          <b-col cols="2">
                            <span v-b-tooltip="refund.creationTime()">
                              {{ refund.createdOn|dateToString('DD/MM/YYYY') }}
                            </span>
                          </b-col>
                          <b-col cols="2">{{ refund.amount|currency }}</b-col>
                          <b-col cols="3" class="small-text">
                            <div>
                              {{ refund.refundMode.name }}
                              <span v-if="refund.bankName">
                                {{ refund.bankName }} {{ refund.bankNumber }}
                              </span>
                            </div>
                            <div v-if="refund.comments">
                              <i>{{ refund.comments }}</i>
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <analytics-detail-view
                              :analytics="refund.sourceAnalytics()"
                              from
                              label="Source"
                            >
                            </analytics-detail-view>
                            <analytics-detail-view
                              :analytics="refund.destAnalytics()"
                              label="Dons"
                            >
                            </analytics-detail-view>
                          </b-col>
                          <b-col cols="1" class="small-text text-right">
                            <a
                              v-if="hasPerm('payments.delete_refund')" title="Supprimer" v-b-tooltip
                              href @click.prevent="onDeleteRefund(refund)"
                            >
                              <i class="fa fa-trash"></i>
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field-line" v-if="rewards.length">
                  <div
                    class="header-line"
                    :class="{ 'no-print': !showRewards, 'inactive': !showRewards, }"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b class="clickable" @click.prevent="showRewards = !showRewards">
                          <counter-label
                            label="avoir de chantier"
                            label-n="avoirs de chantier"
                            :counter="rewards.length"
                          ></counter-label>
                        </b>
                      </b-col>
                      <b-col cols="3" class="text-right">
                      </b-col>
                    </b-row>
                  </div>
                  <div v-if="showRewards">
                    <div v-if="!isLoading(creditsLoadingName)">
                      <div v-if="availableRewards.length" class="field-line">
                        <b-row>
                          <b-col>
                            <b>
                              <counter-label
                                :counter="availableRewards.length"
                                label="avoir en cours"
                                label-n="avoirs en cours"
                              >
                              </counter-label>
                              pour un montant de {{ availableRewardsTotal | currency }}
                            </b>
                          </b-col>
                          <b-col cols="3">
                          </b-col>
                        </b-row>
                      </div>
                      <table v-show="false" title="Avoirs de chantier" v-if="rewards.length">
                        <tr v-for="reward in rewards" :key="reward.id">
                          <td>{{ reward.createdOn|dateToString('DD/MM/YYYY') }}</td>
                          <td class="number">{{ reward.amount|currency }}</td>
                          <td class="number">{{ reward.remainingAmount|currency }}</td>
                          <td>{{ reward.source }}</td>
                        </tr>
                      </table>
                      <div v-for="reward in rewards" :key="reward.id" class="field-line">
                        <b-row>
                          <b-col cols="2">
                            <span v-b-tooltip="reward.creationTime()">
                              {{ reward.createdOn|dateToString('DD/MM/YYYY') }}
                            </span>
                          </b-col>
                          <b-col cols="3">
                            <div>
                              Total: {{ reward.amount|currency }}
                              <span
                                v-if="reward.childrenAmount"
                                class="badge badge-dark"
                                title="Montant rendu"
                                v-b-tooltip
                              >
                                {{ reward.childrenAmount|currency }}
                              </span>
                            </div>
                            <div>Restant: {{ reward.remainingAmount|currency }}</div>
                          </b-col>
                          <b-col cols="2" class="small-text">
                            <div v-if="reward.source">
                              {{ reward.source }}
                            </div>
                          </b-col>
                          <b-col cols="3" class="small-text">
                            <analytics-detail-view
                              :analytics="reward.fromAnalytics"
                              from
                              label="Source"
                            >
                            </analytics-detail-view>
                            <analytics-detail-view
                              :analytics="reward.analytics"
                              label="Destination"
                            >
                            </analytics-detail-view>
                          </b-col>
                          <b-col cols="2" class="text-right">
                            <div v-for="invoice in reward.invoices" :key="invoice.id">
                              <invoice-badge :invoice="invoice"></invoice-badge>
                              <div class="small-text" v-if="invoice.entity.id !== entity.id">
                                {{ invoice.entity.id }} {{ invoice.entity.name }}
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showSales">
                <loading-gif :loading-name="salesLoadingName"></loading-gif>
                <div v-if="!isLoading(salesLoadingName)">
                  <div v-if="sales && sales.length === 0" class="field-line">
                    Aucune vente à facturer
                  </div>
                  <table v-show="false" v-if="groupedSales.length === 0">
                    <tr><td></td></tr>
                  </table>
                  <table
                    v-show="false"
                    v-for="groupOfSales in groupedSales"
                    :key="groupOfSales.name"
                  >
                    <tr
                      v-for="sale in groupOfSales.sales"
                      :key="sale.id"
                    >
                      <td>{{ sale.createdOn|dateToString('DD/MM/YYYY') }}</td>
                      <td>
                        {{ sale.label }}
                      </td>
                      <td class="number">{{ sale.price|currency }}</td>
                    </tr>
                  </table>
                  <div class="field-line">
                    <b-row>
                      <b-col><b>Total</b></b-col>
                      <b-col><b>{{ sumOfGroupsTotal(false) }}</b></b-col>
                      <b-col cols="1" class="text-right">
                      </b-col>
                    </b-row>
                  </div>
                  <div
                    v-for="groupOfSales in groupedSales"
                    :key="'x' + groupOfSales.name"
                    class="field-line"
                  >
                    <div class="header-line">
                      <b-row>
                        <b-col><b>{{ groupOfSales.name }}</b></b-col>
                        <b-col><b>{{ getGroupOfSalesTotal(groupOfSales, false) }}</b></b-col>
                        <b-col cols="1" class="text-right">
                        </b-col>
                      </b-row>
                    </div>
                    <div
                      v-for="sale in groupOfSales.sales"
                      :key="sale.id"
                      class="field-line"
                      :class="showAll ? '' : 'small-row'"
                    >
                      <b-row>
                        <b-col cols="2">
                          <span v-b-tooltip="sale.creationTime()">
                            {{ sale.createdOn|dateToString('DD/MM/YYYY') }}
                          </span>
                        </b-col>
                        <b-col cols="8">
                          <span
                            class="badge badge-light clickable"
                            v-b-tooltip="saleInfo2(sale)"
                            @click.prevent="showInfo1 = !showInfo1"
                          >
                            {{ saleInfo1(sale) }}
                          </span>
                          {{ sale.label }}
                          <a href class="edit-label" v-if="canChangeSaleLabel" @click.prevent="changeSaleLabel(sale)">
                            <i class="fa fa-edit"></i>
                          </a>
                          <span
                            class="badge badge-warning"
                            title="inscription sur liste d'attente"
                            v-b-tooltip
                            v-if="sale.waiting"
                          >
                            En attente
                          </span>
                          <span
                            class="badge badge-warning"
                            v-if="sale.unconfirmed"
                            title="inscription à confirmer"
                            v-b-tooltip
                          >
                            À confirmer
                          </span>
                          <span class="badge badge-warning" v-if="sale.trying">À l'essai</span>
                          <span class="badge badge-warning" v-if="sale.cancellation">Annulation facturée</span>
                        </b-col>
                        <b-col cols="2" class="text-right">
                          <b>{{ sale.price|currency }}</b>
                          <div class="discounts" v-if="sale.discounts && sale.discounts.length">
                            <div class="badge badge-dark">{{ sale.basePrice | currency }}</div>&nbsp;
                            <div class="badge badge-light" v-for="discount in sale.discounts" :key="discount.id">
                              {{ getDiscountValue(discount) }}
                              <span v-if="discount.comments" v-b-tooltip="discount.comments">
                                &nbsp;<i class="fa fa-info-circle"></i>
                              </span>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showSynthesis">
                <entity-payment-synthesis :entity="entity"></entity-payment-synthesis>
              </div>
            </div>
            <div v-if="showForm">
              <b-form @submit.prevent="onSave()">
                <b-row class="field-line">
                  <b-col>
                    <b>Total</b>
                  </b-col>
                  <b-col>
                    <b>{{ sumOfGroupsTotal(true) }}</b>
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <b-form-checkbox
                      id="all-sales"
                      :checked="areAllSalesChecked()"
                      :indeterminate="areAllSalesIndeterminated()"
                      @change="selectAllSales($event)"
                      class="inline"
                    > Tous
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <div v-for="groupOfSales in groupedSales"
                  :key="groupOfSales.name"
                  class="field-line-group ut-sale-group"
                >
                  <div class="header-line">
                    <b-row>
                      <b-col>
                        <b>{{ groupOfSales.name }}</b>
                      </b-col>
                      <b-col>
                        <b>{{ getGroupOfSalesTotal(groupOfSales, true) }}</b>
                      </b-col>
                      <b-col cols="6" class="text-right">
                        <b-form-checkbox
                          v-if="hasTrying(groupOfSales)"
                          :id="'groupTrying' + groupOfSales.name"
                          :name="'groupTrying' + groupOfSales.name"
                          :checked="selectedTryingGroups[groupOfSales.name]"
                          :indeterminate.sync="indeterminatedTryingGroups[groupOfSales.name]"
                          @change="selectTryingSalesOfGroup(groupOfSales, $event)"
                          class="inline"
                        >
                          à l'essai
                        </b-form-checkbox>
                        <b-form-checkbox
                          v-if="hasUnconfirmed(groupOfSales)"
                          :id="'groupUnconfirmed' + groupOfSales.name"
                          :name="'group' + groupOfSales.name"
                          :checked="selectedUnconfirmedGroups[groupOfSales.name]"
                          :indeterminate.sync="indeterminatedUnconfirmedGroups[groupOfSales.name]"
                          @change="selectUnconfirmedSalesOfGroup(groupOfSales, $event)"
                          class="inline"
                        >
                          à confirmer
                        </b-form-checkbox>
                        <b-form-checkbox
                          v-if="hasWaiting(groupOfSales)"
                          :id="'groupWaiting' + groupOfSales.name"
                          :name="'groupWaiting' + groupOfSales.name"
                          :checked="selectedWaitingGroups[groupOfSales.name]"
                          :indeterminate.sync="indeterminatedWaitingGroups[groupOfSales.name]"
                          @change="selectWaitingSalesOfGroup(groupOfSales, $event)"
                          class="inline"
                        > en attente
                        </b-form-checkbox>
                        <b-form-checkbox
                          :id="'group' + groupOfSales.name"
                          :name="'group' + groupOfSales.name"
                          :checked="selectedGroups[groupOfSales.name]"
                          :indeterminate.sync="indeterminatedGroups[groupOfSales.name]"
                          @change="selectSalesOfGroup(groupOfSales, $event)"
                          class="inline"
                        > Tous
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </div>
                  <div
                    v-for="sale in groupOfSales.sales"
                     v-bind:key="sale.id"
                     class="field-line"
                  >
                    <b-row class="ut-sale-form">
                      <b-col cols="2">{{ sale.createdOn|dateToString('DD/MM/YYYY') }}</b-col>
                      <b-col cols="8">
                        <span
                          class="badge badge-light clickable"
                          v-b-tooltip="saleInfo2(sale)"
                          @click.prevent="showInfo1 = !showInfo1"
                        >
                          {{ saleInfo1(sale) }}
                        </span>
                        {{ sale.label }}
                        <span class="badge badge-warning" v-if="sale.trying">À l'essai</span>
                        <span class="badge badge-warning" v-if="sale.cancellation">Annulation facturée</span>
                        <span
                            class="badge badge-warning"
                            title="inscription sur liste d'attente"
                            v-b-tooltip
                            v-if="sale.waiting"
                          >
                            En attente
                          </span>
                          <span
                            class="badge badge-warning"
                            v-if="sale.unconfirmed"
                            title="inscription à confirmer"
                            v-b-tooltip
                          >
                            À confirmer
                          </span>
                      </b-col>
                      <b-col cols="2" class="text-right">
                        <b>{{ sale.price|currency }}</b>
                        <b-form-checkbox
                          :id="'sale' + sale.id"
                          :checked="selectedSales[sale.id]"
                          :name="'sale' + sale.id"
                          inline
                          class="sale-checkbox"
                          @change="selectSale(sale, $event, groupOfSales)"
                        >
                        </b-form-checkbox>
                        <div class="discounts" v-if="sale.discounts && sale.discounts.length">
                          <div class="badge badge-dark">{{ sale.basePrice | currency }}</div>&nbsp;
                          <div class="badge badge-light" v-for="discount in sale.discounts" :key="discount.id">
                            {{ getDiscountValue(discount) }}
                            <span v-if="discount.comments" v-b-tooltip="discount.comments">
                              &nbsp;<i class="fa fa-info-circle"></i>
                            </span>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <b-row class="buttons-bar">
                  <b-col class="text-right">
                    <a class="btn btn-secondary btn-cancel" href @click.prevent="onCancel">Annuler</a>
                    <b-button type="submit" variant="primary" :disabled="noSelectedSales">
                      Créer la facture <span v-if="totalPrice">de {{ totalPrice | currency }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <invoice-actions-modal
      id="invoice-actions-modal"
      :invoices="newInvoices"
      :entity="entity"
      @close="doInvoiceAction($event)"
    ></invoice-actions-modal>
    <div v-if="editedPayment">
      <edit-payment :payment="editedPayment" :entity="entity" @hidden="onEditPaymentHidden" @done="onRefreshPayments">
      </edit-payment>
    </div>
    <div v-if="deletedPayment">
      <delete-payment :payment="deletedPayment" @hidden="onDeletePaymentHidden" @done="onRefreshPayments">
      </delete-payment>
    </div>
    <create-sale-modal :entity="entity" @done="refresh()"></create-sale-modal>
    <change-sale-label-modal
      :sale="selectedSale"
      id="bv-change-sale-label2-modal"
      @done="onLabelChanged"
    ></change-sale-label-modal>
    <delete-refund-modal
      :refund="selectedRefund" modal-id="delete-refund-modal"
      @done="refresh()"
    ></delete-refund-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import router from '@/router'
import store from '@/store'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import TabsBar from '@/components/Controls/TabsBar'
import AnalyticsDetailView from '@/components/Accounting/AnalyticsDetailView'
import EntityPaymentSynthesis from '@/components/Entity/EntityPaymentSynthesis'
import CreditsBadge from '@/components/Invoices/CreditsBadge'
import InvoiceActionsModal from '@/components/Invoices/InvoiceActionModal'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge'
import PaymentButton from '@/components/Payments/PaymentButton'
import RefundBadge from '@/components/Refund/RefundBadge'
import RefundModal from '@/components/Refund/RefundModal'
import EditPayment from '@/components/Payments/EditPayment'
import DeletePayment from '@/components/Payments/DeletePayment'
import CreateSaleModal from '@/components/Sales/CreateSaleModal'
import ChangeSaleLabelModal from '@/components/Invoices/ChangeSaleLabelModal.vue'
import DeleteRefundModal from '@/components/Refund/DeleteRefundModal.vue'
import { currency, dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeCredit, makeInvoice, makeSale, makePayment, makeRefund } from '@/types/payments'
import { TabItem } from '@/types/tabs'
import { BackendApi, openDocument } from '@/utils/http'
import { sum } from '@/utils/math'
import { slugify } from '@/utils/strings'

const showByKey = 'invoice-create-mode'

export default {
  name: 'entity-invoices',
  components: {
    DeleteRefundModal,
    ChangeSaleLabelModal,
    EntityPaymentSynthesis,
    CreateSaleModal,
    CounterLabel,
    InvoiceActionsModal,
    RefundBadge,
    LoadingGif,
    AnalyticsDetailView,
    CreditsBadge,
    InvoiceBadge,
    PaymentButton,
    RefundModal,
    TabsBar,
    EditPayment,
    DeletePayment,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
    member: {
      type: Object,
      default: null,
    },
    showAll: Boolean,
    initialTab: {
      type: String,
      default: '',
    },
    isFamily: Boolean,
    noNewSales: {
      type: Boolean,
      default: false,
    },
    entityView: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editModeEnabled: true,
      activeTab: null,
      invoices: [],
      sales: [],
      credits: [],
      rewards: [],
      refunds: [],
      payments: [],
      selectedSales: {},
      selectedGroups: {},
      selectedTryingGroups: {},
      selectedWaitingGroups: {},
      selectedUnconfirmedGroups: {},
      indeterminatedGroups: {},
      indeterminatedTryingGroups: {},
      indeterminatedWaitingGroups: {},
      indeterminatedUnconfirmedGroups: {},
      invoicesLoadingName: 'entity-invoices',
      paymentsLoadingName: 'entity-payments',
      salesLoadingName: 'entity-sales',
      creditsLoadingName: 'entity-credits',
      synthesisLoadingName: 'entity-payment-synthesis',
      bySalesGroup: true,
      bySalesMonth: false,
      showAllCredits: false,
      newInvoices: [],
      editedPayment: null,
      deletedPayment: null,
      refundCounter: 0,
      showInvoices: true,
      showPayments: true,
      showCredits: true,
      showRewards: true,
      showAllRewards: true,
      showCancelledInvoices: false,
      showInfo1: true,
      selectedSale: null,
      selectedRefund: null,
    }
  },
  computed: {
    hasXlsElements() {
      return this.showCredits || this.showInvoices || this.showPayments
    },
    showNewSales() {
      return !this.noNewSales && !this.isFamily && this.activeTab.name === 'actives' && !this.showForm && this.showAll
    },
    visibleInvoices() {
      if (this.showCancelledInvoices) {
        return this.invoices
      } else {
        return this.invoices.filter(elt => !elt.isCancelled)
      }
    },
    invoicesTotal() {
      return sum(this.invoices.filter(elt => !elt.isCancelled).map(elt => elt.totalPrice))
    },
    toBePaidTotal() {
      return sum(this.invoices.filter(elt => !elt.isCancelled).map(elt => elt.toBePaidPrice()))
    },
    paymentsTotal() {
      return sum(this.payments.map(elt => elt.amount))
    },
    cancelledInvoicesCount() {
      return this.invoices.filter(elt => elt.isCancelled).length
    },
    availableCredits() {
      return this.credits.filter(elt => elt.isAvailable())
    },
    availableCreditsTotal() {
      return sum(this.availableCredits.map(elt => elt.remainingAmount))
    },
    availableRewards() {
      return this.rewards.filter(elt => elt.isAvailable())
    },
    availableRewardsTotal() {
      return sum(this.availableRewards.map(elt => elt.remainingAmount))
    },
    memberShown() {
      return this.member !== null
    },
    showForm() {
      return (store.getters.editMode === 'invoices')
    },
    editDisabled() {
      return (store.getters.editMode !== '' && store.getters.editMode !== 'invoices')
    },
    entityIndividuals() {
      return this.entity.getMainIndividuals()
    },
    tabs() {
      const tabs = [
        new TabItem('actives', 'En cours', 'far fa-clock'),
        new TabItem('all', 'Historique', 'fa fa-history')
      ]
      if (this.hasPerm('payments.add_invoice')) {
        tabs.push(new TabItem('sales', 'À Facturer', 'fas fa-calculator'))
      }
      tabs.push(new TabItem('synthesis', 'Synthèse', 'fas fa-scale-balanced'))
      return tabs
    },
    showList() {
      return (this.activeTab.name === 'actives') || (this.activeTab.name === 'all')
    },
    showSales() {
      return (this.activeTab.name === 'sales')
    },
    showSynthesis() {
      return (this.activeTab.name === 'synthesis')
    },
    noSelectedSales() {
      for (let sale of this.sales) {
        if (this.selectedSales[sale.id]) {
          return false
        }
      }
      return true
    },
    groupedSales() {
      let groupOfSales = new Map()
      for (let sale of this.sales) {
        let key = '' + sale.saleType.id + '//'
        let month = ''
        if (this.bySalesGroup) {
          key += sale.group.name
        } else if (this.bySalesMonth) {
          month = moment(sale.createdOn).format('YYYY-MM')
          key += month
        } else {
          month = moment(sale.applyOn).format('YYYY-MM')
          key += month
        }
        if (!groupOfSales.has(key)) {
          let name = this.getGroupName(sale)
          let group = {
            name: name,
            month: month,
            sales: [sale],
          }
          groupOfSales.set(key, group)
        } else {
          groupOfSales.get(key).sales.push(sale)
        }
      }
      return Array.from(groupOfSales.values()).sort(
        (elt1, elt2) => {
          if (elt1.month === elt2.month) {
            return elt1.name > elt2.name ? 1 : -1
          } else {
            return elt1.month > elt2.month ? 1 : -1
          }
        }
      )
    },
    canChangePayment() {
      return this.hasPerm('payments.change_payment')
    },
    canDeletePayment() {
      // change et non pas delete
      return this.hasPerm('payments.change_payment')
    },
    totalPrice() {
      let totalPrice = 0
      for (let sale of this.sales) {
        if (this.selectedSales[sale.id]) {
          totalPrice += sale.price
        }
      }
      return totalPrice
    },
    canChangeSaleLabel() {
      return this.hasPerm('payments.delete_sale')
    },
  },
  async mounted() {
    this.bySalesMonth = window.localStorage.getItem(showByKey) === 'bySalesMonth'
    this.bySalesGroup = window.localStorage.getItem(showByKey) === 'bySalesGroup'
    await this.tabChanged(this.getInitialTab())
  },
  watch: {
    entity: async function() {
      await this.tabChanged(this.getInitialTab())
    },
    showAllCredits: function() {
      this.loadCreditsAndRefunds()
    },
    isFamily() {},
    bySalesGroup() {
      if (this.bySalesGroup) {
        window.localStorage.setItem(showByKey, 'bySalesGroup')
      } else {
        if (!this.bySalesMonth) {
          window.localStorage.setItem(showByKey, '')
        }
      }
    },
    bySalesMonth() {
      if (this.bySalesMonth) {
        window.localStorage.setItem(showByKey, 'bySalesMonth')
      } else {
        if (!this.bySalesGroup) {
          window.localStorage.setItem(showByKey, '')
        }
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    currency,
    creationDate(payment) {
      return dateToString(payment.createdOn, 'DD/MM/YYYY')
    },
    getDiscountValue(discount) {
      if (discount.amount) {
        if (discount.amount < 0) {
          return '+' + currency(-discount.amount)
        } else {
          return '' + currency(-discount.amount)
        }
      } else if (discount.percentage) {
        if (discount.percentage < 0) {
          return '+' + (-discount.percentage) + '%'
        } else {
          return '' + (-discount.percentage) + '%'
        }
      }
      return ''
    },
    saleInfo1(sale) {
      if (this.showInfo1) {
        return sale.saleType.name
      } else {
        return sale.analyticInfo()
      }
    },
    saleInfo2(sale) {
      if (!this.showInfo1) {
        return sale.saleType.name
      } else {
        return sale.analyticInfo()
      }
    },
    getInitialTab() {
      let tabIndex = 0
      const initialTab = this.initialTab || this.$route.query.subTab || ''
      if (initialTab) {
        const initialIndex = this.tabs.map(elt => elt.name).indexOf(initialTab)
        if (initialIndex > -1) {
          tabIndex = initialIndex
        }
      }
      return this.tabs[tabIndex]
    },
    async refresh() {
      let showAll = (this.activeTab.name === 'all')
      await this.loadCreditsAndRefunds()
      await this.loadPayments(showAll)
      await this.loadInvoices(showAll)
    },
    editPayment(payment) {
      this.editedPayment = payment
    },
    onEditPaymentHidden() {
      this.editedPayment = null
    },
    onRefreshPayments() {
      let showAll = (this.activeTab.name === 'all')
      this.loadInvoices(showAll)
      this.loadPayments(showAll)
      this.loadCreditsAndRefunds(showAll)
    },
    deletePayment(payment) {
      this.deletedPayment = payment
    },
    onDeletePaymentHidden() {
      this.deletedPayment = null
    },
    async onEdit() {
      if (!this.editDisabled) {
        this.setEditMode('invoices')
      }
    },
    onCancel() {
      this.setEditMode('')
      if (!this.entity.id) {
        this.getBack()
      }
    },
    async onSave() {
      let data = { sales: [], }
      for (let sale of this.sales) {
        if (this.selectedSales[sale.id]) {
          data.sales.push(sale.id)
        }
      }
      let backendApi = new BackendApi('post', '/api/entity/' + this.entity.id + '/create-invoice/')
      this.startLoading(this.salesLoadingName)
      try {
        let resp = await backendApi.callApi(data)
        let sales = resp.data.sales.map(elt => { return makeSale(elt) })
        if (sales.length === 0) {
          await this.tabChanged(this.tabs[0])
        }
        this.sales = sales
        this.newInvoices = resp.data.invoices.map(makeInvoice)
        this.$bvModal.show('invoice-actions-modal')
        this.$emit('invoiced', {})
        this.setEditMode('')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.salesLoadingName)
    },
    getEntityLink() {
      if (this.entity.family) {
        return { name: 'families-detail', params: { entityId: '' + this.entity.id, }, }
      } else {
        return { name: 'entities-detail', params: { entityId: '' + this.entity.id, }, }
      }
    },
    doInvoiceAction(event) {
      this.$bvModal.hide('invoice-actions-modal')
      if (event.action === 'view') {
        const route = { name: 'invoice-detail', params: { invoiceId: event.invoice.id, }, }
        router.push(route)
      }
      if (event.action === 'tab') {
        if (this.entityView) {
          this.tabChanged(this.tabs[0])
        } else {
          router.push(this.getEntityLink())
        }
      }
      this.newInvoices = []
    },
    async loadInvoices(all = false) {
      if ((this.entity) && (this.entity.id > 0)) {
        this.startLoading(this.invoicesLoadingName)
        let url = '/api/entity/' + this.entity.id + '/invoices/?' + (all ? 'all=1' : 'include_unpaid=1')
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.invoices = resp.data.map(elt => { return makeInvoice(elt) })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.invoicesLoadingName)
      }
    },
    async loadCreateInvoiceSales() {
      if ((this.entity) && (this.entity.id > 0)) {
        this.startLoading(this.salesLoadingName)
        let url = '/api/entity/' + this.entity.id + '/create-invoice/'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.sales = resp.data.map(elt => { return makeSale(elt) })
          for (let sale of this.sales) {
            this.selectedSales[sale.id] = !sale.trying && !sale.unconfirmed && !sale.waiting
          }
          for (let group of this.groupedSales) {
            this.updateCheckboxStatus(group)
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.salesLoadingName)
      }
    },
    async loadCreditsAndRefunds() {
      if ((this.entity) && (this.entity.id > 0)) {
        this.startLoading(this.creditsLoadingName)
        await this._doLoadCredits()
        await this._doLoadRefunds()
        await this._doLoadRewards()
        this.endLoading(this.creditsLoadingName)
      }
    },
    async _doLoadCredits() {
      const showAll = this.showAllCredits ? 1 : 0
      let url = '/api/entity/' + this.entity.id + '/credits/?show_all=' + showAll
      if (this.showAll) {
        url += '&all=1'
      }
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.credits = resp.data.map(elt => { return makeCredit(elt) })
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    async _doLoadRefunds() {
      let url = '/api/entity/' + this.entity.id + '/refunds/'
      if (this.showAll) {
        url += '?all=1'
      }
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.refunds = resp.data.map(elt => { return makeRefund(elt) })
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    async _doLoadRewards() {
      const showAll = this.showAllRewards ? 1 : 0
      let url = '/api/entity/' + this.entity.id + '/credits/?rewards=1&show_all=' + showAll
      if (this.showAll) {
        url += '&all=1'
      }
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.rewards = resp.data.map(elt => { return makeCredit(elt) })
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    async loadPayments(showAll) {
      if (this.entity && this.entity.id) {
        this.startLoading(this.paymentsLoadingName)
        let url = '/api/entity/' + this.entity.id + '/payments/'
        if (showAll) {
          url += '?all=1'
        }
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.payments = resp.data.map(makePayment)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.paymentsLoadingName)
      }
    },
    async tabChanged(tab) {
      this.activeTab = tab
      if (this.showSales) {
        await this.loadCreateInvoiceSales()
      } else if (this.showList) {
        const showAll = (this.activeTab.name === 'all')
        await this.loadCreditsAndRefunds()
        await this.loadInvoices(showAll)
        await this.loadPayments(showAll)
      }
    },
    getGroupName(sale) {
      let name = sale.saleType.name + ' - '
      if (this.bySalesGroup && sale.group.name) {
        name += sale.group.name
      } else if (this.bySalesMonth) {
        name += ' vente en ' + moment(sale.createdOn).format('MMMM YYYY')
      } else {
        name += moment(sale.applyOn || sale.createdOn).format('MMMM YYYY')
      }
      return name
    },
    selectSalesOfGroup(groupOfSales, value) {
      let sales = { ...this.selectedSales, }
      for (let sale of groupOfSales.sales) {
        sales[sale.id] = value
      }
      this.selectedSales = sales
      this.updateCheckboxStatus(groupOfSales)
    },
    selectUnconfirmedSalesOfGroup(groupOfSales, value) {
      let sales = { ...this.selectedSales, }
      for (let sale of groupOfSales.sales) {
        if (sale.unconfirmed) {
          sales[sale.id] = value
        }
      }
      this.selectedSales = sales
      this.updateCheckboxStatus(groupOfSales)
    },
    selectWaitingSalesOfGroup(groupOfSales, value) {
      let sales = { ...this.selectedSales, }
      for (let sale of groupOfSales.sales) {
        if (sale.waiting) {
          sales[sale.id] = value
        }
      }
      this.selectedSales = sales
      this.updateCheckboxStatus(groupOfSales)
    },
    selectTryingSalesOfGroup(groupOfSales, value) {
      let sales = { ...this.selectedSales, }
      for (let sale of groupOfSales.sales) {
        if (sale.trying) {
          sales[sale.id] = value
        }
      }
      this.selectedSales = sales
      this.updateCheckboxStatus(groupOfSales)
    },
    updateCheckboxStatus(groupOfSales) {
      let allTrue = true
      let allFalse = true
      let allTryingTrue = true
      let allTryingFalse = true
      let allWaitingTrue = true
      let allWaitingFalse = true
      let allUnconfirmedTrue = true
      let allUnconfirmedFalse = true
      for (let sale of groupOfSales.sales) {
        if (this.selectedSales[sale.id]) {
          allFalse = false
          if (sale.trying) {
            allTryingFalse = false
          }
          if (sale.waiting) {
            allWaitingFalse = false
          }
          if (sale.unconfirmed) {
            allUnconfirmedFalse = false
          }
        } else {
          allTrue = false
          if (sale.trying) {
            allTryingTrue = false
          }
          if (sale.waiting) {
            allWaitingTrue = false
          }
          if (sale.unconfirmed) {
            allUnconfirmedTrue = false
          }
        }
      }
      this.selectedGroups[groupOfSales.name] = allTrue
      this.indeterminatedGroups[groupOfSales.name] = !(allTrue || allFalse)

      this.selectedTryingGroups[groupOfSales.name] = allTryingTrue
      this.indeterminatedTryingGroups[groupOfSales.name] = !(allTryingTrue || allTryingFalse)

      this.selectedWaitingGroups[groupOfSales.name] = allWaitingTrue
      this.indeterminatedWaitingGroups[groupOfSales.name] = !(allWaitingTrue || allWaitingFalse)

      this.selectedUnconfirmedGroups[groupOfSales.name] = allUnconfirmedTrue
      this.indeterminatedUnconfirmedGroups[groupOfSales.name] = !(allUnconfirmedTrue || allUnconfirmedFalse)
    },
    selectSale(sale, value, groupOfSales) {
      this.selectedSales[sale.id] = value
      this.selectedSales = { ...this.selectedSales, }
      this.updateCheckboxStatus(groupOfSales)
    },
    hasTrying(groupOfSales) {
      return groupOfSales.sales.filter(elt => elt.trying).length > 0
    },
    hasWaiting(groupOfSales) {
      return groupOfSales.sales.filter(elt => elt.waiting).length > 0
    },
    hasUnconfirmed(groupOfSales) {
      return groupOfSales.sales.filter(elt => elt.unconfirmed).length > 0
    },
    toggleSalesByGroup() {
      if (this.bySalesGroup) {
        this.bySalesGroup = false
        this.bySalesMonth = true
      } else {
        if (this.bySalesMonth) {
          this.bySalesMonth = false
        } else {
          this.bySalesGroup = true
        }
      }
      for (let group of this.groupedSales) {
        this.updateCheckboxStatus(group)
      }
    },
    sumOfGroupsTotal(showSelected) {
      let total = 0
      let selectedTotal = 0
      for (const groupOfSales of this.groupedSales) {
        for (let sale of groupOfSales.sales) {
          total += sale.price
          if (showSelected && this.selectedSales[sale.id]) {
            selectedTotal += sale.price
          }
        }
      }
      if (!showSelected || (total === selectedTotal)) {
        return currency(total)
      } else {
        return currency(selectedTotal) + ' / ' + currency(total)
      }
    },
    getGroupOfSalesTotal(groupOfSales, showSelected) {
      let total = 0
      let selectedTotal = 0
      for (let sale of groupOfSales.sales) {
        total += sale.price
        if (showSelected && this.selectedSales[sale.id]) {
          selectedTotal += sale.price
        }
      }
      if (!showSelected || (total === selectedTotal)) {
        return currency(total)
      } else {
        return currency(selectedTotal) + ' / ' + currency(total)
      }
    },
    showRefundModal() {
      this.refundCounter += 1
      this.$bvModal.show('refund-modal')
    },
    onCreateSale() {
      this.$bvModal.show('bv-modal-create-sale')
    },
    async printInvoicing() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = slugify(this.activeTab.label + '-' + this.entity.name)
      const docContent = this.$refs.printInvoicing.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent, 'dossier_caf')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelInvoicing() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = slugify(this.activeTab.label + '-' + this.entity.name)
      const docContent = this.$refs.excelInvoicing.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    areAllSalesChecked() {
      for (const groupOfSales of this.groupedSales) {
        for (let sale of groupOfSales.sales) {
          if (!this.selectedSales[sale.id]) {
            return false
          }
        }
      }
      return true
    },
    areAllSalesIndeterminated() {
      let foundTrue = false
      let foundFalse = false
      for (const groupOfSales of this.groupedSales) {
        for (let sale of groupOfSales.sales) {
          if (this.selectedSales[sale.id]) {
            foundTrue = true
          } else {
            foundFalse = true
          }
          if (foundFalse && foundTrue) {
            return true
          }
        }
      }
      return false
    },
    selectAllSales(value) {
      let sales = { ...this.selectedSales, }
      for (const groupOfSales of this.groupedSales) {
        for (let sale of groupOfSales.sales) {
          sales[sale.id] = value
        }
      }
      this.selectedSales = sales
      for (const groupOfSales of this.groupedSales) {
        this.updateCheckboxStatus(groupOfSales)
      }
    },
    changeSaleLabel(sale) {
      this.selectedSale = sale
      const that = this
      this.$nextTick(
        () => {
          that.$bvModal.show('bv-change-sale-label2-modal')
        }
      )
    },
    onLabelChanged(sale) {
      let index = this.sales.map(elt => elt.id).indexOf(sale.id)
      if (index >= 0) {
        this.sales[index].label = sale.label
        this.sales = [].concat(this.sales)
      }
    },
    onDeleteRefund(refund) {
      this.selectedRefund = refund
      const that = this
      this.$nextTick(
        () => {
          that.$bvModal.show('delete-refund-modal')
        }
      )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.hover-me .show-on-hover {
  display: none;
}
.hover-me:hover .show-on-hover {
  display: inline-block;
  font-size: 13px;
  margin-right: 5px;
}
.sale-price {
  font-weight: bold;
}
.sale-checkbox {
  padding-top: 4px;
  margin-left: 5px;
  margin-right: -5px !important;
}
.hover-me > span {
  margin-right: 2px;
}
.field-line .badge {
  margin: 0 5px;
  vertical-align: middle;
}
.edit-label {
  margin:  0 2px;
  font-size: 11px;
}
.payment-comments {
  padding: 4px;
  background: #f0f0f0;
  color: #444;
  font-size: 14px;
}
</style>
