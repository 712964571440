<template>
  <div v-if="entity !== null && entity.id && hasPerm('portal.view_account')">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-form v-if="showForm && hasPerm('portal.change_account')">
      <div class="field-line">
        <b-row>
          <b-col>
            <span>
              <b-form-group
                id="portal-group"
                label=""
                :label-for="'portal' + individual.id"
                description="Si coché, un compte est créé pour l'email sur le portail Famille"
              >
                <b-form-checkbox
                  :id="'portal' + individual.id"
                  :checked="hasAccount(individual)"
                  :name="'portal' + individual.id"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="!individual.email"
                  @change="setAccount(individual, $event)"
                >
                  Compte sur le portail famille
                </b-form-checkbox>
              </b-form-group>
            </span>
            <div v-if="willRemove" class="warning-text">
              Attention, le compte sera supprimé.
            </div>
          </b-col>
        </b-row>
      </div>
    </b-form>
    <div v-if="!showForm">
      <div v-if="account === null" class="field-line">
        Aucun compte existant
      </div>
      <div v-else class="field-line">
        <b-row>
          <b-col>
            <b><i class="fa fa-house-signal"></i> Portail Famille: </b>
            <span v-if="account.user.isActive">Compte actif</span>
            <span v-else>Compte à activer</span>
          </b-col>
          <b-col class="text-right">
            <span v-if="!account.user.isActive">
              <span v-if="hasPerm('portal.add_account')">
                <a
                  href
                  v-if="!notifiedToday(account)"
                  @click.prevent="sendActivationEmail(account)"
                >
                  <i class="fa fa-send"></i> Envoyer l'email d'activation
                </a>
                <div class="small2">
                  <span v-if="!account.notifiedOn">Pas encore notifié</span>
                  <span v-else>Notifié le {{ account.notifiedOn | dateToString('lll') }}</span>
                </div>
              </span>
            </span>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import { BackendMixin } from '@/mixins/backend'
import { dateToString } from '@/filters/texts'
import store from '@/store'
import { makeAccount } from '@/types/portal'
import { makeYouthHome } from '@/types/youth'
import { BackendApi } from '@/utils/http'

export default {
  name: 'individual-accounts',
  components: {
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
    member: Object,
    showForm: Boolean,
    save: Boolean,
  },
  data() {
    return {
      loadingName: 'individual-accounts',
      componentName: 'individual-accounts',
      enabled: false,
      account: null,
      accountsMap: {},
      initialAccountsMap: {},
    }
  },
  computed: {
    individual() {
      return this.member.individual
    },
    willRemove() {
      const initial = this.hasInitialAccount(this.individual)
      const changed = this.hasAccount(this.individual)
      return initial && !changed
    },
  },
  watch: {
    entity: function() { this.loadEntityAccounts() },
    save: function() {
      if (this.save) {
        this.onSave()
      }
    },
  },
  created() {
    this.loadEntityAccounts()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    async onSave() {
      const initial = this.hasInitialAccount(this.individual)
      const changed = this.hasAccount(this.individual)
      if (initial !== changed) {
        const data = {
          individuals: changed ? [this.individual.id] : [],
          only_individuals: [this.individual.id],
        }
        let backendApi = new BackendApi('post', '/api/create-entity-accounts/' + this.entity.id + '/')
        this.startLoading(this.loadingName)
        try {
          let resp = await backendApi.callApi(data)
          if (resp.data.messages.length) {
            await this.addSuccess(resp.data.messages.join('. '))
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.$emit('saved')
        this.endLoading(this.loadingName)
      } else {
        this.$emit('saved')
      }
    },
    async loadEntityAccounts() {
      if (this.entity.id && this.hasPerm('portal.view_account')) {
        let backendApi = new BackendApi('get', '/api/entity-accounts/' + this.entity.id + '/')
        this.startLoading(this.loadingName)
        try {
          let resp = await backendApi.callApi()
          this.enabled = resp.data.enabled
          const accounts = resp.data.accounts.map(
            elt => makeAccount(elt)
          ).filter(
            elt => elt.individual.id === this.individual.id
          )
          if (accounts.length > 0) {
            this.account = accounts[0]
            this.initialAccountsMap[this.account.individual.id] = true
            this.setAccount(this.account.individual, true)
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      } else {
        this.enabled = false
        this.account = null
      }
    },
    hasAccount(individual) {
      if (this.accountsMap.hasOwnProperty(individual.id)) {
        return this.accountsMap[individual.id]
      }
      return false
    },
    hasInitialAccount(individual) {
      if (this.initialAccountsMap.hasOwnProperty(individual.id)) {
        return this.initialAccountsMap[individual.id]
      }
      return false
    },
    setAccount(individual, value) {
      this.accountsMap[individual.id] = value
      this.accountsMap = { ...this.accountsMap, }
    },
    async sendActivationEmail(account) {
      try {
        const backendApi = new BackendApi('post', '/api/send-account-activation/')
        const resp = await backendApi.callApi({ account: account.id, })
        account.notifiedOn = new Date()
        await this.addSuccess(resp.data.message)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    notifiedToday(account) {
      if (account.notifiedOn === null) {
        return false
      } else {
        const dayFormat = 'DD/MM/YYYY'
        return dateToString(account.notifiedOn, dayFormat) === moment().format(dayFormat)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
