
export function unaccent(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function lower(str: string): string {
  return str.toLowerCase()
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function includes(str1: string, str2: string): boolean {
  if (!str1 || !str2) {
    return false
  }
  const elt1 = lower(unaccent(str1))
  const elt2 = lower(unaccent(str2))
  return elt1.indexOf(elt2) >= 0
}

export function countCharacter(str1: string, str2: string): number {
  return str1.split(str2).length - 1
}

export function slugify(str: string): string {
  let slug = lower(unaccent(str))
  return slug.trim()
    .replace(/\s+/g, '-') // space replaces with '-'
    .replace(/[^\w-]+/g, '') // extra chars
}
