<template>
  <span class="counter-label" v-if="!hideZero || counter">
    {{ counterText }}
    <span class="counter-label-text">{{ labelText }}</span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
export default {
  name: 'CounterLabel',
  props: {
    counter: Number,
    label: String,
    labelN: {
      type: String,
      defaultValue: '',
    },
    hideZero: {
      type: Boolean,
      defaultValue: false,
    },
    name0: {
      type: String,
      defaultValue: '0',
    },
  },
  computed: {
    labelText() {
      return (this.counter > 1) ? (this.labelN || this.label + 's') : this.label
    },
    counterText() {
      return (this.counter === 0 && this.name0) ? (this.name0) : ('' + this.counter)
    },
  },
}
</script>
<style scoped lang="less">
</style>
