<template>
  <div class="notes-sidebar" v-if="canViewNotes">
    <b-sidebar
      id="sidebar-notes"
      left shadow width="30%" no-header
      @shown="onSideBarOpened()"
      @hidden="onSideBarClosed()"
    >
      <template #default="{ hide }">
        <div class="px-3 py-2">
          <b-form-checkbox id="include-archived-notes" v-model="includeArchives">
            Inclure les archives
          </b-form-checkbox>
          <ul class="app-menu">
            <li>
              <b-row>
                <b-col><b><i class="fa fa-sticky-note"></i> Notes</b></b-col>
                <b-col cols="6" class="text-right">
                  <a
                    class="btn btn-xs btn-secondary"
                    href
                    @click.prevent="toggleEditMode(true)"
                    v-if="canAddNote && !editMode"
                  >
                    <i class="fa fa-plus"></i> Ajouter une note
                  </a>
                </b-col>
                <b-col cols="1" class="text-right">
                  <i class="fa fa-close a-like" @click="hide"></i>
                </b-col>
              </b-row>
            </li>
          </ul>
          <div v-if="editMode">
            <note-editor
              :note="editedNote"
              @note-added="onNoteAdded"
              @note-saved="onNoteSaved"
              @cancelled="toggleEditMode(false)"
            >
            </note-editor>
          </div>
          <div v-else>
            <loading-gif :loading-name="loadingName"></loading-gif>
            <note-items
              :items="notes"
              :side="true"
              @item-delete="onItemDelete($event)"
              @item-edit="onEditNote($event)"
            ></note-items>
          </div>
        </div>
      </template>
    </b-sidebar>
    <confirm-modal
      name="delete-note-sidebar"
      icon="fa fa-trash"
      title="Suppression d'une note"
      :text="confirmDeleteText"
      :object="selectedNote"
      @confirmed="onDeleteNote"
      ok-variant="danger"
    >
    </confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import store from '@/store'
import NoteEditor from '@/components/Notes/NoteEditor'
import LoadingGif from '@/components/Controls/LoadingGif'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import NoteItems from '@/components/Notes/NoteItems.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeNote } from '@/types/notes'

export default {
  name: 'notes-sidebar',
  components: { LoadingGif, NoteEditor, NoteItems, ConfirmModal, },
  mixins: [BackendMixin],
  props: {
  },
  data() {
    return {
      editMode: false,
      notes: [],
      loadingName: 'side-notes-loading',
      selectedStaff: [],
      selectedNote: null,
      includeArchives: false,
      editedNote: null,
    }
  },
  computed: {
    confirmDeleteText() {
      if (this.selectedNote) {
        if (this.selectedNote.archived) {
          return (
            '"' + this.selectedNote.title + '"\n\n' +
            'Voulez-vous supprimer définitivement cette note?'
          )
        } else {
          return (
            '"' + this.selectedNote.title + '"\n\n' +
            'Voulez-vous archiver cette note?'
          )
        }
      }
      return ''
    },
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    navTitle() {
      return store.getters.navTitle
    },
    navIcon() {
      return store.getters.navIcon
    },
    canViewNotes() {
      return this.isAuthenticated && this.hasPerm('notes.view_note')
    },
    canAddNote() {
      return this.isAuthenticated && this.hasPerm('notes.add_note')
    },
  },
  watch: {
    includeArchives: function() {
      this.loadNotes()
    },
  },
  methods: {
    ...mapMutations(['setNotesUpdated']),
    ...mapActions(['addError']),
    onSideBarOpened() {
      this.loadNotes()
    },
    onSideBarClosed() {
      this.notes = []
      this.editMode = false
    },
    toggleEditMode(value) {
      this.editMode = value
      this.editedNote = null
    },
    async loadNotes() {
      let url = '/notes/api/notes/?path=' + window.location.pathname
      if (this.includeArchives) {
        url += '&include_archives=1'
      }
      this.startLoading(this.loadingName)
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.notes = resp.data.results.map(elt => makeNote(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    // onNotesUpdated() {
    //   this.notesUpdated = true
    //   this.setNotesUpdated(true)
    //   const that = this
    //   this.$nextTick(
    //     () => {
    //       that.setNotesUpdated(false)
    //     }
    //   )
    // },
    onNoteAdded(note) {
      this.editMode = false
      this.notes = [note].concat(this.notes)
      this.$emit('update')
    },
    onEditNote(event) {
      this.editedNote = event
      this.editMode = true
    },
    onNoteSaved(editedNote) {
      const index = this.notes.map(elt => elt.id).indexOf(editedNote.id)
      if (index >= 0) {
        this.notes[index] = editedNote
      }
      this.notes = [].concat(this.notes)
      this.editMode = false
      this.editedNote = null
      this.$emit('update')
    },
    onItemDelete(event) {
      this.selectedNote = event
      this.$bvModal.show('bv-confirm-modal:delete-note-sidebar')
    },
    async onDeleteNote() {
      if (this.selectedNote) {
        this.startLoading(this.loadingName)
        let url = '/notes/api/notes/' + this.selectedNote.id + '/'
        if (this.selectedNote.archived) {
          url += '?include_archives=1'
        }
        const backendApi = new BackendApi('delete', url)
        try {
          await backendApi.callApi()
          this.notes = this.notes.filter(elt => elt.id !== this.selectedNote.id)
          this.$emit('update')
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.selectedNote = null
        this.endLoading(this.loadingName)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.a-like {
  cursor: pointer;
}
.a-like:hover {
  color: #666;
}
</style>
