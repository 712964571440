<template>
  <span :style="block ? 'display: block' : ''" class="phone-link">
    <a v-if="phone" :href="'tel:' + rawPhone()" :class="{ light: light, }">{{ formattedPhone() }}</a>
    <span v-else>{{ defaultValue }}</span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { rawPhone, formattedPhone } from '@/utils/phones'

export default {
  name: 'PhoneLink',
  props: {
    phone: String,
    defaultValue: {
      type: String,
      default: '',
    },
    block: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    rawPhone() {
      return rawPhone(this.phone)
    },
    formattedPhone() {
      return formattedPhone(this.phone)
    },
  },
}
</script>
<style scoped lang="less">
.phone-link a.light {
  color: #fff !important;
}
</style>
