export function isNumber(value: any): boolean {
  if ((value === '') || (value === null) || isNaN(value)) {
    return false
  }
  return true
}

export function isEmailValid(email: string): boolean {
  const emailRegex = /\S+@\S+\.\S+/
  return emailRegex.test(email)
}
