<template>
  <div class="search" v-if="hasPerm('people.view_individual')">
    <b-form @submit="onSearch">
      <b-form-row>
        <b-col cols="12">
          <vue-bootstrap-typeahead
            v-model="searchText"
            :data="[]"
            placeholder="Entrez le nom de famille d'une personne"
            ref="familiesSearchTypeahead"
            @hit="onTypeaheadHit"
            :minMatchingChars="100"
            :disableFiltering="true"
          >
            <template slot="append">
              <b-button type="submit" variant="primary">
                <i class="fa fa-search"></i>
              </b-button>
            </template>
          </vue-bootstrap-typeahead>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeIndividual } from '@/types/people'
import { BackendApi } from '@/utils/http'

export default {
  name: 'SearchIndividual',
  mixins: [BackendMixin],
  data() {
    return {
      searchText: '',
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['setSearchedIndividuals']),
    onSearch(evt) {
      evt.preventDefault()
      this.searchIndividual(this.searchText)
    },
    async searchIndividual(text) {
      if (text) {
        try {
          let url = '/api/people/search-individual/?last_name=' + text
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          let individuals = await resp.data.map(elt => makeIndividual(elt))
          this.setSearchedIndividuals(individuals)
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
    },
    onTypeaheadHit(evt) {

    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
