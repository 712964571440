<template>
  <div v-if="hasPerm('people.view_individual')">
    <div class="content-headline" style="margin-top: 3px;">
      <b-row v-if="!addMode">
        <b-col>
          <div>
            <div class="badges">
              <span :title="getTime(member.individual.createdOn)" v-b-tooltip>
                <b-badge>Créé le {{ member.individual.createdOn | dateToString('L') }}</b-badge>
              </span>
              <span :title="getTime(member.individual.updatedOn)" v-b-tooltip>
                <b-badge>MAJ le {{ member.individual.updatedOn | dateToString('L') }}</b-badge>
              </span>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="border-tags">
            <span class="badge badge-light badge-tags" v-for="tag in tags" :key="tag.id">
              <router-link
                class="link"
                :to="{ name: 'tag-detail', params: { id: tag.id, }, }"
                :title="tag.comments"
                v-b-tooltip
              >
                {{ tag.name }}
              </router-link>
              <a href class="remove-tag" @click.prevent="deleteTag(tag)" v-if="hasPerm('people.delete_tag')">
                <i class="fas fa-times-circle"></i>
              </a>
            </span>
          </div>
        </b-col>
        <b-col cols="3" v-if="member.individual.id && hasPerm('people.add_tag')" class="text-right">
          <add-tags-button :individual="member.individual" @done="getIndividualTags()"></add-tags-button>
        </b-col>
      </b-row>
      <div class="first-block">
        <b-row>
          <b-col>
            <div class="header-bar" v-if="!addMode">
              <b-row>
                <b-col cols="9">
                  <div class="field-line">
                    <span v-if="member.id > 0 || member.legalRepresentativeId !== 0">
                      <b>
                        <span v-if="!isChild">{{ member.individual.fullName() }}</span>
                        <span v-if="isChild">{{ member.individual.firstAndLastName() }}</span>
                      </b>
                      <span
                        class="member-info badge badge-danger"
                        v-if="!sidebarMode && member.legalRepresentativeId !== 0"
                      >
                        Parent séparé
                      </span>
                      <span class="member-info badge badge-warning" v-if="!member.role.isMain">
                        Intervenant
                      </span>
                      <span class="member-info badge badge-dark" v-if="member.individual.deathDate">
                        Décès
                      </span>
                    </span>
                    <span class="new-title" v-else>Nouveau membre</span>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div v-if="!showForm && member.legalRepresentativeId >= 0" class="text-right">
                    <a
                      class="edit-button btn btn-primary btn-sm"
                      href
                      @click.prevent="onEdit"
                      :class="editDisabled ? 'disabled' : ''"
                      v-if="canEdit"
                    >
                      <i class="fa fa-edit"></i> Modifier
                    </a>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <div v-if="!showForm">
          <div class="field-line">
            <b-row>
              <b-col>
                <b><i class="fa fa-venus-mars"></i> </b> {{ getGenderName() }}
              </b-col>
              <b-col class="text-right">
                <span class="badge badge-dark">{{ member.role.name }}</span>
                <div class="small-text" v-if="member.comments">{{ member.comments }}</div>
              </b-col>
            </b-row>
          </div>
          <div v-if="member.role.showChildrenAllowed" class="field-line">
            <span v-if="member.childrenAllowed">
              <i class="fa fa-check-circle"></i> Peut récupérer les enfants
            </span>
            <span v-if="!member.childrenAllowed">
              <i class="fa fa-exclamation-circle"></i> Ne peut pas récupérer les enfants
            </span>
          </div>
          <div class="field-line" v-if="member.role.showBirthDate">
            <b><i class="fa fa-calendar"></i>&nbsp;
              <span v-if="member.individual.isFemale()">Née le</span>
              <span v-else>Né le</span>
            </b>
            {{ member.individual.birthDate | dateToString('LL') }}
            <span v-if="member.individual.birthDate && !member.individual.deathDate">
              ({{ getSafeAge(member.individual.birthDate) }})
            </span>
          </div>
          <div class="field-line" v-if="member.individual.deathDate">
            <b><i class="fa fa-square"></i>&nbsp;
              <span v-if="member.individual.isFemale()">Décédée le</span>
              <span v-else>Décédé le</span>
            </b>
            {{ member.individual.deathDate | dateToString('LL') }}
          </div>
          <div v-if="member.role.hasContact">
            <div class="field-line">
              <b><i class="fas fa-mobile-alt"></i> Téléphone mobile: </b>
              <phone-link :phone="member.individual.cellPhone" default-value="-"></phone-link>
            </div>
            <div class="field-line">
              <b>
                <i class="fa fa-phone"></i> Téléphone
                <span v-if="member.role.isMain">professionnel</span>
                <span v-else>fixe</span>:
              </b>
              <phone-link :phone="member.individual.proPhone" default-value="-"></phone-link>
            </div>
            <div class="field-line">
              <b><i class="fa fa-envelope"></i> Email: </b>
              <email-link :email="member.individual.email" default-value="-"></email-link>
            </div>
            <div class="field-line" v-if="member.role.isMain && member.role.hasContact">
              <b><i class="fa fa-paper-plane"></i> Contact privilégié: </b>
              {{ member.emailContact | yesno }}
            </div>
            <div class="field-line" v-if="member.role.showAcceptNewsletter">
              <b><i class="fa fa-newspaper"></i> Newsletter </b>
              {{ member.individual.email && member.individual.isNewsletter | yesno }}
              <span class="badge-light badge" v-if="member.individual.isNewsletterOn">
                inscrit le {{ member.individual.isNewsletterOn | dateToString('L') }}
              </span>
            </div>
            <individual-accounts
              :entity="entity"
              :member="member"
              :show-form="false"
              v-if="member.role.isMain && !member.role.isChild"
            ></individual-accounts>
          </div>
          <div v-if="member.individual.about" class="field-line">
            <b><i class="fa fa-info-circle"></i> Informations complémentaires</b>
            <div class="comments">
              {{ member.individual.about }}
            </div>
          </div>
          <div v-if="otherEntities.length" class="field-line field-line-highlight">
            <b>
              <i class="fa fa-warning"></i>
              <span v-if="otherEntities.length > 1">Cette personne appartient à d'autres fiches</span>
              <span v-else>Cette personne appartient à une autre fiche</span>
            </b>
            <div v-for="membership of otherEntities" :key="membership.id">
              <router-link :to="getOtherEntityLink(membership)">
                {{ membership.asText() }}
              </router-link>
            </div>
          </div>
        </div>
        <div
          v-if="showForm && member.id === 0 && member.legalRepresentativeId === 0"
        >
          <div v-if="isSearchVisible" class="search-individual-section">
            <b-row >
              <b-col>
                <b-form-group
                  id="search-individual-group"
                  label="Ajouter un membre déjà existant"
                  label-for="search-individual"
                  description="Recherchez une personne existante pour l'ajouter comme nouveau membre de la famille"
                >
                  <search-individual></search-individual>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div v-if="searchedIndividuals !== null">
                  <div v-if="searchedIndividuals.length === 0">
                    Aucune personne avec ce nom
                  </div>
                  <div v-if="memberAlreadyExists">
                    <b>Des personnes avec ce nom existent déjà. Voulez-vous ajouter un membre existant à cette famille?</b>
                  </div>
                  <div
                    v-for="individual in searchedIndividuals" :key="individual.id"
                    class="search-individual-result"
                    :class="individual.id === memberData.individual.id ? 'selected' : ''"
                  >
                    <b-row>
                      <b-col>{{ individual.fullName() }}</b-col>
                      <b-col class="text-right">
                        <div v-if="isAlreadyFamilyMember(individual)">
                          Déjà membre de la famille
                        </div>
                        <div v-else>
                          <a class="btn btn-secondary btn-sm" href @click="selectIndividual($event, individual)"
                            :class="'select-individual' + individual.id"
                          >
                            <i class="fa fa-check"></i> Sélectionner
                          </a>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div
                    v-if="searchedIndividuals.length > 0"
                    class="search-individual-result"
                    :class="0 === memberData.individual.id ? 'selected' : ''"
                  >
                    <b-row>
                      <b-col>Créer une nouvelle personne</b-col>
                      <b-col class="text-right">
                        <div>
                          <a class="btn btn-secondary btn-sm select-new-individual" href @click="selectNewIndividual($event)">
                            <i class="fa fa-check"></i> Sélectionner
                          </a>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row>
              <b-col class="text-right">
                <a
                  href @click.prevent="isSearchVisible = true"
                  class="search-individual-link"
                  :class="dark ? 'light-link' : ''"
                >
                  <i class="fa fa-search"></i> Rechercher un membre existant
                </a>
              </b-col>
            </b-row>
          </div>
        </div>
        <b-form @submit.prevent="onSave" v-if="showForm">
          <div class="field-line">
            <b-row>
              <b-col cols="6">
                <b-form-group
                  id="role-group"
                  label="Rôle*"
                  label-for="role"
                  description=""
                >
                  <b-form-select id="role" v-model="formRole" required v-if="formRole" :rel="formRole.id">
                    <b-form-select-option-group
                      :label="group.text" :value="group" v-for="group in roleGroups"
                      :key="group.text"
                    >
                      <b-form-select-option :value="elt" v-for="elt in group.roles" v-bind:key="elt.id">
                        {{ elt.name }}
                      </b-form-select-option>
                    </b-form-select-option-group>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <div v-if="member.legalRepresentativeId">
                  <b-form-group
                    label-for="legalRepresentativeId"
                    label="Parent séparé de"
                    description="Un changement de rôle ne modifiera que le lien avec cet enfant"
                  >
                    <b-form-input :value="member.comments" type="text" id="legalRepresentativeId" readonly>
                    </b-form-input>
                  </b-form-group>
                  <div class="small2"></div>
                </div>
                <div v-else>
                  <b-form-group
                    id="role-comments-group"
                    label="Commentaires"
                    label-for="roleComments"
                    description=""
                    v-if="formRole.allowComments"
                  >
                    <b-form-input
                      id="roleComments"
                      v-model="memberData.comments"
                      type="text">
                    </b-form-input>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="field-line" v-if="formRole.showChildrenAllowed">
            <b-row>
              <b-col>
                <b-form-group
                  description="cocher si la personne est autorisée à récupérer les enfants"
                >
                  <b-form-checkbox
                    id="childrenAllowed"
                    v-model="memberData.childrenAllowed"
                    name="childrenAllowed"
                    :value="true"
                    :unchecked-value="false"
                  >
                    <span v-if="isFemale">Autorisée</span>
                    <span v-else>Autorisé</span>
                    à récupérer les enfants
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="field-line">
            <b-row>
              <b-col>
                <b-form-group
                  id="gender-group"
                  label="Civilité*"
                  label-for="gender"
                  description=""
                >
                  <b-form-select
                    id="gender" v-model="formGender" required v-if="formGender !== null"
                    :rel="formGender.id || 0" :disabled="!isGenderActive">
                    <b-form-select-option :value="elt" v-for="elt in genders" v-bind:key="elt.id">
                      <span v-if="formRole.isChild">{{ elt.nameForChild }}</span>
                      <span v-else>{{ elt.nameForIndividual }}</span>
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="lastName-group"
                  label="Nom de famille*"
                  label-for="lastName"
                  description=""
                >
                  <b-form-input
                    id="lastName"
                    v-model="memberData.individual.lastName"
                    required
                    type="text"
                    @change="onLastNameChanged"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="firstName-group"
                  label="Prénom*"
                  label-for="firstName"
                  description=""
                >
                  <b-form-input
                    id="firstName"
                    v-model="memberData.individual.firstName"
                    required
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="field-line" v-if="(formRole && formRole.id && formRole.showBirthDate)">
            <b-row>
              <b-col cols="6">
                <b-form-group
                  id="birthDate-group"
                  label="Date de naissance"
                  label-for="birthDate"
                  description=""
                >
                  <b-form-input
                    id="birthDate"
                    v-model="memberData.individual.birthDate"
                    type="date"
                    :min="minBirthDate"
                    :max="maxBirthDate"
                    :required="!!(formRole && formRole.isBirthDateRequired)"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  id="deathDate-group"
                  label="Date de décès"
                  label-for="deathDate"
                  description=""
                  v-if="showDeathDate || memberData.individual.deathDate"
                >
                  <b-form-input
                    id="deathDate"
                    v-model="memberData.individual.deathDate"
                    type="date"
                    :min="minDeathDate"
                    :max="maxDeathDate"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="field-line" v-if="formRole && formRole.hasContact">
            <b-row>
              <b-col>
                <b-form-group
                  id="cellPhone-group"
                  label="Téléphone mobile"
                  label-for="cellPhone"
                  description=""
                >
                  <phone-input
                    id="cellPhone"
                    @isValid="setCellPhoneValid"
                    v-model="memberData.individual.cellPhone">
                  </phone-input>
                  <div v-if="!isCellPhoneValid" class="field-error">Le N° n'est pas valide</div>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="proPhone-group"
                  :label="formRole.isMain ? 'Téléphone professionnel' : 'Téléphone fixe'"
                  label-for="proPhone"
                  description=""
                >
                  <phone-input
                    id="proPhone"
                    @isValid="setProPhoneValid"
                    v-model="memberData.individual.proPhone">
                  </phone-input>
                  <div v-if="!isProPhoneValid" class="field-error">Le N° n'est pas valide</div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="field-line" v-if="formRole && formRole.hasContact">
            <b-row>
              <b-col>
                <b-form-group
                  id="email-group"
                  label="Email"
                  label-for="email"
                  description=""
                >
                  <b-form-input
                    id="email"
                    v-model="memberData.individual.email"
                    type="email"
                  ></b-form-input>
                </b-form-group>
                <div
                  v-if="formRole && formRole.isEmailRecommended && !memberData.individual.email"
                  :class="forceEmptyEmail ? 'email-ok' : 'email-warning'"
                >
                  <div v-if="!forceEmptyEmail">
                    <i class="fa fa-question-circle"></i>
                    Il est recommandé de saisir un email valide pour le rôle {{ formRole.name }}.
                    Voulez-vous garder cet email vide?
                    <a href class="btn btn-xs btn-primary" @click.prevent="forceEmptyEmail = true">
                      Oui, je souhaite garder cet email vide
                    </a>
                  </div>
                  <div v-else>
                    <i class="fa fa-check-circle"></i> Vous souhaitez ne pas saisir d'email pour cette personne
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="field-line" v-if="formRole && formRole.hasContact && formRole.isMain">
            <b-row>
              <b-col>
                <b-form-group
                  id="email-contact-group"
                  label=""
                  label-for="email-contact"
                  description="Si coché, autorise de contacter par email pour l'envoi des factures par exemple"
                >
                  <b-checkbox
                    v-model="memberData.emailContact"
                    id="email-contact"
                    name="email-contact"
                  >
                    Contact privilégié
                  </b-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="field-line" v-if="formRole && formRole.hasContact && formRole.showAcceptNewsletter">
            <b-row>
              <b-col>
                <b-form-group
                  id="newsletter-contact-group"
                  label=""
                  label-for="newsletter-contact"
                  description="Si coché, autorise l'envoi des newsletter"
                >
                  <b-checkbox
                    v-model="memberData.individual.isNewsletter"
                    :value="true"
                    :unchecked-value="false"
                    id="newsletter-contact"
                    name="newsletter-contact"
                  >
                    Inscrit à la newsletter
                  </b-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <individual-accounts
            v-show="member.role.isMain && !member.role.isChild"
            :entity="entity"
            :member="memberData"
            :show-form="true"
            :save="saveAccount"
            @saved="onAccountSaved()"
          ></individual-accounts>
          <div class="field-line" v-if="showAbout">
            <b-row>
              <b-col>
                <b-form-group
                    id="about-group"
                    label="Informations complémentaires"
                    label-for="about"
                    description=""
                >
                  <b-textarea
                    id="about"
                    v-model="memberData.individual.about"
                  ></b-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-row class="buttons-bar" v-if="!addMode">
            <b-col cols="7" class="text-left">
              <a class="btn btn-danger" href @click="confirmDelete" v-if="member.id">Supprimer</a>
              &nbsp;
              <a href @click.prevent="showAbout = true" v-if="!showAbout" class="show-about">
                Ajouter des informations complémentaires
              </a>
              <b-modal id="bv-modal-delete" size="lg">
                <template v-slot:modal-title>
                  Suppression
                </template>
                <div class="d-block">
                  <p><b>Êtes-vous sûr de supprimer {{ member.individual.fullName() }}?</b></p>
                  <ul>
                    <li>
                      Si la personne est décédée, vous pouvez simplement saisir sa date de décès.
                      Elle restera visible dans les listings mais ne sera plus contactable.
                    </li>
                    <li>
                      Si vous retirez cette personne, elle pourra être ajoutée plus tard à une autre structure ou famille
                    </li>
                    <li>
                      Si vous supprimez définitivement cette personne, elle ne sera plus accessible
                    </li>
                  </ul>
                </div>
                <template v-slot:modal-footer>
                  <div class="text-right delete-modal-footer">
                    <b-button
                      class="btn-sm"
                      @click="cancelDelete"
                    >
                      Annuler
                    </b-button>
                    <b-button
                      class="btn-sm"
                      variant="primary"
                      @click.prevent="onDeath()"
                    >
                      Décès
                    </b-button>
                    <b-button
                      class="btn-sm"
                      variant="warning"
                      @click.prevent="onDelete(false)"
                    >
                      Retirer
                    </b-button>
                    <b-button
                      class="btn-sm"
                      variant="danger"
                      @click.prevent="onDelete(true)"
                    >
                      Supprimer définitivement
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col>
            <b-col class="text-right">
              <a class="btn btn-secondary" href @click.prevent="onCancel">Annuler</a>
              <b-button type="submit" variant="primary" :disabled="!isSaveActive">
                Enregistrer
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <confirm-modal
      :name=deleteTagModalId
      :object="selectedTag"
      title="Supprimer de la liste"
      :text="getDeleteTagMessage()"
      @confirmed="onDeleteTagConfirmed"
    ></confirm-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import PhoneLink from '@/components/Controls/Links/PhoneLink'
import PhoneInput from '@/components/Controls/PhoneInput'
import EmailLink from '@/components/Controls/Links/EmailLink'
import IndividualAccounts from '@/components/Individual/IndividualAccounts'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import AddTagsButton from '@/components/Tags/AddTagsButton'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeFamilyMembership } from '@/types/families'
import {
  makeEntityMembership, makeGender, makeEntityRole, makeIndividual, GENDER_FEMALE, makeTag,
  makeIndividualEntityMembership, makeTagMembership
} from '@/types/people'
import SearchIndividual from './SearchIndividual'
import { fixDate, getSafeAge } from '@/utils/dates'
import { BackendApi } from '@/utils/http'

export default {
  name: 'individual-identity',
  components: {
    IndividualAccounts,
    AddTagsButton,
    SearchIndividual,
    EmailLink,
    PhoneLink,
    PhoneInput,
    ConfirmModal,
  },
  mixins: [BackendMixin],
  props: {
    isFamily: Boolean,
    member: Object,
    entity: Object,
    addMode: {
      type: Boolean,
      defaultValue: false,
    },
    forceSave: {
      type: Boolean,
      defaultValue: false,
    },
    sidebarMode: {
      type: Boolean,
      defaultValue: false,
    },
    dark: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      formRole: null,
      formGender: null,
      searchedIndividuals: null,
      memberAlreadyExists: false,
      isCellPhoneValid: false,
      isProPhoneValid: false,
      isEmailValid: false,
      tags: [],
      selectedTag: null,
      forceEmptyEmail: false,
      otherEntities: [],
      showAbout: false,
      memberData: makeEntityMembership(),
      isSearchVisible: true,
      saveAccount: false,
      savedMembership: null,
      saveInProgress: false,
      showDeathDate: false,
    }
  },
  mounted() {
    this.init()
  },
  created() {
    this.formRole = makeEntityRole({})
    this.formGender = makeGender({})
    store.watch(
      (state, getters) => getters.searchedIndividuals,
      (newValue, oldValue) => {
        if (newValue) {
          this.searchedIndividuals = newValue
        }
      }
    )
  },
  computed: {
    birthDate() {
      if (this.memberData && this.memberData.individual) {
        return this.memberData.individual.birthDate
      }
      return null
    },
    minBirthDate() {
      return moment().subtract(100, 'years').format('YYYY-MM-DD')
    },
    maxBirthDate() {
      return moment().format('YYYY-MM-DD')
    },
    minDeathDate() {
      if (this.memberData && this.memberData.individual && this.memberData.individual.birthDate) {
        return moment(this.memberData.individual.birthDate).format('YYYY-MM-DD')
      }
      return null
    },
    maxDeathDate() {
      return moment().format('YYYY-MM-DD')
    },
    deleteTagModalId() {
      const memberId = (this.member && this.member.individual.id) ? this.member.individual.id : 0
      return 'deleteTag' + memberId
    },
    members() {
      return this.entity.memberships
    },
    genders() {
      return store.getters.genders
    },
    roleGroups() {
      let roles = store.getters.roles || []
      roles = roles.filter(elt => this.isFamily ? elt.familyRole : !elt.familyRole).sort(
        (eltA, eltB) => { return eltA.id === eltB.id ? 0 : (eltA.id > eltB.id ? 1 : -1) }
      )
      let roleGroups = []
      if (this.isFamily) {
        if (this.addMode) {
          roleGroups = [{ text: 'Adultes', roles: [], }]
          for (let role of roles) {
            if (!role.isChild && role.isMain) {
              roleGroups[0].roles.push(role)
            }
          }
        } else {
          roleGroups = [
            { text: 'Enfants', roles: [], },
            { text: 'Adultes', roles: [], },
            { text: 'Intervenants', roles: [], }
          ]
          for (let role of roles) {
            if (role.isChild) {
              roleGroups[0].roles.push(role)
            } else if (role.isMain) {
              roleGroups[1].roles.push(role)
            } else {
              roleGroups[2].roles.push(role)
            }
          }
        }
      } else {
        roleGroups = [
          { text: 'Membres', roles: roles, }
        ]
      }
      return roleGroups
    },
    isChild() {
      if (this.formRole && this.formRole.id) {
        return this.formRole.isChild
      } else {
        return this.member.role.isChild
      }
    },
    showForm() {
      return this.addMode || this.editMode === 'individual-identity'
    },
    isGenderActive() {
      if (this.formRole) {
        return this.formRole.gender === 0
      }
      return true
    },
    emailNotValid() {
      return (
        this.formRole.isEmailRecommended &&
        !this.memberData.individual.email &&
        !this.forceEmptyEmail
      )
    },
    isSaveActive() {
      let isActive = (
        (this.formRole) && (this.formRole.id) && (this.memberData.individual.firstName) &&
        (this.memberData.individual.lastName)
      )
      if (isActive && this.formRole.isBirthDateRequired) {
        isActive = !!(this.memberData.individual.birthDate)
      }
      if (isActive && this.emailNotValid) {
        isActive = false
      }
      return isActive && !this.saveInProgress
    },
    editMode() {
      return store.getters.editMode
    },
    editDisabled() {
      return this.addMode ? false : (this.editMode !== '')
    },
    isFemale() {
      return (this.formGender.id === GENDER_FEMALE)
    },
    canEdit() {
      return this.hasPerm('people.change_individual')
    },
    disableCheckExistingIndividuals() {
      return store.getters.config.disableCheckExistingIndividuals
    },
  },
  watch: {
    formRole: function(newRole, oldRole) {
      if (newRole && newRole.gender !== 0) {
        this.formGender = this.getGender(newRole.gender)
      }
      if (newRole && newRole.hasChildrenAllowed()) {
        if ((this.formRole === null) || (this.member === null) || (this.formRole.id !== this.member.role.id)) {
          this.memberData.childrenAllowed = newRole.checkChildrenAllowed
        }
      }
    },
    member: function() {
      this.forceEmptyEmail = false
      this.init()
    },
    isSaveActive: function(value) {
      this.$emit('is-valid-changed', { isValid: !!value, })
    },
    forceSave(value) {
      if (value) {
        this.onSave()
      }
    },
  },
  methods: {
    getSafeAge,
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['setEditMode', 'setSearchedIndividuals']),
    init() {
      this.showDeathDate = false
      if (this.addMode || (this.member && (this.member.individual.id === 0))) {
        this.isSearchVisible = false
        this.onEdit()
        if (this.entity.memberships.length === 0) {
          for (const roleGroup of this.roleGroups) {
            for (const role of roleGroup.roles) {
              if (this.isFamily && role.isDefault) {
                this.formRole = role
                break
              }
              if (!this.isFamily && role.isMain) {
                this.formRole = role
                break
              }
            }
          }
        }
      } else {
        this.getIndividualTags()
        this.loadOtherEntities()
      }
    },
    initRole(expectedRole) {
      for (const roleGroup of this.roleGroups) {
        for (const role of roleGroup.roles) {
          if (role.id === expectedRole.id) {
            return role
          }
        }
      }
      return makeEntityRole({})
    },
    onLastNameChanged() {
      this.$emit('last-name-changed', { individual: this.memberData.individual, })
    },
    onAccountSaved() {
      this.saveAccount = false
      if (!this.forceSave) {
        if (this.savedMembership) {
          const existingIndex = this.entity.memberships.map(elt => elt.id).indexOf(
            this.savedMembership.id
          )
          if (existingIndex >= 0) {
            this.entity.memberships[existingIndex] = this.savedMembership
            this.entity.memberships = [].concat(this.entity.memberships)
          } else {
            this.entity.memberships.push(this.savedMembership)
          }
        }

        this.setEditMode('')
        this.addSuccess('La personne a été enregistrée')
        this.formRole = makeEntityRole({})
        this.formGender = makeGender({})
      }
      this.$emit(
        'change',
        {
          entity: this.entity,
          member: this.savedMembership,
          individual: this.member.individual,
        }
      )
    },
    onEdit() {
      this.forceEmptyEmail = false
      if (!this.editDisabled) {
        if (!this.addMode) {
          this.setEditMode('individual-identity')
        }
        let individual = this.member.individual || null
        let role = this.member.role || null
        this.memberData = { ...this.member, }
        if (individual) {
          this.memberData.individual = { ...individual, }
        }
        if (role) {
          this.memberData.role = { ...role, }
        }
        if (this.entity && this.memberData.individual.lastName === '') {
          this.memberData.individual.lastName = this.entity.name
        }
        this.formGender = this.memberData.individual.gender
        this.formRole = this.initRole(this.memberData.role)
        this.showAbout = this.memberData.individual.about
      }
    },
    confirmDelete(evt) {
      evt.preventDefault()
      this.$bvModal.show('bv-modal-delete')
    },
    cancelDelete(evt) {
      this.$bvModal.hide('bv-modal-delete')
    },
    getGender(value) {
      for (let gender of this.genders) {
        if (gender.id === value) {
          return gender
        }
      }
      return null
    },
    async getIndividualTags() {
      this.tags = []
      if (this.member.individual.id && this.hasPerm('people.view_tag')) {
        const url = '/api/people/get-tags/' + this.member.individual.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.tags = resp.data.map(elt => makeTagMembership(elt))
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
    },
    async loadOtherEntities() {
      this.otherEntities = []
      if (this.member.individual.id) {
        const url = '/api/people/individual-entities/' + this.member.individual.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.otherEntities = resp.data.map(
            elt => makeIndividualEntityMembership(elt)
          ).filter(
            elt => elt.entity.id !== this.entity.id
          )
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    removeMember() {
      const index = this.entity.memberships.map(elt => elt.id).indexOf(this.member.id)
      if (index >= 0) {
        this.entity.memberships = this.entity.memberships.splice(index, 1)
      }
    },
    onDeath() {
      this.showDeathDate = true
      this.$bvModal.hide('bv-modal-delete')
    },
    async onDelete(archiveIndividual) {
      this.$bvModal.hide('bv-modal-delete')
      let backendApi = new BackendApi('delete', '/api/people/memberships/' + this.member.id + '/')
      try {
        await backendApi.callApi({ 'archive_individual': archiveIndividual, })
        this.removeMember()
        if (archiveIndividual) {
          await this.addSuccess('La personne a été supprimée')
        } else {
          await this.addSuccess('La personne a été retirée')
        }
        this.formRole = makeEntityRole({})
        this.formGender = makeGender({})
        this.setEditMode('')
        this.$emit('deleted', {})
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onCancel() {
      this.setEditMode('')
      this.formRole = makeEntityRole({})
      this.formGender = makeGender({})
      this.forceEmptyEmail = false
      this.$emit('change', { entity: this.entity, member: this.member, })
    },
    async checkAlreadyExist() {
      this.memberAlreadyExists = false
      try {
        let url = '/api/people/search-individual/?last_name=' + this.memberData.individual.lastName +
          '&first_name=' + this.memberData.individual.firstName
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        let individuals = await resp.data.map(elt => makeIndividual(elt))
        if (individuals.length === 0) {
          await this.doSave()
          this.memberAlreadyExists = false
        } else {
          this.memberAlreadyExists = true
          this.isSearchVisible = true
          this.setSearchedIndividuals(individuals)
          this.$emit('failed', { error: 'Une personne existe déjà avec ce nom', })
        }
      } catch (err) {
        const error = this.getErrorText(err)
        await this.addError(error)
        this.$emit('failed', { error: error, })
      }
    },
    async doSave() {
      let birthDate = null
      let deathDate = null
      if (this.memberData.individual.birthDate) {
        birthDate = fixDate(this.memberData.individual.birthDate)
      }
      if (this.memberData.individual.deathDate) {
        deathDate = fixDate(this.memberData.individual.deathDate)
      }
      let emailContact = this.memberData.emailContact
      if (this.formRole) {
        if (!this.formRole.isMain || !this.formRole.hasContact) {
          emailContact = false
        }
      }
      const data = {
        entity: this.entity.id,
        role: (this.formRole) ? this.formRole.id : 0,
        children_allowed: this.formRole.showChildrenAllowed ? this.memberData.childrenAllowed : false,
        comments: this.formRole.allowComments ? this.memberData.comments : '',
        email_contact: emailContact,
        individual: {
          id: this.memberData.individual.id || 0,
          last_name: this.memberData.individual.lastName,
          first_name: this.memberData.individual.firstName,
          birth_date: birthDate,
          death_date: deathDate,
          gender: this.formGender.id,
          about: this.memberData.individual.about,
          pro_phone: this.memberData.individual.proPhone,
          cell_phone: this.memberData.individual.cellPhone,
          email: this.memberData.individual.email,
          is_newsletter: this.memberData.individual.isNewsletter,
        },
      }
      let baseUrl
      let backendApi
      let maker
      if (this.isFamily) {
        baseUrl = '/api/families/memberships/'
        maker = makeFamilyMembership
      } else {
        baseUrl = '/api/people/memberships/'
        maker = makeEntityMembership
      }
      if (this.member.id) {
        backendApi = new BackendApi('patch', baseUrl + this.member.id + '/')
      } else if (this.member.legalRepresentativeId > 0) {
        baseUrl = '/api/families/save-legal-representative/' + this.member.legalRepresentativeId + '/'
        backendApi = new BackendApi('post', baseUrl)
        maker = null
      } else {
        backendApi = new BackendApi('post', baseUrl)
      }
      try {
        let resp = await backendApi.callApi(data)
        let membership = null
        if (maker) {
          membership = maker(resp.data)
        }
        this.savedMembership = membership
        this.saveAccount = true
      } catch (err) {
        const error = this.getErrorText(err)
        this.$emit('failed', { error: error, })
        await this.addError(error)
      }
    },
    async onSave() {
      if (!this.saveInProgress) {
        this.saveInProgress = true
        if (!this.emailNotValid) {
          if ((this.member.id !== 0) || (this.member.legalRepresentativeId > 0)) {
            // Existing member : save it
            await this.doSave()
          } else {
            if (this.memberAlreadyExists || this.disableCheckExistingIndividuals) {
              // The duplicates warning have been displayed or is disabled. Let's go
              await this.doSave()
              this.memberAlreadyExists = false
            } else {
              // New member : make sure if there is no duplicates
              await this.checkAlreadyExist()
            }
          }
        }
        this.saveInProgress = false
      }
    },
    getGenderName() {
      if (this.isChild) {
        return this.member.individual.gender.nameForChild
      } else {
        return this.member.individual.gender.nameForIndividual
      }
    },
    isAlreadyFamilyMember(individual) {
      for (let membership of this.members) {
        if (membership.individual.id === individual.id) {
          return true
        }
      }
      return false
    },
    selectIndividual(event, individual) {
      event.preventDefault()
      event.stopPropagation()
      this.memberData.individual = { ...individual, }
      this.formGender = { ...individual.gender, }
      this.memberAlreadyExists = true
    },
    selectNewIndividual(event) {
      event.preventDefault()
      event.stopPropagation()
      this.memberData.individual.id = 0
      this.memberData.individual.about = ''
      this.memberData.individual.birthDate = null
      this.memberData.individual.deathDate = null
      this.memberAlreadyExists = true
    },
    setCellPhoneValid(evt) {
      this.isCellPhoneValid = evt
    },
    setProPhoneValid(evt) {
      this.isProPhoneValid = evt
    },
    deleteTag(tag) {
      this.selectedTag = tag
      this.$bvModal.show('bv-confirm-modal:' + this.deleteTagModalId)
    },
    getDeleteTagMessage() {
      if (this.selectedTag) {
        return 'Voulez vous supprimer ' + this.member.fullName() + ' de la liste ' + this.selectedTag.name + '?'
      } else {
        return ''
      }
    },
    async onDeleteTagConfirmed(event) {
      if (event.object) {
        let url = '/api/people/delete-tags/'
        const backendApi = new BackendApi('delete', url)
        const data = {
          'individual': this.member.individual.id,
          'tag': event.object.id,
        }
        try {
          const resp = await backendApi.callApi(data)
          this.addSuccess(resp.data.message)
          await this.getIndividualTags()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    getOtherEntityLink(membership) {
      let name = 'entities-detail'
      if (membership.role.familyRole) {
        name = 'families-detail'
      }
      return {
        name: name,
        params: { entityId: '' + membership.entity.id, },
        query: { individual: this.member.individual.id, },
      }
    },
    getTime(value) {
      if (value) {
        return dateToString(value, 'LT')
      }
      return ''
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.comments {
  padding: 4px;
  background: #ececec;
}
.search-individual-section {
  padding: 10px;
}
.search-individual-result {
  padding: 5px;
}
.search-individual-result:nth-child(even) {
  background: #e8e8e8;
  color: #222;
}
.search-individual-result .fa-check {
  color: transparent;
}
.search-individual-result.selected {
  background: #444;
  color: #fff;
}
.search-individual-result.selected .fa-check {
  color: #444;
}
.display-tags-title{
  margin-top: 5px;
  margin-left: 15px;
}
.remove-tag{
  margin-left: 4px;
  color: #888 !important;
}
.remove-tag:hover{
  color: #222 !important;
}
.border-tags{
}
.badge-tags{
  margin-right: 5px;
  padding: 8px;
  background: #fbffae !important;
  border-color: #888 !important;
  cursor: default;
}
.badge-tags .link {
  color: #222 !important;
}
.email-warning {
  font-weight: bold;
  padding: 5px;
  background: #fbffae;
  color: #222;
}
.email-ok {
  font-weight: bold;
  padding: 5px;
  background: #c0ffbc;
  color: #222;
}
.field-line-highlight {
  background: #ccc;
}
.field-line-highlight a{
  color: #222 !important;
  text-decoration: underline !important;
}
.member-info {
  margin: 0 5px;
  vertical-align: top;
}
.search-individual-link {
  margin: 2px 5px;
  font-size: 12px;
}
.delete-modal-footer button {
  margin-left: 10px;
}
.badge-light {
  vertical-align: top;
}
</style>
