<template>
  <span class="delete-refund-modal" v-if="refund && hasPerm('payments.delete_refund')">
    <b-modal dialog-class="modal-xl"
      :id="modalId"
      cancel-title="Annuler"
      @ok.prevent="onDeleteRefund"
      ok-variant="danger"
      ok-title="Supprimer"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-money-bill"></i> Supprimer un remboursement</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b>Êtes-vous sûr de vouloir supprimer le remboursement de {{ refund.amount | currency }}
          du {{ refund.createdOn | dateToString }}?
          </b>
          <div>
            Cette opération ne peut pas être annulée. Ne supprimer un remboursement qu'en cas d'erreur manifeste et
            jamais pour saisir un nouveau paiement.
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="donations.length === 0">
            <i class="fa fa-info-circle"></i> Aucun don n'est associé à ce remboursement.
          </div>
          <div v-else>
            <i class="fa fa-warning"></i>
            <counter-label label="don est associé" label-n="dons sont associés" :counter="donations.length">
            </counter-label> à ce remboursement:
            <ul>
              <li v-for="donation of donations" :key="donation.id">
                {{ donation.label }} du {{ donation|dateToString }} de {{ donation.price | currency }}
              </li>
            </ul>
            <div>
              <b-form-group
                id="keep-donations-gr"
                label=""
                label-for="keep-donations"
                description="Si vous décochez la case cochée, alors les dons seront annulés et l'avoir entièrement restitué"
              >
                <b-form-checkbox id="keep-donations" v-model="keepDonations">
                  Conserver les dons
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { BackendApi } from '@/utils/http'
import { makeSale } from '@/types/payments'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel.vue'

export default {
  name: 'delete-refund-modal',
  components: { CounterLabel, },
  mixins: [BackendMixin],
  props: {
    refund: Object,
    modalId: String,
  },
  data() {
    return {
      keepDonations: true,
      donations: [],
      errorText: '',
    }
  },
  computed: {
  },
  watch: {
    refund: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      this.loadRefundDonations()
    },
    async onDeleteRefund() {
      this.errorText = ''
      try {
        let url = '/api/delete-refund/' + this.refund.id + '/'
        const backendApi = new BackendApi('post', url)
        let data = {
          keep_donations: this.keepDonations,
        }
        await backendApi.callApi(data)
        this.$bvModal.hide(this.modalId)
        this.$emit('done')
        this.addSuccess('Le remboursement a été supprimé')
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    async loadRefundDonations() {
      if (this.refund) {
        try {
          let url = '/api/delete-refund/' + this.refund.id + '/'
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.donations = resp.data.sales.map(makeSale)
          this.keepDonations = true
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      } else {
        this.donations = []
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
</style>
