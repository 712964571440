import moment from 'moment'
import { SchoolYear } from '@/types/schools'

export function fixDate(date: string): string {
  const elements: number[] = date.split('-').map(elt => +elt).filter(elt => !isNaN(elt))
  if (elements.length < 3) {
    return ''
  }
  let year = elements[0]
  const month = elements[1]
  const day = elements[2]
  if (year < 100) {
    const currentYear = +(moment().format('YY'))
    if (year <= currentYear) {
      year += 2000
    } else {
      year += 1900
    }
  }
  const dateMonth = month - 1
  const newDate = new Date(year, dateMonth, day)
  if (((newDate.getMonth()) === dateMonth) && (newDate.getDate() === day)) {
    return moment(newDate).format('YYYY-MM-DD')
  } else {
    return ''
  }
}

export function getAgeValue(birthDate: any, onDate: any = null): number|null {
  if (birthDate) {
    const refDate = onDate ? moment(onDate) : moment()
    return refDate.diff(moment(birthDate), 'years')
  }
  return null
}

export function getMonthAgeValue(birthDate: any, onDate: any = null): number|null {
  if (birthDate) {
    const refDate = onDate ? moment(onDate) : moment()
    if (refDate.diff(moment(birthDate), 'days') >= 0) {
      return refDate.diff(moment(birthDate), 'months')
    }
  }
  return null
}

export function getSchoolLevelAge(birthDate: any, schoolYear: SchoolYear): number|null {
  if (birthDate) {
    const refDate = moment('' + (schoolYear.startYear) + '-12-31')
    return refDate.diff(moment(birthDate), 'years')
  }
  return null
}

export function getAge(birthDate: any): string {
  const age = getAgeValue(birthDate)
  if (age !== null) {
    return '' + age + ' ' + ((age > 1) ? 'ans' : 'an')
  }
  return ''
}

export function getBabyAge(birthDate: any, onDate: any = null): string {
  const monthAge = getMonthAgeValue(birthDate, onDate)
  if (monthAge !== null) {
    let theMonthAge = monthAge || 0
    if (theMonthAge >= 0) {
      let age = ''
      if (theMonthAge >= 12) {
        let yearAge = Math.floor(theMonthAge / 12)
        if (yearAge > 1) {
          age = yearAge + ' ans'
        } else {
          age = '1 an'
        }
      }
      let months = theMonthAge % 12
      if (months > 0) {
        age += age ? ' et ' : ''
        age += months + ' mois'
      }
      if (!age) {
        age = 'moins d\'un mois'
      }
      return age
    }
  }
  return ''
}

export function getSafeAge(birthDate: any, onDate: any = null): string {
  const monthAge = getMonthAgeValue(birthDate, onDate)
  if (monthAge !== null) {
    let theMonthAge = monthAge || 0
    if (theMonthAge >= 0 && theMonthAge < 36) {
      let age = ''
      if (theMonthAge >= 12) {
        let yearAge = Math.floor(theMonthAge / 12)
        if (yearAge > 1) {
          age = yearAge + ' ans'
        } else {
          age = '1 an'
        }
      }
      let months = theMonthAge % 12
      if (months > 0) {
        age += age ? ' et ' : ''
        age += months + ' mois'
      }
      if (!age) {
        age = 'moins d\'un mois'
      }
      return age
    } else {
      const age2 = getAgeValue(birthDate, onDate)
      if (age2 !== null) {
        return '' + age2 + ' ' + ((age2 > 1) ? 'ans' : 'an')
      }
    }
  }
  return ''
}

export function daysAgo(date1: any): number {
  if (date1) {
    return moment().diff(moment(date1), 'days')
  }
  return 365
}

export function diffDate(date1: any, date2: any): number {
  return moment(date1).diff(moment(date2), 'days')
}

export function isInTimeframe(date: any, dateFrom: any, dateTo: any): boolean {
  return (
    (moment(dateFrom).diff(moment(date), 'days') <= 0) &&
    (moment(dateTo).diff(moment(date), 'days') >= 0)
  )
}

export function getWeekRange(date: any): string[] {
  const day = moment(date)
  const weekday = day.weekday()
  const monday = day.add(-weekday, 'days')
  const date1 = monday.format('YYYY-MM-DD')
  const friday = monday.add(6, 'days')
  return [date1, friday.format('YYYY-MM-DD')]
}

export function getNWeeks(date: any, numberOfWeeks: number): string[] {
  const day = moment(date)
  const days: string[] = []
  let theDay = day
  for (let index = 0; index < numberOfWeeks; index++) {
    days.push(theDay.format('YYYY-MM-DD'))
    theDay = theDay.add(7, 'days')
  }
  return days
}

export function getWeekDays(date: any, noWednesay: boolean = false): string[] {
  const day = moment(date)
  const weekday = day.weekday()
  let theDay = day.add(-weekday, 'days')
  const days: string[] = []
  for (let index = 0; index < 7; index++) {
    if (!(noWednesay && (index === 2))) {
      days.push(theDay.format('YYYY-MM-DD'))
    }
    theDay = theDay.add(1, 'days')
  }
  return days
}

export function getBaseAge(birthDate: any, baseYear: number): number {
  const birthYear: number = moment(birthDate).year()
  return baseYear - birthYear
}

export function isBirthday(birthDate: any, day: any): boolean {
  if (birthDate && day) {
    return moment(birthDate).format('DDMM') === moment(day).format('DDMM')
  }
  return false
}

export function getMonthRange(date: any): string[] {
  const day = moment(date, 'YYYY-MM-DD')
  if (day.isValid()) {
    const startOfMonth = day.startOf('month').format('YYYY-MM-DD')
    const endOfMonth = day.endOf('month').format('YYYY-MM-DD')
    return [startOfMonth, endOfMonth]
  } else {
    return []
  }
}

export function monthsAgo(date: any, delta: number): string {
  const day = moment(date, 'YYYY-MM-DD')
  if (day.isValid()) {
    return moment(date).add(delta, 'month').format('YYYY-MM-DD')
  } else {
    return ''
  }
}

export function today(): string {
  return moment().format('YYYY-MM-DD')
}

export function firstDayOfMonth(date: any): string {
  return moment(date).format('YYYY-MM') + '-01'
}

export function lastDayOfMonth(date: any): string {
  const firstOfMonth = firstDayOfMonth(date)
  return moment(firstOfMonth).add(1, 'month').add(-1, 'days').format('YYYY-MM-DD')
}

export function getWeekDay(date: any): number {
  if (date) {
    return moment(date).weekday()
  }
  return -1
}

export function nextDate(date: any, delta: number = 1): Date {
  return moment(date).add(delta, 'day').toDate()
}
