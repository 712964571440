<template>
  <span v-if="hasPerm('payments.view_invoice')">
    <b-modal dialog-class="modal-md"
      :id="id"
      cancel-disabled
      @ok.prevent="onQuit"
      ok-title="Fermer"
      @hidden="onQuit"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fa fa-file-invoice"></i>&nbsp;
          <span v-if="invoices.length > 1">Nouvelles factures</span>
          <span v-else>Nouvelle facture</span>
        </b>
      </template>
      <div v-for="invoice of invoices" :key="invoice.id" class="invoice-item">
        <b-row>
          <b-col>
            la facture {{ invoice.getNumber() }} d'un montant de {{ invoice.totalPrice | currency }}
            a été créée avec succès
          </b-col>
        </b-row>
        <div class="footer-buttons">
          <a class="btn-sm btn btn-secondary" @click.prevent="viewEntity">
            <i class="fa fa-people-roof"></i> Fiche famille
          </a>
          <a class="btn-sm btn btn-secondary" @click.prevent="viewInvoice(invoice)">
            <i class="fa fa-file-invoice"></i> Facture
          </a>
          <invoice-download :invoice="invoice" @opened="onInvoiceDownloaded" no-html></invoice-download>
          <invoice-send :invoice="invoice" :invoice-entity="entity" @sent="onInvoiceSent"></invoice-send>
        </div>
      </div>
      <template #modal-footer>
        &nbsp;
      </template>
    </b-modal>
  </span>
</template>

<script>

import { BackendMixin } from '@/mixins/backend'
import InvoiceDownload from '@/components/Invoices/InvoiceDownload'
import InvoiceSend from '@/components/Invoices/InvoiceSend'

export default {
  name: 'invoice-actions-modal',
  components: {
    InvoiceSend,
    InvoiceDownload,
  },
  mixins: [
    BackendMixin
  ],
  props: {
    invoices: Array,
    entity: {
      type: Object,
      default: null,
    },
    id: String,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    onQuit() {
      this.$emit('close', { action: 'quit', })
    },
    viewEntity() {
      this.$emit('close', { action: 'tab', })
    },
    viewInvoice(invoice) {
      this.$emit('close', { invoice: invoice, action: 'view', })
    },
    onInvoiceSent() {
      // this.$emit('close', { action: 'quit', })
    },
    onInvoiceDownloaded() {
      // this.$emit('close', { action: 'quit', })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.invoice-item {
  padding: 10px;
  margin-bottom: 10px;
  border: solid 1px #cccccc;
}
.displayBlock {
  display: block;
  margin-bottom: 2px;
}
.footer-buttons > * {
  margin: 5px;
}
</style>
