<template>
  <div class="legal-representatives" v-if="hasPerm('families.view_legalrepresentative')">
    <legal-member
      v-if="showNewForm"
      :member="member"
      :legal-member="legalMember"
      :entity="entity"
      @change="onLegalMembersChanged"
    ></legal-member>
    <b-form @submit.prevent="onSave" v-if="showForm">
      <div class="field-group-block">
        <div class="field-group-header">
          <b-row>
          <b-col cols="9">
            <b>Représentants légaux</b>
          </b-col>
          <b-col cols="3" class="text-right">
          </b-col>
        </b-row>
        </div>
        <div class="representatives-form">
          <div class="field-line"
             v-for="elt in legalRepresentatives.getAllChildLegalRepresentatives()" :key="elt.individual.id">
            <b-row>
              <b-col cols="2">
                {{ elt.role.name }}
              </b-col>
              <b-col cols="5">
                <b>{{ elt.individual.fullName() }}</b>
              </b-col>
              <b-col cols="5">
                <b-form-checkbox
                    :id="'check' + elt.individual.id"
                    v-model="legalRepresentativesData[elt.individual.id]"
                    :name="'check' + elt.individual.id"
                    :value="true"
                    :unchecked-value="false"
                    @change="onLegalRepresentativeChanged"
                  >
                    Représentant légal
                  </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
        </div>
        <b-row class="buttons-bar">
          <b-col class="text-left">
            <a class="btn btn-primary btn-add" href @click.prevent="onAdd">Ajouter</a>
          </b-col>
          <b-col class="text-right">
            <a class="btn btn-secondary" href @click.prevent="onCancel">Annuler</a>
            <b-button type="submit" variant="primary" class="btn-save">Enregistrer</b-button>
          </b-col>
        </b-row>
      </div>
    </b-form>
    <div v-if="!showForm && legalRepresentatives">
      <div class="field-group-block">
        <div class="field-group-header">
          <b-row>
            <b-col cols="9">
              <b>Représentants légaux</b>
            </b-col>
            <b-col cols="3" class="text-right" v-if="!readOnly">
              <a
                v-if="hasPerm('families.add_legalrepresentative')"
                class="btn btn-primary btn-sm"
                href
                @click.prevent="onEdit()"
                :class="editDisabled ? 'disabled' : ''"
              >
                <i class="fa fa-edit"></i> Modifier
              </a>
            </b-col>
          </b-row>
        </div>
        <div class="field-line"
         v-for="elt in legalRepresentatives.getChildLegalRepresentatives()"
         :key="elt.individual.id"
        >
          <b-row>
            <b-col cols="2">
              {{ elt.role.name }}
            </b-col>
            <b-col cols="10">
              <b>{{ elt.individual.fullName() }}</b>
            </b-col>
          </b-row>
          <b-row class="small2">
            <b-col cols="3">
              <i class="fas fa-phone-alt"></i>&nbsp;
              <phone-link :phone="elt.individual.proPhone" default-value="-"></phone-link>
            </b-col>
            <b-col cols="3">
              <i class="fas fa-mobile-alt"></i>&nbsp;
              <phone-link :phone="elt.individual.cellPhone" default-value="-"></phone-link>
            </b-col>
            <b-col cols="6">
              <i class="fa fa-envelope"></i>&nbsp;
              <email-link :email="elt.individual.email" default-value="-"></email-link>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeLegalRepresentatives } from '@/types/families'
import { makeEntityMembership } from '@/types/people'
import LegalMember from './LegalMember'
import EmailLink from '@/components/Controls/Links/EmailLink'
import PhoneLink from '@/components/Controls/Links/PhoneLink'

export default {
  name: 'legalRepresentativesDetail',
  mixins: [BackendMixin],
  components: {
    EmailLink,
    PhoneLink,
    LegalMember,
  },
  props: {
    entity: Object,
    member: Object,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      legalRepresentativesData: {},
      addNew: false,
      memberAlreadyExists: false,
      memberData: makeEntityMembership(),
      legalRepresentatives: null,
      legalMember: null,
    }
  },
  created() {
    this.loadLegalRepresentatives(this.member)
  },
  computed: {
    searchedIndividuals() {
      return store.getters.searchedIndividuals
    },
    showForm() {
      return store.getters.editMode === 'legal-representatives' && this.legalMember === null
    },
    showNewForm() {
      return store.getters.editMode === 'legal-representatives' && this.legalMember !== null
    },
    editDisabled() {
      return store.getters.editMode !== ''
    },
  },
  watch: {
    member: function(newMember) {
      this.loadLegalRepresentatives(newMember)
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['setEditMode']),
    onEdit() {
      this.memberData = makeEntityMembership()
      this.legalRepresentativesData = {}
      for (let elt of this.legalRepresentatives.getAllChildLegalRepresentatives()) {
        this.legalRepresentativesData[elt.individual.id] = !elt.notAllowed
      }
      this.setEditMode('legal-representatives')
    },
    onAdd() {
      this.legalMember = makeEntityMembership()
    },
    onCancel() {
      this.setEditMode('')
    },
    async loadLegalRepresentatives(member) {
      let url = '/api/families/legal-representatives/' + this.entity.id + '/' + member.individual.id + '/'
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.legalRepresentatives = makeLegalRepresentatives(resp.data)
        this.setEditMode('')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onLegalRepresentativeChanged() {
      this.legalRepresentativesData = { ...this.legalRepresentativesData, }
    },
    async onSave() {
      this.setEditMode('')
      let data = []
      for (let elt of this.legalRepresentatives.getAllChildLegalRepresentatives()) {
        data.push({
          individual: {
            id: elt.individual.id,
            last_name: elt.individual.lastName,
            first_name: elt.individual.firstName,
            gender: elt.individual.gender.id,
            pro_phone: elt.individual.proPhone,
            cell_phone: elt.individual.cellPhone,
            email: elt.individual.email,
          },
          role: elt.role.id,
          not_allowed: !this.legalRepresentativesData[elt.individual.id],
        })
      }
      let url = '/api/families/legal-representatives/' + this.entity.id + '/' + this.member.individual.id + '/'
      let backendApi = new BackendApi('post', url)
      try {
        let resp = await backendApi.callApi(data)
        this.legalRepresentatives = makeLegalRepresentatives(resp.data)
        await this.addSuccess('Les représentants légaux ont été enregistrés')
        this.$emit('change', { legalRepresentatives: this.legalRepresentatives, })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onLegalMembersChanged(event) {
      this.legalRepresentatives = event
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .representatives-form {
    margin-bottom: 20px;
  }
</style>
