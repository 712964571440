import { makeSchoolYear, SchoolYear } from './schools'
import { currency } from '@/filters/texts'
import json = Mocha.reporters.json;

export class SeanceTariffRate {
  constructor(
    public id: number,
    public rate: number,
    public fixedPrice: number,
    public threshold: number,
    public additionalPrice: number
  ) {
  }
}

export class SeanceTariffSlice {
  constructor(
    public id: number,
    public index: number,
    public price: number,
    public additionalPrice: number
  ) {
  }
}

export class SeanceTariff {
  constructor(
    public id: number,
    public fixedPrice: number,
    public minPrice: number|null,
    public maxPrice: number|null,
    public rates: SeanceTariffRate[],
    public slices: SeanceTariffSlice[],
    public allowDiscountOnFreePrice: boolean
  ) {
  }

  public toData(): any|null {
    const data: any = {}
    let isValid = (
      isNaN(+this.fixedPrice) ||
      ((this.maxPrice !== null) && isNaN(+this.maxPrice)) ||
      ((this.minPrice !== null) && isNaN(+this.minPrice))
    )
    if (this.minPrice && this.maxPrice && (this.minPrice > this.maxPrice)) {
      isValid = false
    }
    if (isValid) {
      return null
    } else {
      data['fixed_price'] = +this.fixedPrice
      data['min_price'] = this.minPrice ? +this.minPrice : null
      data['max_price'] = this.maxPrice ? +this.maxPrice : null
      data['allow_discount_on_free_price'] = this.allowDiscountOnFreePrice
    }
    data.rates = []
    for (const rate of this.rates) {
      if (rate.rate !== null && rate.threshold !== null) {
        if (isNaN(+rate.rate) || isNaN(+rate.threshold)) {
          break
        }
        if (+rate.threshold) {
          data.rates.push(
            {
              rate: +rate.rate,
              threshold: +rate.threshold,
              'fixed_price': rate.fixedPrice ? +rate.fixedPrice : 0,
              'additional_price': rate.additionalPrice ? +rate.additionalPrice : 0,
            }
          )
        }
      } else {
        break
      }
    }
    data.slices = []
    for (let index = 0; index < this.slices.length; index++) {
      const slice = this.slices[index]
      if ((slice.price !== null)) {
        if (isNaN(+slice.price)) {
          break
        }
        if (+slice.price) {
          data.slices.push(
            {
              index: index,
              price: +slice.price,
              'additional_price': slice.additionalPrice ? +slice.additionalPrice : 0,
            }
          )
        } else {
          break
        }
      } else {
        break
      }
    }
    return data
  }
}

export function makeSeanceTariffRate(jsonData: any = null): SeanceTariffRate {
  if (!jsonData) {
    jsonData = {}
  }
  return new SeanceTariffRate(
    jsonData.id || 0,
    jsonData.rate || 0,
    jsonData['fixed_price'] || 0,
    jsonData.threshold || 0,
    jsonData['additional_price'] || 0
  )
}

export function makeSeanceTariffSlice(jsonData: any = null, index: number = 0): SeanceTariffSlice {
  if (!jsonData) {
    jsonData = {}
  }
  let sliceIndex = jsonData.index
  if (!sliceIndex && sliceIndex !== 0) {
    sliceIndex = index
  }
  return new SeanceTariffSlice(
    jsonData.id || 0,
    sliceIndex,
    jsonData.price || null,
    +(jsonData['additional_price'] || 0)
  )
}

export function makeSeanceTariff(jsonData: any = null): SeanceTariff {
  if (!jsonData) {
    jsonData = {}
  }
  const rates = jsonData.rates || []
  const slices = jsonData.slices || []
  let fixedPrice = jsonData['fixed_price'] || 0
  if (fixedPrice) {
    fixedPrice = +fixedPrice
  }
  let minPrice = jsonData.min_price || null
  if (minPrice !== null) {
    minPrice = +minPrice
    if (isNaN(minPrice) || (!(minPrice > 0))) {
      minPrice = null
    }
  }
  let maxPrice = jsonData.max_price || null
  if (maxPrice !== null) {
    maxPrice = +maxPrice
    if (isNaN(maxPrice) || (!(maxPrice > 0))) {
      maxPrice = null
    }
  }
  return new SeanceTariff(
    jsonData.id || 0,
    fixedPrice,
    minPrice,
    maxPrice,
    rates.map((elt: any) => makeSeanceTariffRate(elt)),
    slices.map((elt: any, index: number) => makeSeanceTariffSlice(elt, index)),
    !!jsonData.allow_discount_on_free_price
  )
}

export class SeanceWelfareType {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export class SeanceWelfare {
  constructor(
    public id: number,
    public name: string,
    public amount: number,
    public percentage: number,
    public inPercentage: boolean,
    public welfareType: SeanceWelfareType,
    public organizationName: string,
    public allowedInFixedFeeSeances: boolean,
    public schoolYear: SchoolYear|null
  ) {
  }

  public fullName(): string {
    let text = this.welfareType.name + ' ' + this.name
    if (this.schoolYear) {
      text += ' ' + this.schoolYear.name
    }
    text += ' ' + this.getWelfareAmount()
    return text
  }

  public getWelfareAmount(): string {
    if (this.inPercentage) {
      return '' + this.percentage + '%'
    } else {
      return currency(this.amount)
    }
  }

  public clone(): SeanceWelfare {
    return new SeanceWelfare(
      this.id,
      this.name,
      this.amount,
      this.percentage,
      this.inPercentage,
      new SeanceWelfareType(this.welfareType.id, this.welfareType.name),
      this.organizationName,
      this.allowedInFixedFeeSeances,
      this.schoolYear
    )
  }

  public add(other: SeanceWelfare): void {
    if (this.inPercentage) {
      // oups, cela ne fonctionne pas je pense
    } else {
      this.amount += other.amount
    }
  }
}

export function makeSeanceWelfare(jsonData: any = null): SeanceWelfare {
  if (!jsonData) {
    jsonData = {}
  }
  return new SeanceWelfare(
    jsonData.id || 0,
    jsonData.name || '',
    +jsonData.amount || 0,
    +jsonData.percentage || 0,
    !!jsonData.in_percentage,
    new SeanceWelfareType(jsonData.welfare_type || 0, jsonData.welfare_type_name || ''),
    jsonData.organization_name || '',
    !!jsonData.allowed_in_fixed_fee_seances,
    jsonData.school_year ? makeSchoolYear(jsonData.school_year) : null
  )
}

export class SeancePrice {
  constructor(
    public price: number,
    public welfare: SeanceWelfare[],
    public invoiced: boolean,
    public message: string
  ) {
  }

  public getPrice(): number {
    let price: number = this.price
    for (const welfare of this.welfare.filter(elt => !elt.inPercentage)) {
      price -= welfare.amount
    }
    for (const welfare of this.welfare.filter(elt => elt.inPercentage)) {
      price -= (price * welfare.percentage / 100)
    }
    return Math.max(0, price)
  }

  public isInvoiced(): boolean {
    return this.invoiced
  }
}

export function makeSeancePrice(jsonData: any): SeancePrice {
  if (jsonData.invoiced_price) {
    return new SeancePrice(
      +jsonData.invoiced_price,
      [],
      true,
      jsonData.message || ''
    )
  } else {
    const welfare = jsonData.welfare || []
    return new SeancePrice(
      +jsonData.price || 0,
      welfare.map((elt: any) => makeSeanceWelfare(elt)),
      false,
      jsonData.message || ''
    )
  }
}

export class FamilyLevelScale {
  constructor(
    public id: number,
    public name: string,
    public index: number,
    public schoolYear: SchoolYear,
    public slices: number[]
  ) {
  }
}

export function makeFamilyLevelScale(jsonData: any): FamilyLevelScale {
  const slices = jsonData.slices || []
  return new FamilyLevelScale(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.index || 0,
    makeSchoolYear(jsonData.school_year),
    slices.map((elt: any) => elt.value)
  )
}

export class TariffGridSlice {
  constructor(
    public index: number,
    public price: number
  ) {
  }
}

export class TariffGrid {
  constructor(
    public id: number,
    public name: string,
    public schoolYears: number[],
    public slices: TariffGridSlice[],
    public forActivities: boolean,
    public forYouthHomes: boolean
  ) {
  }
}

export function makeTariffGridSlice(jsonData: any): TariffGridSlice {
  return new TariffGridSlice(
    jsonData.index,
    (+jsonData.price) || 0
  )
}

export function makeTariffGrid(jsonData: any): TariffGrid {
  const slices = jsonData.slices || []
  const hasActivities = !!jsonData.activities_count
  const hasYouthHomes = !!jsonData.youthhomes_count
  return new TariffGrid(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.school_years || [],
    slices.map((elt: any) => makeTariffGridSlice(elt)),
    hasActivities || !hasYouthHomes,
    hasYouthHomes || !hasActivities
  )
}
