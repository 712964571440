<template>
  <span class="nursery-create-one-inscription-modal">
    <b-modal
      :id="modalId"
      cancel-title="Annuler"
      @ok="onConfirm"
      @cancel="onCancel"
      @show="onShow"
      :ok-disabled="!canAdd || !isValid"
      ok-variant="primary"
      ok-title="Inscrire"
    >
      <template v-slot:modal-title>
        <i class="fa fa-baby-carriage"></i>
        Ajout d'une inscription <span v-if="selectedNursery">{{ selectedNursery.name }}</span>
      </template>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div v-if="!isLoading(loadingName)">
        <div class="nursery-create-one-inscription">
          <b-row v-if="nurseries.length !== 1">
            <b-col>
              <b-form-group label-for="selectedNursery" label="Crèche">
                <b-form-select
                  v-model="selectedNursery"
                  id="selectedNursery"
                >
                  <b-form-select-option
                    v-for="item of nurseries"
                    :key="item.id"
                    :value="item"
                  >
                    {{ item.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label-for="name" label="Nom">
                <b-form-select
                  v-model="selectedIndividual"
                  id="selectedIndividual"
                  :disabled="individuals.length <= 1"
                >
                  <b-form-select-option
                    v-for="item of individuals"
                    :key="item.id"
                    :value="item"
                  >
                    {{ item.firstAndLastName() }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="entities.length > 1">
            <b-col>
              <b-form-group label-for="family" label="Famille">
                <b-form-select
                  v-model="selectedEntity"
                  id="selectedEntity"
                >
                  <b-form-select-option
                    v-for="item of entities"
                    :key="item.id"
                    :value="item"
                  >
                    {{ item.id }} - {{ item.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-for="date"
                label="Date"
              >
                <b-input
                  v-if="date"
                  type="text"
                  id="date"
                  disabled
                  :value="dateToString(date, 'dddd D MMMM Y')"
                ></b-input>
                <b-input
                  v-else
                  type="date"
                  id="date"
                  v-model="seanceDate"
                ></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label-for="arrivalAt" label="Arrivée">
                <b-input
                  type="time"
                  id="arrivalAt"
                  v-model="arrivalAt"
                ></b-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="leavingAt" label="Départ">
                <b-input
                  type="time"
                  id="leavingAt"
                  v-model="leavingAt"
                ></b-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="lunch" label="Repas">
                <b-form-checkbox
                  id="lunch"
                  v-model="lunch"
                >
                  Repas
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label-for="adaptation" label="">
                <b-form-checkbox
                  id="adaptation"
                  v-model="adaptation"
                >
                  Adaptation
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="adaptationWithParents" label="">
                <b-form-checkbox
                  id="adaptationWithParents"
                  v-model="adaptationWithParents"
                  :disabled="!adaptation"
                >
                  avec parents
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeNurseryContract, makeNurseryInscription, NurseryAdaptation } from '@/types/nursery'
import { diffTimes } from '@/utils/time'
import { dateToString } from '@/filters/texts'
import { distinct } from '@/utils/arrays'

export default {
  name: 'NurseryCreateOneInscriptionModal',
  components: {
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    nurseries: {
      type: Array,
      default: function() { return [] },
    },
    individual: {
      type: Object,
      default: null,
    },
    entity: {
      type: Object,
      default: null,
    },
    entities: {
      type: Array,
      default: function() { return [] },
    },
    parentInscription: {
      type: Object,
      default: null,
    },
    date: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      arrivalAt: '',
      leavingAt: '',
      lunch: '',
      loadingName: 'NurseryCreateOneInscriptionModal',
      errorText: '',
      adaptation: false,
      adaptationWithParents: false,
      seanceDate: null,
      selectedIndividual: null,
      individuals: [],
      selectedEntity: null,
      selectedNursery: null,
    }
  },
  watch: {
    arrivalAt: async function() {
      this.autoLunch()
    },
    leavingAt: async function() {
      this.autoLunch()
    },
    individual() {
      // this.init()
    },
    entity() {
      // this.init()
    },
  },
  created() {
    // this.init()
  },
  computed: {
    modalId() {
      if (this.id) {
        return this.id
      } else {
        return 'bv-nursery-create-one-inscription-modal'
      }
    },
    canAdd() {
      return this.hasPerm('nursery.add_nurseryinscription')
    },
    isValid() {
      let isValid = (
        this.selectedIndividual && this.selectedIndividual.id &&
        this.seanceDate && this.arrivalAt && this.leavingAt
      )
      if (isValid) {
        isValid = diffTimes(this.leavingAt, this.arrivalAt) > 0
      }
      if (isValid && this.entities.length > 1) {
        isValid = this.selectedEntity
      }
      return isValid
    },
  },
  methods: {
    dateToString,
    ...mapActions(['addSuccess']),
    init() {
      if (this.individual) {
        this.selectedIndividual = this.individual
        if (this.selectedIndividual) {
          this.individuals = [this.selectedIndividual]
        }
      } else if (this.entity) {
        this.selectedIndividual = null
        this.individuals = []
        this.loadContractIndividuals()
      }
      if (this.nurseries.length === 1) {
        this.selectedNursery = this.nurseries[0]
      }
    },
    async loadContractIndividuals() {
      if (this.entity) {
        let url = '/nursery/api/entity-contracts/' + this.entity.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.individuals = distinct(
            resp.data.map(elt => makeNurseryContract(elt)).map(elt => elt.individual)
          )
          if (this.individuals.length === 1) {
            this.selectedIndividual = this.individuals[0]
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async onConfirm(event) {
      event.preventDefault()
      this.errorText = ''
      if (this.canAdd && this.selectedIndividual) {
        try {
          const url = '/nursery/api/create-one-inscription/'
          const backendApi = new BackendApi('post', url)
          let adaptationValue = NurseryAdaptation.None
          if (this.adaptation) {
            if (this.adaptationWithParents) {
              adaptationValue = NurseryAdaptation.WithParents
            } else {
              adaptationValue = NurseryAdaptation.WithoutParents
            }
          }
          const data = {
            'individual': this.selectedIndividual.id,
            'date': dateToString(this.seanceDate, 'YYYY-MM-DD'),
            'arrival_at': this.arrivalAt,
            'leaving_at': this.leavingAt,
            'lunch': this.lunch,
            'nursery': this.selectedNursery.id,
            'adaptation': adaptationValue,
          }
          if (this.entities.length === 1) {
            // Depuis le listing journée, enfant non inscrit et membre d'une seule famille
            data.entity = this.entities[0].id
          } else if (this.entities.length > 1) {
            // Depuis le listing journée, enfant non inscrit et membre de plusieurs familles (parents séparés)
            data.entity = this.selectedEntity.id
          } else if (this.parentInscription) {
            // Depuis le listing journée, enfant déjà inscrit à une autre journée
            data.parent = this.parentInscription.id
          } else if (this.entity) {
            // Depuis la fiche famille
            data.entity = this.entity.id
          }
          const resp = await backendApi.callApi(data)
          const inscription = makeNurseryInscription(resp.data)
          this.$emit('confirmed', { inscription: inscription, })
          let text = 'Une inscription a été créée'
          await this.addSuccess(text)
          this.$bvModal.hide(this.modalId)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    onCancel() {
    },
    onShow() {
      this.init()
      this.adaptation = false
      this.adaptationWithParents = false
      if (this.date) {
        this.seanceDate = this.date
      } else {
        this.seanceDate = null
      }
    },
    autoLunch() {
      if (this.arrivalAt && this.leavingAt) {
        this.lunch = (
          (diffTimes('12:00', this.arrivalAt) >= 0) &&
          (diffTimes(this.leavingAt, '12:00') > 0)
        )
      }
    },
  },
}
</script>
<style lang="less" scoped>
</style>
