<template>
  <span class="email-link" :style="block ? 'display: block' : ''">
    <a
      v-if="email"
      :class="{ light: light, }"
      :href="'mailto:' + link"
    >
      <span :class="icon"></span>
      {{ email }}
    </a>
    <span v-else>{{ defaultValue }}</span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
export default {
  name: 'EmailLink',
  props: {
    email: String,
    defaultValue: {
      type: String,
      default: '',
    },
    subject: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    light: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    link() {
      let link = this.email
      if (this.subject) {
        link += '?subject=' + this.subject
      }
      if (this.body) {
        const prefix = this.subject ? '&' : '?'
        link += prefix + 'body=' + this.body
      }
      return link
    },
  },
  methods: {
  },
}
</script>
<style scoped lang="less">
a {
  text-decoration: underline !important;
}
.email-link a.light {
  color: #fff !important;
}
</style>
