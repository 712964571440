<template>
  <span class="online-help">
    <b-modal
      dialog-class="modal-md"
      id="bv-online-help"
      @ok="onView"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Continuer vers l'aide en ligne"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-info-circle"></i> Aide en ligne</b>
      </template>
      <div>
        Pour accéder à l'aide en ligne, cliquer sur "Continuer...".<br/>
        Le mot de passe à saisir est "Millibase.net"
      </div>
    </b-modal>
  </span>
</template>

<script>

export default {
  name: 'online-help',
  components: {},
  props: {
  },
  computed: {
  },
  methods: {
    onView() {
      setTimeout(
        function() {
          const fullUrl = 'https://acloud.zaclys.com/index.php/s/NHfE75W5XcEp773'
          window.open(fullUrl, '_blank')
        }, 100
      )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
