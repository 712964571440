<template>
  <div>
    <span
      v-for="analytic in groupedAnalytics"
      :key="analytic.getKey()"
      class="analytics-priority-elt"
      :class="(getPriority(analytic) > 0) ? 'selected' : ''"
      @click.prevent="onSelectPriority(analytic)"
    >
      <span v-if="(getPriority(analytic) > 0)" class="analytic-priority" v-b-tooltip="'Priorité'">
        {{ getPriority(analytic) }}
      </span>
      <span >{{ analytic.getFullLabel() }}</span>
      <span>: {{ analytic.amount | currency }}</span>
    </span>
  </div>
</template>

<script>

import { currency } from '@/filters/texts'
import { sum } from '@/utils/math'
import { compareNumbers, compareStrings } from '@/utils/sorting'

export default {
  name: 'analytics-priority-selector',
  components: {},
  mixins: [],
  props: {
    analytics: Array,
  },
  data() {
    return {
      priorities: [],
      groupedAnalytics: [],
    }
  },
  computed: {
    total() {
      return sum(this.analytics.map(elt => elt.amount))
    },
  },
  created() {
    this.init()
  },
  watch: {
    analytics: function() { this.init() },
  },
  methods: {
    init() {
      this.createList()
      this.initPriorities()
    },
    createList() {
      const map = new Map()
      if (this.analytics) {
        for (const elt of this.analytics) {
          let groupElt = null
          const key = elt.getKey()
          if (map.has(key)) {
            groupElt = map.get(key)
            groupElt.amount += elt.amount
          } else {
            groupElt = elt.clone()
          }
          map.set(key, groupElt)
        }
        this.groupedAnalytics = Array.from(map.values()).sort(
          (ana1, ana2) => {
            let prio1 = 0 // this.getPriority(ana1) || 9999999
            let prio2 = 0 // this.getPriority(ana2) || 9999999
            if (prio1 === prio2) {
              prio1 = ana1.schoolYear.startYear
              prio2 = ana2.schoolYear.startYear
            }
            if (prio1 === prio2) {
              prio1 = ana1.order
              prio2 = ana2.order
            }
            if (prio1 === prio2) {
              prio1 = ana1.amount
              prio2 = ana2.amount
            }
            if (prio1 === prio2) {
              return compareStrings(ana1.getKey(), ana2.getKey())
            } else {
              return compareNumbers(prio1, prio2)
            }
          }
        )
      }
    },
    initPriorities() {
      let grouped = this.groupedAnalytics.filter(elt => elt.analyticAccount.priority > 0)
      grouped = grouped.sort((elt1, elt2) => compareNumbers(elt1.priority, elt2.priority))
      this.priorities = grouped
      this.$emit('priorities', { priorities: this.priorities, })
    },
    getPriority(analytic) {
      return this.priorities.map(elt => elt.getKey()).indexOf(analytic.getKey()) + 1
    },
    onSelectPriority(analytic) {
      const priority = this.getPriority(analytic)
      if (priority > 0) {
        const index = priority - 1
        this.priorities.splice(index, 1)
      } else {
        this.priorities.push(analytic)
      }
      this.createList()
      this.$emit('priorities', { priorities: this.priorities, })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .analytics-priority-elt {
    display: inline-block;
    font-size: 12px;
    margin: 0 0 2px 2px;
    padding: 5px;
    border: solid 1px #888;
    background: #fff;
    cursor: pointer;
  }
  .analytics-priority-elt.selected {
    background: #444;
    border: solid 1px #222;
    color: #fff;
  }
  .analytic-priority {
    display: inline-block;
    padding: 2px;
    font-size: 11px;
    font-weight: bold;
    min-width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    background: #f2f2a2;
    color: #000;
    margin-right: 2px;
    vertical-align: bottom;
  }
</style>
