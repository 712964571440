import { Individual, makeIndividual } from '@/types/people'
import { makeSeancePeriod, makeSeanceType, makeYouthHome, SeancePeriod, SeanceType, YouthHome } from '@/types/youth'
import { capitalize } from '@/utils/strings'

export class PortalOptions {
  constructor(
    public disablePayWithCredits: boolean,
    public viewFixedFeeDates: boolean
  ) {
  }
}

export function makePortalOptions(jsonData: any = null): PortalOptions {
  let viewFixedFeeDates: boolean = false
  if (jsonData === null) {
    jsonData = {}
    viewFixedFeeDates = true
  } else {
    viewFixedFeeDates = !!jsonData.view_fixed_fee_dates
  }

  return new PortalOptions(
    !!jsonData.disable_pay_with_credits,
    viewFixedFeeDates
  )
}

export class OpeningOptions {
  constructor(
    public id: number,
    public name: string,
    public onlyCodes: string,
    public ignoreCodes: string,
    public isActive: Boolean,
    public youthHomeNumber: number,
    public seanceTypeId: number
  ) {
  }
}

export function makeOpeningOptions(jsonData: any = null): OpeningOptions {
  let viewFixedFeeDates: boolean = false
  if (jsonData === null) {
    jsonData = {}
  }

  return new OpeningOptions(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.only_codes || '',
    jsonData.ignore_codes || '',
    !!jsonData.is_active,
    jsonData.youth_home_number || 0,
    jsonData.seance_type || 0
  )
}

export class AccountUser {
  constructor(
    public id: number,
    public email: string,
    public isActive: boolean
  ) {
  }
}

export function makeAccountUser(jsonData: any = null): AccountUser {
  if (jsonData === null) {
    jsonData = {}
  }
  return new AccountUser(
    jsonData.id || 0,
    jsonData.email || '',
    !!jsonData.is_active
  )
}

export class Account {
  constructor(
    public id: number,
    public user: AccountUser,
    public individual: Individual,
    public notifiedOn: Date|null
  ) {
  }
}

export function makeAccount(jsonData: any = null): Account {
  if (jsonData === null) {
    jsonData = {}
  }
  return new Account(
    jsonData.id || 0,
    makeAccountUser(jsonData.user),
    makeIndividual(jsonData.individual),
    jsonData.notified_on || null
  )
}

export enum PortalServiceRule {
  DAYS_BEFORE_FIRST_DATE = 0,
  DAYS_BEFORE_DATE = 1,
  OPEN_DAYS_BEFORE_DATE = 2,
  MONDAY_AFTER_DAY_OF_WEEK = 3,
  DAY_OF_WEEK = 4,
  DAYS_AFTER_FIRST_DATE = 5,
  NO_RULE = 6
}

export class PortalService {
  constructor(
    public id: number,
    public limitRules: PortalServiceRule,
    public limitDays: number,

    public cancelLimitDays: number,
    public limitTime: string,
    public allowCancellation: boolean,
    public onlyCodes: string,
    public ignoreCodes: string,
    public autoInvoice: boolean,
    public autoConfirmation: boolean,
    public youthHomeNumber: number,
    public seanceTypeId: number
  ) {
  }

  private getWeekDay(day: number): string {
    switch (day) {
      case 0:
        return 'lundi'
      case 1:
        return 'mardi'
      case 2:
        return 'mercredi'
      case 3:
        return 'jeudi'
      case 4:
        return 'vendredi'
      case 5:
        return 'samedi'
      case 6:
        return 'dimanche'
      default:
        return '?'
    }
  }
  public getDescription(capitalized: boolean = true): string {
    let description = 'Inscription à une séance '
    if (this.allowCancellation && !this.cancelLimitDays) {
      description = 'Inscription à une séance et annulation '
    }
    if (this.limitRules === PortalServiceRule.DAYS_BEFORE_FIRST_DATE) {
      const limitName = this.limitDays > 1 ? this.limitDays + ' jours' : this.limitDays + ' jour'
      description += 'jusqu\'à ' + limitName + ' avant la première séance de la période.'
    }

    if (this.limitRules === PortalServiceRule.DAYS_BEFORE_DATE) {
      const limitName = this.limitDays > 1 ? this.limitDays + ' jours' : this.limitDays + ' jour'
      description += 'jusqu\'à ' + limitName + ' avant la séance.'
    }

    if (this.limitRules === PortalServiceRule.OPEN_DAYS_BEFORE_DATE) {
      const limitName = this.limitDays > 1 ? this.limitDays + ' jours ouvrés' : this.limitDays + ' jour ouvré'
      description += 'jusqu\'à ' + limitName + ' avant la séance.'
    }

    if (this.limitRules === PortalServiceRule.MONDAY_AFTER_DAY_OF_WEEK) {
      description += 'jusqu\'au ' + this.getWeekDay(this.limitDays) + ' pour le lundi suivant.'
    }

    if (this.limitRules === PortalServiceRule.DAY_OF_WEEK) {
      description += 'jusqu\'au ' + this.getWeekDay(this.limitDays) + 'précédent.'
    }

    if (this.limitRules === PortalServiceRule.DAYS_AFTER_FIRST_DATE) {
      const limitName = this.limitDays > 1 ? this.limitDays + ' jours' : this.limitDays + ' jour'
      description += 'jusqu\'à ' + limitName + ' après la première séance de la période.'
    }

    if (this.limitRules === PortalServiceRule.NO_RULE) {
      description = ''
    }

    if (this.limitTime) {
      description += ' Heure limite: ' + this.limitTime + '.'
    }

    if (this.allowCancellation) {
      if (this.cancelLimitDays) {
        if (description) {
          description += ' '
        }
        description += 'Annulation possible jusqu\'à J-' + this.cancelLimitDays + ' avant la séance.'
      }
    } else {
      if (description) {
        description += ' '
      }
      description += 'Les annulations ne sont pas possibles depuis la plateforme.'
    }

    if (capitalized) {
      description = capitalize(description)
    }

    return description
  }
}

export function makePortalService(jsonData: any = null): PortalService {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PortalService(
    jsonData.id || 0,
    jsonData.limit_rules,
    jsonData.limit_days,
    jsonData.cancel_limit_days,
    jsonData.limit_time,
    jsonData.allow_cancellation,
    jsonData.only_codes,
    jsonData.ignore_codes,
    !!jsonData.auto_invoice,
    !!jsonData.auto_confirmation,
    jsonData.youth_home_number || 0,
    jsonData.seance_type || 0
  )
}

export class PortalOpening {
  constructor(
    public id: number,
    public youthHome: YouthHome,
    public seanceType: SeanceType,
    public period: SeancePeriod,
    public openingAt: Date,
    public closingAt: Date,
    public service: PortalService|null,
    public options: OpeningOptions|null
  ) {
  }
}

export function makePortalOpening(jsonData: any = null): PortalOpening {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PortalOpening(
    jsonData.id || 0,
    makeYouthHome(jsonData.youth_home),
    makeSeanceType(jsonData.seance_type),
    makeSeancePeriod(jsonData.period),
    jsonData.opening_at,
    jsonData.closing_at,
    jsonData.service ? makePortalService(jsonData.service) : null,
    jsonData.options ? makeOpeningOptions(jsonData.options) : null
  )
}

export class PortalStructure {
  constructor(
    public id: number,
    public name: string,
    public cgu: string,
    public email: string,
    public htmlPortalAbout: string
  ) {
  }
}

export function makePortalStructure(jsonData: any = null): PortalStructure {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PortalStructure(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.cgu_url || '',
    jsonData.email || '',
    jsonData.html_portal_about || ''
  )
}
