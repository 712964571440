<template>
  <span v-if="(credits.length > 0) && hasPerm('payments.view_credit')">
    <span class="badge" :class="{returned: returned}">
      <counter-label :counter="credits.length" label="avoir"></counter-label> pour {{ totalSum | currency }}
    </span>
  </span>
</template>

<script>
import CounterLabel from '@/components/Controls/CounterLabel'
import { currency } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { sum } from '@/utils/math'

export default {
  name: 'credits-badge',
  components: { CounterLabel, },
  mixins: [BackendMixin],
  props: {
    credits: Array,
    returned: Boolean,
  },
  computed: {
    totalSum() {
      return sum(this.credits.map(elt => elt.amount))
    },
  },
  data() {
    return {
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .badge {
    color: #000;
    display: block;
    margin-bottom: 2px;
    border: solid 1px #ccc;
  }
  .badge.returned {
    text-decoration: line-through;
  }
  .badge a {
    color: #000 !important;
  }
</style>
