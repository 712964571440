<template>
  <div>
    <vue-phone-number-input
      :id="id"
      :name="id"
      :translations="translations"
      v-model="phone"
      :clearable="true"
      :required="required"
      :no-flags="true"
      default-country-code="FR"
      :preferred-countries="['FR', 'BE', 'CH']"
      valid-color="#454851"
      error-color="#F78888"
      color="#444"
      :no-country-selector="true"
      :no-example="true"
      @update="onUpdatePhone"
    >
    </vue-phone-number-input>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
export default {
  name: 'PhoneInput',
  props: {
    id: String,
    value: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phone: '',
      translations: {
        countrySelectorLabel: 'Code pays',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Numéro de téléphone',
        example: 'Exemple :',
      },
    }
  },
  watch: {
    phone: function() {
      if (this.phone === null) {
        this.phone = '' // will trigger this function again
      } else {
        this.onInputPhone()
      }
    },
  },
  computed: {
  },
  methods: {
    onInputPhone() {
      this.$emit('input', this.phone)
    },
    onUpdatePhone(payload) {
      setTimeout(
        () => {
          let isValid = payload.isValid
          if ((!this.required) && (this.phone === '')) {
            isValid = true
          }
          this.$emit('isValid', isValid)
        }, 100)
    },
  },
  mounted() {
    this.phone = this.value
    this.$emit('isValid', true)
  },
}
</script>
<style scoped lang="less">
</style>
