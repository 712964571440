import { htmlText } from '@/utils/html'

export class NotePage {
  constructor(
    public name: string,
    public path: string,
    public icon: string
  ) {
  }
}

export function makeNotePage(jsonData: any = null): NotePage {
  if (!jsonData) {
    jsonData = {}
  }
  return new NotePage(
    jsonData.name || '',
    jsonData.path || '',
    jsonData.icon || ''
  )
}

export enum NotePriority {
  High = 1,
  Normal = 2,
  Low = 3
}

export class Note {
  constructor(
    public id: number,
    public title: string,
    public body: string,
    public priority: NotePriority,
    public createdOn: string,
    public createdBy: string,
    public modifiedOn: string,
    public modifiedBy: string,
    public recipients: number[],
    public page: NotePage|null,
    public archived: boolean
  ) {
  }

  public bodyHtml(): string {
    return htmlText(this.body)
  }
}

export function makeNote(jsonData: any = null): Note {
  if (!jsonData) {
    jsonData = {}
  }
  return new Note(
    jsonData.id || 0,
    jsonData.title || '',
    jsonData.body || '',
    jsonData.priority || NotePriority.Normal,
    jsonData.created_on || '',
    jsonData.created_by || '',
    jsonData.modified_on || '',
    jsonData.modified_by || '',
    jsonData.recipients ? jsonData.recipients : [],
    jsonData.page ? makeNotePage(jsonData.page) : null,
    !!jsonData.archived
  )
}
