import { City, makeCity } from '@/types/people'
import { calcHeaderStyle } from '@/utils/style'
import { dateToString } from '@/filters/texts'

export class SchoolYear {
  constructor(
    public id: number,
    public name: string,
    public startYear: number,
    public isActive: boolean,
    public backgroundColor: string,
    public textColor: string,
    public startDate: Date,
    public endDate: Date,
    public isClosedForPayments: boolean,
    public isCertificateActive: boolean,
    public showHistoryOnPortal: boolean,
    public isCivilYear: boolean,
    public isArchived: boolean
  ) {
  }

  public getHeaderStyle() {
    const defaultStyle: any = { backgroundColor: '#f7f9fb', textColor: '#000', }
    return calcHeaderStyle([this, defaultStyle])
  }

  public getName(): string {
    if (this.name) {
      return this.name
    } else if (this.startYear) {
      return '' + this.startYear + '/' + (this.startYear + 1)
    } else {
      return ''
    }
  }
}

export class SchoolYearEx {
  constructor(
    public id: number,
    public name: string,
    public startYear: number,
    public isActive: boolean,
    public startDate: Date,
    public endDate: Date,
    public backgroundColor: string,
    public textColor: string,
    public isClosedForPayments: boolean,
    public isCertificateActive: boolean,
    public showHistoryOnPortal: boolean,
    public isCivilYear: boolean,
    public isArchived: boolean
  ) {
  }
}

export class School {
  constructor(
    public id: number,
    public name: string,
    public order: number,
    public city: City|null,
    public schoolClasses: SchoolClass[],
    public isActive: boolean
  ) {
  }

  public fullName(): string {
    let name = this.name
    if (this.city) {
      name += (' (' + this.city.name + ')')
    }
    return name
  }
}

export class SchoolClass {
  constructor(
    public id: number,
    public name: string,
    public order: number,
    public teacher: string,
    public school: School|null,
    public isActive: boolean,
    public backgroundColor: string,
    public textColor: string
  ) {
  }

  public fullName(): string {
    let name = this.name
    if (this.teacher) {
      name += (' (' + this.teacher + ')')
    }
    if (this.school) {
      name += ' - ' + this.school.fullName()
    }
    return name
  }
}

export function makeSchool(jsonData: any = null): School {
  if (jsonData === null) {
    jsonData = {}
  }
  let schoolClasses: SchoolClass[] = []
  if (jsonData.school_classes) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    schoolClasses = jsonData.school_classes.map((elt: any) => makeSchoolClass(elt))
  }
  return new School(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.order || 0,
    jsonData.city ? makeCity(jsonData.city) : null,
    schoolClasses,
    !!jsonData.is_active
  )
}

export function makeSchoolClass(jsonData: any = null): SchoolClass {
  if (jsonData === null) {
    jsonData = {}
  }
  return new SchoolClass(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.order || 0,
    jsonData.teacher || '',
    jsonData.school ? makeSchool(jsonData.school) : null,
    !!jsonData.is_active,
    jsonData.background_color || '',
    jsonData.text_color || ''
  )
}

export function makeSchoolYear(jsonData: any = null): SchoolYear {
  if (jsonData === null) {
    jsonData = {}
  }
  return new SchoolYear(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.start_year || 0,
    !!jsonData.is_active,
    jsonData.background_color || '',
    jsonData.text_color || '',
    jsonData.start_date,
    jsonData.end_date,
    !!jsonData.is_closed_for_payments,
    !!jsonData.is_certificate_active,
    !!jsonData.show_history_on_portal,
    !!jsonData.is_civil_year,
    !!jsonData.is_archived
  )
}

export function makeSchoolYearEx(jsonData: any = null): SchoolYearEx {
  if (jsonData === null) {
    jsonData = {}
  }
  return new SchoolYearEx(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.start_year || 0,
    !!jsonData.is_active,
    jsonData.start_date,
    jsonData.end_date,
    jsonData.background_color || '',
    jsonData.text_color || '',
    !!jsonData.is_closed_for_payments,
    !!jsonData.is_certificate_active,
    !!jsonData.show_history_on_portal,
    !!jsonData.is_civil_year,
    !!jsonData.is_archived
  )
}

export class BankHoliday {
  constructor(
    public id: number,
    public name: string,
    public date: Date,
    public endDate: Date|null,
    public isSchool: boolean
  ) {
  }

  public period(): string {
    let periodName = dateToString(this.date, 'ddd LL')
    if (this.endDate) {
      periodName += ' - ' + dateToString(this.endDate, 'ddd LL')
    }
    return periodName
  }
}

export function makeBankHoliday(jsonData: any = null): BankHoliday {
  if (jsonData === null) {
    jsonData = {}
  }
  return new BankHoliday(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.date || null,
    jsonData.end_date || null,
    !!jsonData.is_school
  )
}

export class SchoolLevelGroup {
  constructor(
    public id: number,
    public name: string,
    public order: number
  ) {
  }
}

export function makeSchoolLevelGroup(jsonData: any = null): SchoolLevelGroup {
  if (jsonData === null) {
    jsonData = {}
  }
  return new SchoolLevelGroup(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.order || 0
  )
}

export class SchoolLevel {
  constructor(
    public id: number,
    public name: string,
    public fullName: string,
    public age: number,
    public group: SchoolLevelGroup
  ) {
  }
}

export function makeSchoolLevel(jsonData: any = null): SchoolLevel {
  if (jsonData === null) {
    jsonData = {}
  }
  return new SchoolLevel(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.full_name || '',
    jsonData.age || 0,
    makeSchoolLevelGroup(jsonData.group)
  )
}
