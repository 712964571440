import { render, staticRenderFns } from "./LegalRepresentativesDetail.vue?vue&type=template&id=4a9a88b6&scoped=true&"
import script from "./LegalRepresentativesDetail.vue?vue&type=script&lang=js&"
export * from "./LegalRepresentativesDetail.vue?vue&type=script&lang=js&"
import style0 from "./LegalRepresentativesDetail.vue?vue&type=style&index=0&id=4a9a88b6&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a9a88b6",
  null
  
)

export default component.exports