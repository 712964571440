<template>
  <span
    class="version-warning-badge"
    :class="{ 'demo-warning': isDemo, 'dev-warning': isDev, 'beta-warning': isBeta, }"
  >
    <span v-if="isDemo">démo</span>
    <span v-else-if="isDev">dev</span>
    <span v-else-if="isBeta">bêta</span>
  </span>
</template>

<script>

export default {
  name: 'DemoWarning',
  computed: {
    isDemo() {
      return +process.env.VUE_APP_DEMO_WARNING === 1
    },
    isDev() {
      return +process.env.VUE_APP_DEMO_WARNING === 2
    },
    isBeta() {
      return +process.env.VUE_APP_DEMO_WARNING === 3
    },
  },
  methods: {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.version-warning-badge {
  display: inline-block;
  padding: 3px 16px;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
}
@media(max-width: 800px) {
  .version-warning-badge {
    padding: 3px;
  }
}
.dev-warning {
  background: #ff2222;
}
.demo-warning {
  background: #ff8000;
}
.beta-warning {
  background: #fd59c5;
}
</style>
