<template>
<span class="add-tag-button" v-if="hasPerm('people.add_tag')">
    <a href @click.prevent="showModal" class="btn btn-sm btn-secondary">
      <i class="fa fa-plus"></i> Liste
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Ajouter"
      :ok-disabled="formInvalid"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-id-card"></i> Ajouter à une liste</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group label="Nom" label-for="nameTag">
            <vue-bootstrap-typeahead
              :data="tags"
              ref="nameTagTypeAhead"
              v-model="nameTag"
            ></vue-bootstrap-typeahead>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
          label="Commentaire"
          label-for="comments"
          >
            <b-form-input
            id="comments"
            type="text"
            v-model="comments"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'

export default {
  name: 'addTagsModal',
  mixins: [BackendMixin],
  props: {
    individual: Object,
  },
  data() {
    return {
      randomId: getRandomId(),
      errorText: '',
      tags: [],
      nameTag: '',
      comments: '',
    }
  },
  computed: {
    formInvalid() {
      return (this.nameTag === '')
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      // this.init()
      this.comments = ''
      this.loadTags()
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-add-tags-modal' + this.randomId
    },
    async loadTags() {
      let url = '/api/people/tags/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.tags = resp.data.map(elt => elt.name)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async onSave() {
      let url = '/api/people/add-tags/'
      const backendApi = new BackendApi('post', url)
      const data = {
        'name': this.nameTag,
        'individual': this.individual.id,
        'comments': this.comments,
      }
      try {
        const resp = await backendApi.callApi(data)
        await this.addSuccess(resp.data.message)
        this.$bvModal.hide(this.getId())
        this.$emit('done')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
</style>
