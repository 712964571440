<template>
  <div class="note-items">
    <ul class="notes">
      <li><span class="empty-text" v-if="items.length === 0">Aucune note</span></li>
      <li
        class="note"
        :class="item.archived ? 'priority-archived' : ('priority-' + item.priority)"
        v-for="item of items" :key="item.id"
      >
        <div class="note-action-buttons text-right">
          <a href @click.prevent="onEdit(item)" v-if="canEdit(item) && !noEdit">
            <span>
              <i class="fa fa-edit"></i>
            </span>
          </a>
          <a href @click.prevent="onDeleteClicked(item)" v-if="canDelete(item)">
            <i class="fa fa-trash" v-if="!item.archived"></i>
            <i class="fa fa-trash-o" v-else></i>
          </a>
          <a href @click.prevent="onCloseClicked(item)" v-if="canUnpin(item)">
            <i class="fa fa-map-pin"></i>
          </a>
        </div>
        <div class="note-block">
          <div class="note-header" @click.prevent="toggleItem($event, item)">
            <b-row>
              <b-col>
                <b>
                  <i class="fa fa-bell" v-if="item.priority === 1"></i>
                  <i class="fa fa-info-circle" v-else-if="item.priority === 3"></i>
                  <i class="fa fa-sticky-note" v-else></i>
                  <span v-if="item.archived">[ARCHIVE]</span>
                  {{ item.title }}
                </b>
              </b-col>
              <b-col cols="4" class="text-center" v-if="!side">
                <div>{{ item.createdOn | dateToString(dateFormat) }}</div>
              </b-col>
              <b-col :cols="side ? 4 : 3" class="text-right">
                <div v-if="side">
                  {{ item.createdOn | dateToString(dateFormat) }}
                </div>
                <span>De {{ item.createdBy }}</span>&nbsp;
              </b-col>
            </b-row>
          </div>
          <div v-if="isExpanded(item)">
            <div class="note-action" v-if="item.page">
              <b-row>
                <b-col class="text-right">
                  <a :href="item.page.path">
                    <i :class="item.page.icon"></i> {{ item.page.name }}
                  </a>
                </b-col>
              </b-row>
            </div>
            <div class="note-separator" v-if="item.body && item.page"></div>
            <div class="note-body" v-if="item.body">
              <b-row>
                <b-col>
                  <div v-html="item.bodyHtml()"></div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'note-items',
  components: {},
  mixins: [BackendMixin],
  props: {
    items: Array,
    home: {
      type: Boolean,
      default: false,
    },
    side: {
      type: Boolean,
      default: false,
    },
    viewBody: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedMap: new Map(),
      dateFormat: 'ddd D MMM YYYY HH:mm',
    }
  },
  computed: {
  },
  watch: {
    viewBody: function() {
      this.toggleAll(this.viewBody)
    },
  },
  methods: {
    canEdit(item) {
      if (!item.archived) {
        return this.hasPerm('notes.change_note')
      } else {
        return false
      }
    },
    canDelete(item) {
      return this.hasPerm('notes.delete_note')
    },
    canUnpin(item) {
      return !!this.home
    },
    onDeleteClicked(item) {
      this.$emit('item-delete', item)
    },
    onCloseClicked(item) {
      this.$emit('item-closed', item)
    },
    isExpanded(item) {
      return !!this.expandedMap.get(item.id)
    },
    toggleItem(event, item) {
      const isExpanded = this.isExpanded(item)
      if (event.shiftKey) {
        // si shift -> On change toutes les notes
        for (const curItem of this.items) {
          this.expandedMap.set(curItem.id, !isExpanded)
        }
      } else {
        this.expandedMap.set(item.id, !isExpanded)
      }
      this.expandedMap = new Map(this.expandedMap)
    },
    toggleAll(isExpanded) {
      for (const curItem of this.items) {
        this.expandedMap.set(curItem.id, isExpanded)
      }
      this.expandedMap = new Map(this.expandedMap)
    },
    onEdit(item) {
      this.$emit('item-edit', item)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
ul.notes {
  padding: 0;
  margin: 0;
  list-style: none;
}
ul.notes li.note {
  padding: 1px;
  border: solid 1px #ddd !important;
  background: #eee;
  margin-bottom: 10px;
}
ul.notes li.note .note-block {
  background: #e5faff;
  padding: 0;
  border-radius: 4px;
  font-size: 16px !important;
}
ul.notes li.note .note-block .note-header {
  background: #4d85af;
  color: #fff;
  padding: 4px;
  cursor: pointer;
}
ul.notes li.note .note-block .note-header a{
  color: #fff !important;
}
ul.notes li.note.priority-1 .note-block .note-header {
  background: #ef6469;
}
ul.notes li.note.priority-1 .note-block {
  background: #ffcbce;
}
ul.notes li.note.priority-2 .note-block .note-header {
  background: #4d85af;
}
ul.notes li.note.priority-2 .note-block {
  background: #c6e4f1;
}
ul.notes li.note.priority-3 .note-block .note-header {
  background: #a4a6ab;
}
ul.notes li.note.priority-archived .note-block {
  background: #fbffc6;
}
ul.notes li.note.priority-archived .note-block .note-header {
  background: #94945c;
}
ul.notes li.note.priority-3 .note-block {
  background: #d0d0d0;
}
ul.notes li a {
  color: #222 !important;
}
ul.notes li .note-block .note-body {
  padding: 5px;
}
ul.notes li.note .note-action {
  padding: 5px;
}
ul.notes li.note .note-separator {
  border-bottom: solid 1px #ccc;
}
.note-action-buttons a{
  font-size: 15px;
  margin-left: 4px;
}
</style>
