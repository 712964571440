<template>
  <span v-if="hasPerm('payments.view_credit')">
    <span class="badge">
      <span v-if="credit.refund" v-b-tooltip="refundDate">
        Remboursé {{ credit.refundAmount | currency }}
      </span>
    </span>
  </span>
</template>

<script>
import { currency, dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'refund-badge',
  components: {},
  mixins: [BackendMixin],
  props: {
    credit: Object,
  },
  data() {
    return {
    }
  },
  computed: {
    refundDate() {
      return 'remboursé le ' + dateToString(this.credit.refund.createdOn)
    },
  },
  methods: {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .badge {
    color: #000;
    display: block;
    margin-bottom: 2px;
    border: solid 1px #ccc;
  }
  .badge a {
    color: #000 !important;
  }
</style>
