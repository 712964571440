<template>
  <span>
    <div class="loading-gif" v-if="visible" :class="short ? 'loading-gif-short' : ''">
      <img src="@/assets/img/loading.gif" />
      <div class="loading-text" v-if="!short">
        <span v-if="message">{{ message }}</span><span v-else>Chargement en cours</span>
      </div>
    </div>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import store from '@/store'

export default {
  name: 'LoadingGif',
  props: {
    loadingName: String,
    short: {
      type: Boolean,
      defaultValue: false,
    },
    message: {
      type: String,
      defaultValue: '',
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    isLoading() {
      return store.getters.isLoading(this.loadingName)
    },
  },
  methods: {
    show() {
      this.visible = false
      let that = this
      setTimeout(() => {
        that.visible = this.isLoading
      }, 400)
    },
  },
  mounted() {
    this.show()
    store.watch(
      (state, getters) => getters.loading,
      (newValue, oldValue) => {
        const isLoading = newValue && newValue[this.loadingName]
        if (isLoading) {
          this.show()
        } else {
          this.visible = false
        }
      }
    )
  },
}
</script>
<style scoped lang="less">
  .loading-gif {
    text-align: left;
    font-size: 24px;
  }
  .loading-gif img{
    width: 32px;
  }
  .loading-text {
    display: inline-block;
    padding: 0 5px;
  }
  .loading-gif-short {
    display: inline-block;
  }
</style>
