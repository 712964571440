<template>
  <span v-if="hasPerm('payments.delete_sale')">
    <b-modal dialog-class="modal-md"
      :id="id"
      @ok.prevent="onSave"
      cancel-title="Annuler"
      ok-title="Modifier"
      :ok-disabled="!isValid"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fa fa-edit"></i>&nbsp;
          <span v-if="sale">Modifier le libellé d'une vente</span>
        </b>
      </template>
      <b-row>
        <b-col>
          <div v-if="errorText" class="warning-text">{{ errorText }}</div>
          <b-form-group label="Libellé" label-for="label">
            <b-form-input id="label" v-model="label" type="text" maxlength="200">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeSale } from '@/types/payments'

export default {
  name: 'change-sale-label-modal',
  components: {
  },
  mixins: [
    BackendMixin
  ],
  props: {
    sale: {
      type: Object,
      default: null,
    },
    id: String,
  },
  data() {
    return {
      label: '',
      errorText: '',
    }
  },
  computed: {
    isValid() {
      return this.sale && this.label && (this.label.length < 200)
    },
  },
  watch: {
    sale: function() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['addSuccess']),
    init() {
      if (this.sale) {
        this.label = this.sale.label.slice(0, 200)
      } else {
        this.label = ''
      }
    },
    async onSave() {
      if (this.sale) {
        const url = '/api/change-sale-label/' + this.sale.id + '/'
        const backendApi = new BackendApi('post', url)
        this.errorText = ''
        try {
          const resp = await backendApi.callApi({ label: this.label, })
          await this.addSuccess('Le libellé a été modifié')
          this.$emit('done', makeSale(resp.data))
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
      this.$bvModal.hide(this.id)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
