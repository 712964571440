<template>
  <div class="birthdays-button" v-if="isAuthenticated && canView">
    <a href @click.prevent="showBirthdays()">
      <i
        class="fa fa-birthday-cake right-bar-icon birthdays-icon"
        :class="isActive ? (!isRead ? 'highlighted' : '') : 'grayed'"
        title="Liste des anniversaires"
        v-b-tooltip
      ></i>
    </a>
    <b-modal
      dialog-class="modal-md"
      :id="modalId"
      ok-only
      ok-variant="secondary"
      ok-title="Fermer"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-birthday-cake"></i> Joyeux Anniversaire</b>
      </template>
      <b-row>
        <b-col>
          <table class="table table-striped">
            <tr v-for="individual of individuals" :key="individual.id">
              <td>
                <a href @click.prevent="showIndividualSidebar(individual)">{{ individual.firstAndLastName() }}</a>
              </td>
              <td>{{ individual.getAge() }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import { BackendApi } from '@/utils/http'
import { makeIndividual } from '@/types/people'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'BirthdaysButton',
  components: {},
  mixins: [BackendMixin],
  data() {
    return {
      individuals: [],
      modalId: 'bv-modal-view-birthdays',
      isRead: false,
    }
  },
  created() {
    this.loadData()
  },
  watch: {
    isAuthenticated: function() {
      this.loadData()
    },
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    isActive() {
      return this.individuals.length > 0
    },
    canView() {
      return this.hasPerm('people.view_individual')
    },
  },
  methods: {
    async loadData() {
      if (this.isAuthenticated && this.canView) {
        const backendApi = new BackendApi('get', '/api/people/birthdays/')
        try {
          const resp = await backendApi.callApi()
          this.individuals = resp.data.map(elt => makeIndividual(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    showBirthdays() {
      this.isRead = true
      this.$bvModal.show(this.modalId)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.birthdays-button {
  .birthdays-icon {
    top: 280px;
  }
}
</style>
