import { Config } from '@/types/config'
import { getConfig, storeConfig } from '@/apis/config'

export const configModule = {
  state: {
    config: getConfig(),
    serviceMessages: [],
  },
  getters: {
    familyLevelsCount: (state: any) => {
      return state.config.familyLevelsCount
    },
    allowEmptyLevel: (state: any) => {
      return state.config.allowEmptyLevel
    },
    structureName: (state: any) => {
      return state.config.structureName
    },
    youthHomeLabel: (state: any) => {
      return state.config.youthHomeLabel
    },
    youthHomeIcon: (state: any) => {
      return state.config.youthHomeIcon
    },
    daytimeLabels: (state: any) => {
      return state.config.daytimeLabels
    },
    hideAddress2: (state: any) => {
      return state.config.hideAddress2
    },
    showForceCity: (state: any) => {
      return state.config.showForceCity
    },
    config: (state: any) => {
      return state.config
    },
    serviceMessages: (state: any) => {
      return state.serviceMessages
    },
  },
  mutations: {
    setConfig(state: any, config: Config) {
      state.config = config
    },
    setServiceMessages(state: any, messages: any[]) {
      state.serviceMessages = messages
    },
  },
  actions: {
    setConfig(context: any, config: Config) {
      context.commit('setConfig', config)
      storeConfig(config)
    },
  },
  modules: {
  },
}

export default configModule
