<template>
  <div v-if="hasPerm('people.view_individual')">
    <div class="field-group-block">
      <div class="field-group-header">
        <b-row>
          <b-col cols="9">
            <b>Nouveau représentant légal</b>
          </b-col>
          <b-col cols="3" class="text-right">
          </b-col>
        </b-row>
      </div>
      <div class="field-group-block-inner">
        <div v-if="legalMember" class="search-individual-section">
          <b-row>
            <b-col>
              <b-form-group
                id="search-individual-group"
                label="Ajouter une personne déjà existant"
                label-for="search-individual"
                description="Recherchez une personne existante pour l'ajouter comme représentant légal"
              >
                <search-individual></search-individual>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div v-if="searchedIndividuals !== null">
                <div v-if="searchedIndividuals.length === 0">
                  Aucune personne avec ce nom
                </div>
                <div v-if="memberAlreadyExists">
                  <b>Des personnes avec ce nom existent déjà. Voulez-vous ajouter une personne existante?</b>
                </div>
                <div v-if="searchedIndividuals.length">
                  <div v-for="individual in searchedIndividuals" :key="individual.id"
                       class="search-individual-result"
                       :class="individual.id === legalMember.individual.id ? 'selected' : ''">
                    <b-row>
                      <b-col>{{ individual.fullName() }}</b-col>
                      <b-col class="text-right">
                        <a class="btn btn-secondary btn-sm" href @click.prevent="selectIndividual(individual)">
                          <i class="fa fa-check"></i> Sélectionner
                        </a>
                      </b-col>
                    </b-row>
                  </div>
                  <div
                    class="search-individual-result"
                    :class="0 === legalMember.individual.id ? 'selected' : ''"
                  >
                    <b-row v-if="searchedIndividuals.length > 0">
                      <b-col>Créer une nouvelle personne</b-col>
                      <b-col class="text-right">
                        <div>
                          <a class="btn btn-secondary btn-sm" href @click.prevent="selectNewIndividual()">
                            <i class="fa fa-check"></i> Sélectionner
                          </a>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-form @submit.prevent="onSave">
          <b-row>
            <b-col cols="4">
              <b-form-group
                id="role-group"
                label="Rôle*"
                label-for="role"
                description=""
              >
                <b-form-select id="role" v-model="formRole" required v-if="formRole" :rel="formRole.id">
                  <b-form-select-option-group :label="group.text" :value="group" v-for="group in roleGroups"
                                              v-bind:key="group.text">
                    <b-form-select-option :value="elt" v-for="elt in group.roles" v-bind:key="elt.id">
                      {{ elt.name }}
                    </b-form-select-option>
                  </b-form-select-option-group>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="8">
            </b-col>
          </b-row>
          <div class="hr"></div>
          <b-row>
            <b-col>
              <b-form-group
                id="gender-group"
                label="Civilité*"
                label-for="gender"
                description=""
              >
                <b-form-select
                  id="gender" v-model="formGender" required v-if="formGender !== null"
                  :rel="formGender.id || 0" :disabled="!isGenderActive">
                  <b-form-select-option :value="elt" v-for="elt in genders" v-bind:key="elt.id">
                    <span v-if="formRole.isChild">{{ elt.nameForChild }}</span>
                    <span v-else>{{ elt.nameForIndividual }}</span>
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="firstName-group"
                label="Prénom*"
                label-for="firstName"
                description=""
              >
                <b-form-input
                  id="firstName"
                  v-model="legalMember.individual.firstName"
                  required
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="lastName-group"
                label="Nom de famille*"
                label-for="lastName"
                description=""
              >
                <b-form-input
                  id="lastName"
                  v-model="legalMember.individual.lastName"
                  required
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="proPhone-group"
                label="Téléphone professionnel"
                label-for="proPhone"
                description=""
              >
                <b-form-input
                  id="proPhone"
                  v-model="legalMember.individual.proPhone"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="cellPhone-group"
                label="Téléphone mobile"
                label-for="cellPhone"
                description=""
              >
                <b-form-input
                  id="cellPhone"
                  v-model="legalMember.individual.cellPhone"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="email-group"
                label="Email"
                label-for="email"
                description=""
              >
                <b-form-input
                  id="email"
                  v-model="legalMember.individual.email"
                  type="email"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="buttons-bar">
            <b-col cols="3" class="text-left">
            </b-col>
            <b-col cols="9" class="text-right">
              <a class="btn btn-secondary" href @click.prevent="onCancel">Annuler</a>
              <b-button type="submit" variant="primary" class="btn-save-member" :disabled="!isSaveActive">
                Enregistrer
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeGender, makeEntityRole, makeIndividual } from '@/types/people'
import SearchIndividual from './SearchIndividual'
import { makeLegalRepresentatives } from '@/types/families'

export default {
  name: 'legal-member',
  components: {
    SearchIndividual,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
    member: Object,
    legalMember: Object,
  },
  data() {
    return {
      formRole: null,
      formGender: null,
      searchedIndividuals: null,
      memberAlreadyExists: false,
    }
  },
  created() {
    this.formRole = makeEntityRole({})
    this.formGender = makeGender({})
    store.watch(
      (state, getters) => getters.searchedIndividuals,
      (newValue, oldValue) => {
        if (newValue) {
          this.searchedIndividuals = newValue
        }
      }
    )
  },
  computed: {
    genders() {
      return store.getters.genders
    },
    roleGroups() {
      let roleGroups = [
        { text: 'Représentant légaux', roles: [], }
      ]
      let roles = store.getters.roles || []
      roles = roles.filter(elt => elt.isLegalRepresentative).sort(
        (eltA, eltB) => { return eltA.id === eltB.id ? 0 : (eltA.id > eltB.id ? 1 : -1) }
      )
      for (let role of roles) {
        roleGroups[0].roles.push(role)
      }
      return roleGroups
    },
    isGenderActive() {
      if (this.formRole) {
        return this.formRole.gender === 0
      }
      return true
    },
    isSaveActive() {
      return (this.formRole !== null) && (this.formGender !== null)
    },
  },
  watch: {
    // whenever role changes, this function will run
    formRole: function(newRole, oldRole) {
      if (newRole && newRole.gender !== 0) {
        this.formGender = this.getGender(newRole.gender)
      }
    },
  },
  methods: {
    ...mapActions([
      'addError', 'addSuccess'
    ]),
    ...mapMutations(['setSearchedIndividuals', 'setEditMode']),
    getGender(value) {
      for (let gender of this.genders) {
        if (gender.id === value) {
          return gender
        }
      }
      return null
    },
    async checkAlreadyExist() {
      this.memberAlreadyExists = false
      try {
        let url = '/api/people/search-individual/?last_name=' + this.legalMember.individual.lastName +
          '&first_name=' + this.legalMember.individual.firstName
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        let individuals = await resp.data.map(elt => makeIndividual(elt))
        if (individuals.length === 0) {
          this.memberAlreadyExists = false
          await this.doSave()
        } else {
          this.memberAlreadyExists = true
          this.setSearchedIndividuals(individuals)
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getGenderName() {
      if (this.isChild) {
        return this.member.individual.gender.nameForChild
      } else {
        return this.member.individual.gender.nameForIndividual
      }
    },
    selectIndividual(individual) {
      this.legalMember.individual = { ...individual, }
      this.formGender = { ...individual.gender, }
    },
    selectNewIndividual() {
      let firstName = this.legalMember.individual.firstName
      let lastName = this.legalMember.individual.lastName
      let gender = this.legalMember.individual.gender
      this.legalMember.individual = makeIndividual()
      this.legalMember.individual.firstName = firstName
      this.legalMember.individual.lastName = lastName
      this.formGender = { ...gender, }
    },
    onCancel() {
      this.setEditMode('')
      this.resetLegalMember()
    },
    async onSave() {
      if (this.legalMember.individual.id !== 0) {
        // Existing member : save it
        await this.doSave()
      } else {
        if (this.memberAlreadyExists) {
          // The duplicates warning have been displayed. Let's go
          await this.doSave()
        } else {
          // New member : make sure if there is no duplicates
          await this.checkAlreadyExist()
        }
      }
    },
    async doSave() {
      let data = [
        {
          individual: {
            id: this.legalMember.individual.id,
            last_name: this.legalMember.individual.lastName,
            first_name: this.legalMember.individual.firstName,
            gender: this.formGender.id,
            pro_phone: this.legalMember.individual.proPhone,
            cell_phone: this.legalMember.individual.cellPhone,
            email: this.legalMember.individual.email,
          },
          role: this.formRole.id,
          not_allowed: false,
        }
      ]
      let url = '/api/families/legal-representatives/' + this.entity.id + '/' + this.member.individual.id + '/'
      let backendApi = new BackendApi('post', url)
      try {
        let resp = await backendApi.callApi(data)
        const legalRepresentatives = makeLegalRepresentatives(resp.data)
        this.$emit('change', legalRepresentatives)
        this.setEditMode('')
        await this.addSuccess('Le représentant légal a été ajouté')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.comments {
  padding: 4px;
  background: #ccc;
}
.search-individual-section {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #ccc;
}
.search-individual-result {
  padding: 5px;
}
.search-individual-result:nth-child(even) {
  background: #e8e8e8;
}
.search-individual-result .fa-check {
  color: transparent;
}
.search-individual-result.selected {
  background: #444;
  color: #fff;
}
.search-individual-result.selected .fa-check {
  color: #444;
}
</style>
