import { encrypt, decrypt } from '@/utils/encrypt'
import { User } from '@/types/auth'

const tabsCounterKey = 'millibase-tabs-counter'

export function storeUser(user: User): void {
  const userAsString: string = JSON.stringify(user)
  window.localStorage.setItem('user', encrypt(userAsString))
}

export function loadUser(): User {
  const encryptedValue = window.localStorage.getItem('user')
  if (encryptedValue) {
    let value = ''
    try {
      value = decrypt(encryptedValue)
    } catch (exc1) {
      value = ''
    }
    if (value) {
      try {
        const user: User = JSON.parse(value)
        if (user.token.length > 0) {
          return user
        }
      } catch (exc2) {
      }
    }
  }
  // anonymous User
  return new User()
}

export function incTabCounter(inc: number) {
  const key = tabsCounterKey
  let tabsCounterString: string = window.localStorage.getItem(key) || ''
  let tabsCounter = +tabsCounterString
  if (isNaN(tabsCounter)) {
    tabsCounter = 0
  }
  if (inc) {
    tabsCounter += inc
    window.localStorage.setItem(key, '' + tabsCounter)
  }
  return tabsCounter
}

export function setTabCounter(value: number) {
  window.localStorage.setItem(tabsCounterKey, '' + value)
}
