<template>
  <div class="page-header">
    <b-row>
      <b-col cols="6">
        <div class="page-header-h1">
          <span class="main-icon" v-if="icon"><i :class="icon"></i></span>
          <span class="main-icon" v-else><i class="fa fa-chevron-right"></i></span>
          {{ title }}
        </div>
      </b-col>
      <b-col class="text-right hide-here" v-if="printHour" cols="5">
        {{ printHourValue }}
      </b-col>
      <b-col class="no-print">
        <div class="menu-items">
          <b-dropdown variant="secondary" v-if="extraLinks && extraLinks.length" class="extra-links" size="sm">
            <template #button-content>
              <i class="fa fa-ellipsis-h"></i>
            </template>
            <b-dropdown-item
              v-for="link in extraLinks"
              :class="link.cssClass"
              :key="link.id"
              @click.prevent="call(link)"
              variant="secondary"
            >
              <i :class="link.icon"></i> {{ link.label }}
            </b-dropdown-item>
          </b-dropdown>
          <a
            v-for="link in links"
            href
            class="btn btn-sm"
            :class="link.cssClass"
            :key="link.id"
            @click.prevent="call(link)"
          >
            <i :class="link.icon"></i> {{ link.label }}
          </a>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import moment from 'moment'
import store from '@/store'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'page-header',
  mixins: [BackendMixin],
  props: {
    title: String,
    icon: String,
    links: Array,
    extraLinks: {
      type: Array,
      defaultValue: [],
    },
    printHour: {
      type: Boolean,
      defaultValue: false,
    },
  },
  components: {
  },
  computed: {
  },
  data() {
    return {
      printHourValue: '',
    }
  },
  watch: {
    icon: function() { this.changeNav() },
    title: function() {
      this.changeNav()
    },
  },
  methods: {
    changeNav() {
      document.title = this.title
      store.dispatch('changeNav', { title: this.title, icon: this.icon, })
    },
    call(link) {
      this.printHourValue = moment().format('DD/MM/YYYY HH:mm')
      this.$nextTick(
        function() {
          link.callback(link)
        }
      )
    },
  },
  mounted() {
    this.changeNav()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.page-header {
  border-bottom: solid 1px #ccc;
  margin: 15px 0 10px -15px;
  background: #ccc;
}

.page-header .main-icon {
  font-size: 22px;
  margin-left: 5px;
  vertical-align: top;
  display: inline-block;
  text-align: center;
}

.page-header-h1 .main-icon i {
  color: #b83375;
}

.page-header h1 {
  margin: 0;
  display: inline-block;
}

.page-header .menu-items {
  display: inline-block;
  float: right;
  vertical-align: top;
}

.page-header .menu-items a{
  margin-right: 10px;
}

.page-header .menu-items a:last-of-type{
  margin-right: 0;
}

.page-header .extra-links {
  display: inline-block;
  margin-right: 10px;
}

.page-header-h1 {
  position: relative;
  display: block;
  height: 32px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 20px;
}
</style>
