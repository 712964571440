
export function escapeHtml(unsafe: string) {
  return unsafe.replace(
    /&/g, '&amp;'
  ).replace(
    /</g, '&lt;'
  ).replace(
    />/g, '&gt;'
  ).replace(
    /"/g, '&quot;'
  ).replace(
    /'/g, '&#039;'
  )
}

export function htmlText(text: string) {
  const escapedMessage = escapeHtml(text)
  return escapedMessage.split('\n').join('<br />')
}

export function replaceAll(text: string, search: string, replacement: string): string {
  return text.split(search).join(replacement)
}

export function enrichedHtmlText(text: string) {
  const escapedMessage = escapeHtml(text)
  let html: string = replaceAll(
    escapedMessage, '[b]', '<b>'
  )
  html = replaceAll(
    html, '[/b]', '</b>'
  )
  return html.split('\n').join('<br />')
}
