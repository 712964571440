<template>
  <div
    class="service-messages" v-if="isAuthenticated && serviceMessages.length"
  >
    <a href @click.prevent="showScreen()">
      <i
        class="fa fa-bell service-messages-icon right-bar-icon"
        title="Voir les messages"
        v-b-tooltip
      ></i>
    </a>
    <div class="service-messages-content" v-show="isScreenShown" :style="windowsStyle">
      <b-row class="close-screen-line">
        <b-col>
          <h3><i class="fa fa-bell"></i> Messages</h3>
        </b-col>
        <b-col cols="3" class="text-right">
          <a class="close-link" href @click.prevent="isScreenShown = false"><i class="fa fa-close"></i></a>
        </b-col>
      </b-row>
      <div class="service-messages-line" v-for="item of serviceMessages" :key="item.id">
          {{ item.message }}
          <a class="more-info" v-if="item.url" :href="item.url" target="_blank">En savoir plus</a>
      </div>
    </div>
  </div>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'

export default {
  name: 'ServiceMessages',
  components: {},
  mixins: [BackendMixin],
  data() {
    return {
      isScreenShown: false,
    }
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    serviceMessages() {
      return store.getters.serviceMessages
    },
    windowsStyle() {
      return { 'min-height': '100vw', }
    },
  },
  watch: {
    serviceMessages: function() {},
  },
  methods: {
    showScreen() {
      this.isScreenShown = true
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth', })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.service-messages {
  .service-messages-icon {
    top: 41px;
  }
  .service-messages-content {
    width: 100vw;
    top: 0;
    left: 0;
    min-height: 100vmax;
    position: absolute;
    background: #db6c6c;
    color: #fff;
    opacity: 1;
    z-index: 50;
    padding: 30px 50px;
  }

  .close-screen-line {
    font-size: 32px;
    margin-bottom: 10px;
  }

  .service-messages-line {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #fff;
    font-weight: bold;
  }

  .service-messages-line:last-of-type {
    border-bottom: none;
  }

  a.link {
    color: #fff !important;
  }

  a.more-info {
    color: #db6c6c !important;
    padding: 5px;
    border: solid 1px #fff;
    background: #fff;
  }

  a.more-info:hover {
    background: #f0f0f0;
  }

  a.close-link {
    color: #fff !important;
  }
}
</style>
