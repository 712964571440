<template>
  <span class="payment-button" v-if="hasPerm('payments.add_payment')">
    <a href @click.prevent="showModal" :class="cssStyle">
      <i class="fa" :class="icon | defaultValue('fa-money-bill')"></i>
      {{ text | defaultValue('Payer') }}
    </a>
    <payment-modal
      :invoice="invoice"
      :entity="entity"
      :modal-id="getId()"
      @paid="onPaid()"
    ></payment-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import PaymentModal from '@/components/Payments/PaymentModal.vue'
import { getRandomId } from '@/utils/random'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'payment-button',
  components: { PaymentModal, },
  mixins: [ BackendMixin ],
  props: {
    invoice: Object,
    entity: Object,
    cssClass: {
      type: String,
      defaultValue: '',
    },
    text: {
      type: String,
      defaultValue: '',
    },
    icon: {
      type: String,
      defaultValue: '',
    },
  },
  data() {
    return {
      randomId: getRandomId(),
    }
  },
  computed: {
    cssStyle() {
      return this.cssClass ? this.cssClass : 'btn btn-primary btn-sm'
    },
  },
  watch: {
    invoice: function() {
    },
    entity: function() {
    },
    cssClass: function(newValue, oldValue) {},
    text: function(newValue, oldValue) {},
  },
  methods: {
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-pay-invoice' + this.randomId
    },
    onPaid() {
      this.$emit('paid')
    },
  },
}
</script>
<style lang="less">
  .payment-button a {
    display: block;
  }
</style>
