import { FieldsGroup, FieldValues, makeFieldsGroup } from '@/types/fields'

export const fieldsModule = {
  state: {
    fieldsGroups: [],
  },
  getters: {
    fieldsGroups: (state: any) => {
      return state.fieldsGroups
    },
  },
  mutations: {
    setFieldGroups(state: any, groups: FieldsGroup[]) {
      state.fieldsGroups = groups
    },
  },
  actions: {
  },
  modules: {
  },
}

export default fieldsModule
