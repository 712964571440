<template>
  <div :class="mainClass">
    <div v-if="choices.length > 1 && !hideAll" class="check-all" :class="cssClasses">
      <b-checkbox
        :id="id + 'check-all-choices'"
        :checked="isAllChecked()"
        :value="true"
        :unchecked-value="false"
        @change="checkAll($event)"
      >
        Tous
      </b-checkbox>
    </div>
    <div v-for="choice of choices" :key="choice.id" :class="cssClasses" :style="getStyle(choice)">
      <b-checkbox
        :id="id + 'check-choice' + choice.id"
        :checked="isChoiceChecked(choice)"
        :value="true"
        :unchecked-value="false"
        :disabled="isChoiceDisabled(choice)"
        @change="checkChoice(choice, $event)"
      >
        {{ getName(choice) }}
        <div v-if="descriptionCallback" class="checkbox-description">
          {{ descriptionCallback(choice) }}
        </div>
      </b-checkbox>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
export default {
  name: 'CheckBoxSelect',
  props: {
    choices: Array,
    inline: {
      type: Boolean,
      default: false,
    },
    span: {
      type: Boolean,
      default: false,
    },
    box: {
      type: Boolean,
      default: false,
    },
    lineBottom: {
      type: Boolean,
      default: false,
    },
    lineSmall: {
      type: Boolean,
      default: false,
    },
    hideAll: {
      type: Boolean,
      default: false,
    },
    descriptionCallback: {
      type: Function,
      default: null,
    },
    styleCallback: {
      type: Function,
      default: null,
    },
    nameCallback: {
      type: Function,
      default: null,
    },
    initialValue: {
      type: Array,
      defaultValue: [],
    },
    disabledChoices: {
      type: Array,
      defaultValue: [],
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedChoices: {},
    }
  },
  watch: {
    choices: function() {},
    initialValue: function() { this.initChoices() },
    disabledChoices: function() { this.updateChoices() },
  },
  computed: {
    mainClass() {
      return { inline: this.span, }
    },
    cssClasses() {
      return { inline: this.inline, box: this.box, lineBottom: this.lineBottom, lineSmall: this.lineSmall, }
    },
  },
  methods: {
    emitChange() {
      let choices = this.choices.filter(elt => this.isChoiceChecked(elt))
      this.$emit('changed', { choices, all: choices.length === this.choices.length, })
    },
    initChoices() {
      const selectedChoices = {}
      if (typeof this.initialValue !== 'undefined') {
        for (const initialItem of this.initialValue) {
          selectedChoices[initialItem.id] = true
        }
      }
      this.selectedChoices = selectedChoices
      let choices = this.choices.filter(elt => this.isChoiceChecked(elt))
      this.$emit('init', { choices, all: choices.length === this.choices.length, })
    },
    updateChoices() {
      const selectedChoices = { ...this.selectedChoices, }
      if (typeof this.disabledChoices !== 'undefined') {
        for (const initialId of this.disabledChoices) {
          selectedChoices[initialId] = false
        }
      }
      this.selectedChoices = selectedChoices
    },
    isChoiceChecked(choice) {
      if (!this.isChoiceDisabled(choice) && this.selectedChoices.hasOwnProperty(choice.id)) {
        return this.selectedChoices[choice.id]
      }
      return false
    },
    isChoiceDisabled(choice) {
      if (typeof this.disabledChoices !== 'undefined') {
        return this.disabledChoices.indexOf(choice.id) >= 0
      }
      return false
    },
    isAllChecked() {
      if (!this.hideAll) {
        for (let choice of this.choices) {
          if (!this.isChoiceDisabled(choice) && !this.isChoiceChecked(choice)) {
            return false
          }
        }
      }
      return true
    },
    checkChoice(choice, value) {
      this.selectedChoices[choice.id] = value
      this.selectedChoices = { ...this.selectedChoices, }
      this.emitChange()
    },
    checkAll(value) {
      for (let choice of this.choices) {
        if (!this.isChoiceDisabled(choice)) {
          this.selectedChoices[choice.id] = value
        }
      }
      this.selectedChoices = { ...this.selectedChoices, }
      this.emitChange()
    },
    getName(elt) {
      if (this.nameCallback) {
        return this.nameCallback(elt)
      } else {
        return elt.name
      }
    },
    getStyle(elt) {
      if (this.styleCallback) {
        return this.styleCallback(elt)
      } else {
        return ''
      }
    },
  },
  mounted() {
    this.initChoices()
  },
}
</script>
<style scoped lang="less">
.inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 2px;
}
.box {
  margin-right: 5px;
  border: solid 1px #ccc;
  padding: 2px 4px;
}
.lineBottom {
  border-bottom: solid 1px #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.lineBottom:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.check-all{
  color: #888;
}
.checkbox-description {
  font-size: 10px;
}
.lineSmall {
  font-size: 16px;
  border-bottom: solid 1px #ccc;
  padding-bottom: 2px;
  margin-bottom: 2px;
}
.lineSmall:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>
