import { Config, makeConfig } from '@/types/config'
import { BackendApi, getErrorText, isAuthenticated } from '@/utils/http'
import store from '@/store'

export function storeConfig(config: Config): void {
  const configAsString: string = config.serialize()
  window.localStorage.setItem('config', configAsString)
}

export function getConfig(): Config {
  const configAsString = window.localStorage.getItem('config')
  const config = makeConfig()
  if (configAsString) {
    config.deserialize(configAsString)
  }
  return config
}

export async function loadConfig() {
  if (isAuthenticated()) {
    const backendApi = new BackendApi('get', '/api/config/')
    try {
      const resp = await backendApi.callApi(null, '', true)
      const config = makeConfig(resp.data)
      await store.dispatch('setConfig', config)
    } catch (err) {
      await store.dispatch('addError', getErrorText(err))
    }
  }
}
