import { Vaccine, Vaccinations } from '@/types/vaccines'

export const vaccinesModule = {
  state: {
    vaccines: [],
  },
  getters: {
    vaccines: (state: any) => {
      return state.vaccines
    },
  },
  mutations: {
    setVaccines(state: any, vaccines: Vaccine[]) {
      state.vaccines = vaccines
    },
  },
  actions: {
  },
  modules: {
  },
}

export default vaccinesModule
