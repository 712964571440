<template>
  <div>
    <span
      v-for="analytic in analytics"
      :key="analytic.getKey()"
      class="analytics-account-elt"
      :class="from ? 'from' : ''"
      v-b-tooltip="fullLabel"
    >
      <span v-if="showGeneralAccount">{{ analytic.getFullLabel() }}</span>
      <span v-else>{{ analytic.getLabel() }}</span>
      <span>: {{ analytic.amount | currency }}</span>
    </span>
  </div>
</template>

<script>

import { currency } from '@/filters/texts'
import { sum } from '@/utils/math'

export default {
  name: 'analytics-detail-view',
  components: {},
  mixins: [],
  props: {
    analytics: Array,
    from: {
      type: Boolean,
      defaultValue: false,
    },
    label: {
      type: String,
      defaultValue: '',
    },
    showGeneralAccount: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    total() {
      return sum(this.analytics.map(elt => elt.amount))
    },
    fullLabel() {
      let prefix = ''
      if (this.label) {
        prefix = this.label + ' '
      }
      return prefix + currency(this.total)
    },
  },
  methods: {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .analytics-account-elt {
    font-size: 11px;
    display: inline-block;
    margin: 2px;
    padding: 2px;
    border: solid 1px #e0e0e0;
  }
  .analytics-account-elt.from {
    background: #444;
    border: solid 1px #222;
    color: #fff;
  }
</style>
