
export function distinct(objects: any[], field: string = 'id'): any[] {
  const ids = objects.map(elt => elt[field])
  return objects.filter((elt, index) => ids.indexOf(elt[field]) === index)
}

export function distinctElements(elements: any[]): any[] {
  return elements.filter((elt, index) => elements.indexOf(elt) === index)
}

export function distinctString(objects: string[]): string[] {
  const strings = objects.concat([])
  return objects.filter((elt, index) => strings.indexOf(elt) === index)
}

export function existsIn(list1: any[], list2: any[]): boolean {
  for (const value of list1) {
    if (list2.indexOf(value) >= 0) {
      return true
    }
  }
  return false
}

export function getObjIndex(obj: any, list2: any[]): number {
  const ids = list2.map(elt => +elt.id)
  return ids.indexOf(+obj.id)
}

export function areSameArrays(list1: any[], list2: any[], reorder: any = null): boolean {
  if (list1.length === list2.length) {
    let array1 = list1
    let array2 = list2
    if (reorder) {
      array1 = array1.sort(reorder)
      array2 = array2.sort(reorder)
    }
    for (let index = 0; index < array1.length; index++) {
      if (array1[index] !== array2[index]) {
        return false
      }
    }
    return true
  }
  return false
}

export function intersection(list1: any[], list2: any[]): any[] {
  const array: any[] = []
  for (const elt of list1) {
    if (list2.indexOf(elt) >= 0) {
      array.push(elt)
    }
  }
  return array
}

export class SubGroup {
  constructor(
    public index: number,
    public values: string[]
  ) {
  }

  public asText(separator: string, suffix: string = ''): string {
    let values = this.values
    if (suffix) {
      values = this.values.map(value => value + suffix)
    }
    return values.join(separator)
  }
}

export function subGrouper(values: (string|string[])[], increment: number = 25): SubGroup[] {
  const groups: SubGroup[] = []
  const flatList: string[] = values.reduce(
    (items: string[], current) => {
      if (Array.isArray(current)) {
        items = items.concat(current)
      } else {
        items.push(current)
      }
      return items
    },
    []
  )
  const uniqueValues: string[] = flatList.reduce(
    (items: string[], current) => {
      if (items.indexOf(current) < 0) {
        items.push(current)
      }
      return items
    },
    []
  )
  let index = 0
  for (let startIndex = 0; startIndex < uniqueValues.length; startIndex += increment) {
    let endIndex = startIndex + increment
    endIndex = Math.min(endIndex, uniqueValues.length)
    const subSlice: string[] = uniqueValues.slice(startIndex, endIndex)
    groups.push(
      new SubGroup(index, subSlice)
    )
    index += 1
  }
  return groups
}

export class SubGroupeAny {
  constructor(
    public index: number,
    public values: any[]
  ) {
  }
}

export function subGrouperAny(values: any, increment: number = 25): SubGroupeAny[] {
  const groups: SubGroupeAny[] = []
  let index = 0
  for (let startIndex = 0; startIndex < values.length; startIndex += increment) {
    let endIndex = startIndex + increment
    endIndex = Math.min(endIndex, values.length)
    const subSlice: any[] = values.slice(startIndex, endIndex)
    groups.push(
      new SubGroupeAny(index, subSlice)
    )
    index += 1
  }
  return groups
}
