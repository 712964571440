<template>
  <span class="contact-support">
    <b-modal
      dialog-class="modal-md"
      id="bv-contact-support"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-user-edit"></i> Contacter le support</b>
      </template>
      <div>
        Un problème? Une question?<br /><br />
        Contactez-nous par email à l'adresse {{ email }} en donnant le maximum d'informations
        et en joignant si possible une ou plusieurs captures d'écran.<br /><br />
        Cliquez sur "{{ email }}" pour ouvrir votre logiciel de messagerie

      </div>
      <template v-slot:modal-footer>
        <div class="w-100 text-right">
          <b-row>
            <b-col cols="3" class="text-left">
              <a class="btn btn-secondary" @click.prevent="onOnlineHelp">
                Aide
              </a>
            </b-col>
            <b-col class="text-right">
              <a class="btn btn-secondary" @click.prevent="onCancel">
                Annuler
              </a>
              &nbsp;
              <a class="btn btn-primary" @click.prevent="onContactSupport">
                {{ email }}
              </a>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
  </span>
</template>

<script>

export default {
  name: 'ContactSupport',
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    email() {
      return 'support@millibase.net'
    },
  },
  methods: {
    onCancel() {
      this.$bvModal.hide('bv-contact-support')
    },
    onOnlineHelp() {
      this.$bvModal.hide('bv-contact-support')
      this.$bvModal.show('bv-online-help')
    },
    onContactSupport() {
      const email = this.email
      const subject = 'Support Millibase.net:'
      const body = 'Une image vaut mille mots! Pensez à inclure une capture d\'écran.'
      const link = 'mailto:' + email + '?subject=' + subject + '&body=' + body
      this.$bvModal.hide('bv-contact-support')
      setTimeout(
        function() {
          window.open(link)
        }, 100
      )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
