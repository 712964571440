
export function displayHourMin(time: string, defaultValue: string = ''): string {
  time = time || ''
  if (time) {
    const timeAsArrayOfStrings = time.split(':').map(elt => elt)
    return timeAsArrayOfStrings[0] + ':' + timeAsArrayOfStrings[1]
  } else {
    return defaultValue
  }
}

export function roundedTime(time: string, minutes: number = 30): string {
  // roundedTime('09:17', 30) -> 9:30
  // assume that time is a valid time
  const timeAsArrayOfNumbers = time.split(':').map(elt => +elt)
  if (timeAsArrayOfNumbers.length === 1) {
    timeAsArrayOfNumbers[0] = isNaN(timeAsArrayOfNumbers[0]) ? 0 : timeAsArrayOfNumbers[0]
  }
  // rounded minutes
  if (timeAsArrayOfNumbers.length > 1) {
    const value = isNaN(timeAsArrayOfNumbers[1]) ? 0 : timeAsArrayOfNumbers[1]
    const roundedMin = Math.round(value / minutes) * minutes
    if (roundedMin >= 60) {
      timeAsArrayOfNumbers[0] = timeAsArrayOfNumbers[0] + 1
      timeAsArrayOfNumbers[1] = 0
    } else {
      timeAsArrayOfNumbers[1] = roundedMin
    }
  } else {
    timeAsArrayOfNumbers.push(0)
  }
  // seconds
  if (timeAsArrayOfNumbers.length > 2) {
    timeAsArrayOfNumbers[2] = 0
  } else {
    timeAsArrayOfNumbers.push(0)
  }
  const timeAsArrayOfStrings = timeAsArrayOfNumbers.map(elt => elt > 9 ? +elt : '0' + elt)
  return timeAsArrayOfStrings.join(':')
}

export function addMinutes(time: string, minutes: number): string {
  // add n minutes to the current time
  // assume that time is a valid time
  let timeAsArrayOfNumbers = time.split(':').map(elt => +elt)
  timeAsArrayOfNumbers[1] += minutes
  while (timeAsArrayOfNumbers[1] >= 60) {
    timeAsArrayOfNumbers[1] -= 60
    timeAsArrayOfNumbers[0] += 1
  }
  if (timeAsArrayOfNumbers[0] >= 24) {
    timeAsArrayOfNumbers = [23, 59, 59]
  }
  const timeAsArrayOfStrings = timeAsArrayOfNumbers.map(elt => elt > 9 ? +elt : '0' + elt)
  return timeAsArrayOfStrings.join(':')
}

export function removeMinutes(time: string, minutes: number): string {
  // remove n minutes to the given time
  // assume that time is a valid time
  let timeAsArrayOfNumbers = time.split(':').map(elt => +elt)
  timeAsArrayOfNumbers[1] -= minutes
  while (timeAsArrayOfNumbers[1] < 0) {
    timeAsArrayOfNumbers[1] = 60 + timeAsArrayOfNumbers[1]
    timeAsArrayOfNumbers[0] -= 1
  }
  if (timeAsArrayOfNumbers[0] < 0) {
    timeAsArrayOfNumbers = [0, 0, 0]
  }
  let timeAsArrayOfStrings = timeAsArrayOfNumbers.map(elt => elt > 9 ? +elt : '0' + elt)
  return timeAsArrayOfStrings.join(':')
}

export function compareTimes(time1: string, time2: string): number {
  // assume that time is a valid time
  // returns -1 if time1 is after time2, 0 if equal and 1 if time2 is after time1
  let array1 = time1.split(':').map(elt => +elt)
  let array2 = time2.split(':').map(elt => +elt)
  for (let index = 0; index < 3; index++) {
    const value1 = (index < array1.length) ? array1[index] : 0
    const value2 = (index < array2.length) ? array2[index] : 0
    if (value1 > value2) {
      return -1
    } else if (value2 > value1) {
      return 1
    }
  }
  return 0
}

export function getArrivalBaseTime(currentTime: string, openingTime: string, step: number): string {
  // on recherche l'heure de base de l'heure d'arrivée
  // ouverture à 8h00, arrivée à 9:31, step=30 minutes alors heure de base est 10:00.
  let baseTime: string = openingTime
  const stepInSeconds: number = step * 60
  let diffInSeconds = diffTimes(currentTime, baseTime)
  while (diffInSeconds > 0) {
    baseTime = addMinutes(baseTime, step)
    diffInSeconds = diffTimes(currentTime, baseTime)
  }
  return baseTime
}

export function getArrivalTimeWithTolerance(
  currentTime: string, openingTime: string, tolerance: number, step: number
): string {
  // on recherche l'heure de base de l'heure d'arrivée
  let baseTime: string = getArrivalBaseTime(currentTime, openingTime, step)
  let result = baseTime
  // si la différence entre l'heure d'arrivée et l'heure de base est inférieur à la tolérance alors on prend
  // l'heure de base. Sinon, on prend le step précédent
  const diffInSeconds = diffTimes(baseTime, currentTime)
  const toleranceInSeconds = tolerance * 60
  if (diffInSeconds > toleranceInSeconds) {
    result = removeMinutes(baseTime, step)
  }
  // si le résultat est inférieur à l'heure d'ouverture alors on prend l'heure d'ouverture.
  if (diffTimes(result, openingTime) < 0) {
    result = openingTime
  }
  return result
}

export function getDepartureBaseTime(currentTime: string, closingTime: string, step: number): string {
  // on recherche l'heure de base de l'heure de départ
  // fermeture à 18h00, départ à 16h51, step=30 minutes alors heure de base est 16:30.
  let baseTime: string = closingTime
  const stepInSeconds: number = step * 60
  let diffInSeconds = diffTimes(baseTime, currentTime)
  while (diffInSeconds > 0) {
    baseTime = removeMinutes(baseTime, step)
    diffInSeconds = diffTimes(baseTime, currentTime)
  }
  return baseTime
}

export function getDepartureTimeWithTolerance(
  currentTime: string, closingTime: string, tolerance: number, step: number
): string {
  // on recherche l'heure de base de l'heure d'arrivée
  let baseTime: string = getDepartureBaseTime(currentTime, closingTime, step)
  // si la différence entre l'heure d'arrivée et l'heure de base est inférieur à la tolérance alors on prend
  // l'heure de base. Sinon, on prend le step précédent
  let result: string = baseTime
  const diffInSeconds = diffTimes(currentTime, baseTime)
  const toleranceInSeconds = tolerance * 60
  if (diffInSeconds >= toleranceInSeconds) {
    result = addMinutes(baseTime, step)
  }
  // si le résultat est inférieur à l'heure d'ouverture alors on prend l'heure d'ouverture.
  if (diffTimes(result, closingTime) > 0) {
    result = closingTime
  }
  return result
}

// export function ceiledTime(time: string, limit: number, ceil: number, after: boolean = false): string {
//   // ceiledTime('09:06:00', 6, 30) -> '09:30'
//   // ceiledTime('09:05:59', 6, 30) -> '09:00'
//   // assume that time is a valid time
//   const timeAsArrayOfNumbers = time.split(':').map(elt => +elt)
//   if (timeAsArrayOfNumbers.length === 1) {
//     timeAsArrayOfNumbers[0] = isNaN(timeAsArrayOfNumbers[0]) ? 0 : timeAsArrayOfNumbers[0]
//   }
//   if (after) {
//     // rounded minutes
//     if (timeAsArrayOfNumbers.length > 1) {
//       let value = isNaN(timeAsArrayOfNumbers[1]) ? 0 : timeAsArrayOfNumbers[1]
//       const modulo = value % ceil
//       let step = 0
//       if (modulo >= limit) {
//         step = 1
//       }
//       value = (value - modulo) + step * ceil
//       if (value >= 60) {
//         timeAsArrayOfNumbers[0] = timeAsArrayOfNumbers[0] + 1
//         timeAsArrayOfNumbers[1] = 0
//       } else {
//         timeAsArrayOfNumbers[1] = value
//       }
//     } else {
//       timeAsArrayOfNumbers.push(0)
//     }
//     // seconds
//     if (timeAsArrayOfNumbers.length > 2) {
//       timeAsArrayOfNumbers[2] = 0
//     } else {
//       timeAsArrayOfNumbers.push(0)
//     }
//   } else {
//     if (timeAsArrayOfNumbers.length > 1) {
//       let value = isNaN(timeAsArrayOfNumbers[1]) ? 0 : timeAsArrayOfNumbers[1]
//       const modulo = value % ceil
//       let step = 0
//       if (modulo >= limit) {
//         step = 1
//       }
//       value = (value - modulo) + step * ceil
//       if (value >= 60) {
//         timeAsArrayOfNumbers[0] = timeAsArrayOfNumbers[0] + 1
//         timeAsArrayOfNumbers[1] = 0
//       } else {
//         timeAsArrayOfNumbers[1] = value
//       }
//     } else {
//       timeAsArrayOfNumbers.push(0)
//     }
//     // seconds
//     if (timeAsArrayOfNumbers.length > 2) {
//       timeAsArrayOfNumbers[2] = 0
//     } else {
//       timeAsArrayOfNumbers.push(0)
//     }
//   }
//   const timeAsArrayOfStrings = timeAsArrayOfNumbers.map(elt => elt > 9 ? +elt : '0' + elt)
//   return timeAsArrayOfStrings.join(':')
// }

// returns the number of seconds between two times
export function diffTimes(timeTo: string|null, timeFrom: string|null): number {
  if (!timeTo || !timeFrom) {
    return 0
  }
  // assume that time is a valid time
  // returns -1 if time1 is after time2, 0 if equal and 1 if time2 is after time1
  let arrayFrom = timeFrom.split(':').map(elt => +elt)
  let arrayTo = timeTo.split(':').map(elt => +elt)
  let diff = 0
  let multiplier = 3600
  for (let index = 0; index < 3; index++) {
    const valueFrom = (index < arrayFrom.length) ? arrayFrom[index] : 0
    const valueTo = (index < arrayTo.length) ? arrayTo[index] : 0
    diff += (valueTo - valueFrom) * multiplier
    multiplier = multiplier / 60
  }
  return diff
}

function changeTime(time: string, minutes: number, func: Function): string {
  let arrayFrom = time.split(':').map(elt => +elt)
  const rawMinutes = arrayFrom[1]
  let hours = arrayFrom[0]
  let floorMinutes = func(rawMinutes / minutes) * minutes
  if (floorMinutes === 60) {
    floorMinutes = 0
    hours += 1
  }
  const timeArray = [hours, floorMinutes, 0].map(elt => elt > 9 ? +elt : '0' + elt)
  return timeArray.join(':')
}

export function floorTime(time: string, minutes: number): string {
  return changeTime(time, minutes, Math.floor)
}

export function ceilTime(time: string, minutes: number): string {
  return changeTime(time, minutes, Math.ceil)
}
