import { isNumber } from '@/utils/check'

export async function filterNumbers(value: string, evt: any, precision: number = 0): Promise<string> {
  let thisValue = value
  const charCode = evt.which ? evt.which : evt.keyCode
  const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  const allowedChar = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '.', '-']
  const allowedKey = [8, 9, 37, 39, 17] // Backslash, Tab, left arrow, right arrow
  const allowedCtrl = [65, 67, 88] // Ctrl A, C, X
  const copyKey = [86] // Ctrl+V
  const ignoreKey = [16, 17] // Shift, Ctrl
  const ignore = (
    (evt.ctrlKey && allowedCtrl.indexOf(charCode) >= 0) ||
    (ignoreKey.indexOf(charCode) >= 0)
  )
  if (ignore) {
    // allow Ctrl a
    return thisValue
  }
  try {
    let start = evt.target.selectionStart || 0
    let end = evt.target.selectionEnd || 0
    if (end > start) {
      const selected = thisValue.substring(start, end)
      thisValue = thisValue.replace(selected, '')
    }
  } catch (error) {
    console.log('replace error', error) // eslint-disable-line no-console
    // silent
  }
  if (evt.ctrlKey && copyKey.indexOf(charCode) >= 0) {
    evt.preventDefault()
    evt.stopPropagation()
    try {
      const paste = await navigator.clipboard.readText()
      if (isNumber(+paste)) {
        thisValue += paste
        const rounding = Math.pow(10, precision)
        thisValue = '' + Math.round(rounding * (+thisValue)) / rounding
      } else {
        return thisValue
      }
    } catch (error) {
      console.log('paste error', error) // eslint-disable-line no-console
      return thisValue
    }
  }
  if ((allowedKey.indexOf(charCode) < 0) && (allowedChar.indexOf(evt.key) < 0)) {
    // N'autorise que les caractères ci-dessus
    evt.preventDefault()
    evt.stopPropagation()
  }
  if ((evt.key === '-') && thisValue) {
    // Le - peut-être seulement en première position
    evt.preventDefault()
    evt.stopPropagation()
  }
  if ([',', '.'].indexOf(evt.key) >= 0) {
    // 1 seule occurrence du point ou de la virgule et pas en première position
    if (
      (!thisValue) ||
      (thisValue.indexOf(',') >= 0) ||
      (thisValue.indexOf('.') >= 0)
    ) {
      evt.preventDefault()
      evt.stopPropagation()
    }
  }
  if (precision && digits.indexOf(evt.key) >= 0) {
    const comaIndex = thisValue.indexOf(',')
    const dotIndex = thisValue.indexOf('.')
    const sepIndex = Math.max(comaIndex, dotIndex)
    if (sepIndex > 0) {
      const postComa = thisValue.substr(sepIndex + 1)
      // il y a une virgule, vérifie que le nombre de chiffre arès la vigule n'est pas atteint
      if (postComa.length >= precision) {
        evt.preventDefault()
        evt.stopPropagation()
      }
    }
  }
  if (evt.key === ',') {
    // remplace la virgule par le point pour avoir un nombre valide
    thisValue += '.'
    evt.preventDefault()
    evt.stopPropagation()
  }
  return thisValue
}
