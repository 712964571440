import { SeancePeriod } from '@/types/youth'
import moment from 'moment'

export function compareNumbers(a: number, b: number): number {
  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  } else {
    return 0
  }
}

export function compareDates(a: Date, b: Date): number {
  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  } else {
    return 0
  }
}

export function compareDays(a: any, b: any): number {
  const ma = moment(moment(a).format('YYYY-MM-DD'))
  const mb = moment(moment(b).format('YYYY-MM-DD'))
  const diff = ma.diff(mb, 'days')
  if (diff < 0) {
    return -1
  } else if (diff > 0) {
    return 1
  } else {
    return 0
  }
}

export function compareStrings(a: string, b: string): number {
  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  } else {
    return 0
  }
}

export function comparePeriods(a: SeancePeriod, b: SeancePeriod): number {
  if (a.timeframes.length && b.timeframes.length) {
    return compareDates(a.timeframes[0].dateFrom, b.timeframes[0].dateFrom)
  } else {
    return 0
  }
}
