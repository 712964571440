import store from '@/store'
import {
  EntityRole, ROLE_CHILDREN, ROLE_ADULTS, ROLE_BABIES, ROLE_PARENTS, ROLE_TEENAGERS, ROLE_CONTACT_MEMBERS,
  ROLE_OTHER_FAMILY_MEMBERS
} from '@/types/people'
import {
  APPLY_TO_ADULTS, APPLY_TO_BABIES, APPLY_TO_CHILDREN, APPLY_TO_CHILDREN_NOT_BABIES,
  APPLY_TO_CHILDREN_NOT_BABIES_NOT_TEENAGERS, APPLY_TO_CHILDREN_NOT_TEENAGERS, APPLY_TO_ENTITY_MEMBERS,
  APPLY_TO_INDIVIDUALS, APPLY_TO_TEENAGERS, APPLY_TO_PARENTS, APPLY_TO_OTHER_FAMILY_MEMBERS, APPLY_TO_ENTITIES,
  APPLY_TO_FAMILIES, APPLY_TO_ORGANIZATIONS
} from '@/types/fields'
import { existsIn } from '@/utils/arrays'

export function getRoleGroups(roleFieldFilter: string): string[] {
  switch (roleFieldFilter) {
    case ROLE_CHILDREN:
      return [
        APPLY_TO_CHILDREN, APPLY_TO_CHILDREN_NOT_BABIES, APPLY_TO_CHILDREN_NOT_TEENAGERS,
        APPLY_TO_CHILDREN_NOT_BABIES_NOT_TEENAGERS
      ]
    case ROLE_BABIES:
      return [
        APPLY_TO_CHILDREN, APPLY_TO_BABIES, APPLY_TO_CHILDREN_NOT_TEENAGERS
      ]
    case ROLE_TEENAGERS:
      return [
        APPLY_TO_CHILDREN, APPLY_TO_TEENAGERS, APPLY_TO_CHILDREN_NOT_BABIES
      ]
    case ROLE_ADULTS:
      return [APPLY_TO_ADULTS, APPLY_TO_INDIVIDUALS]
    case ROLE_PARENTS:
      return [APPLY_TO_ADULTS, APPLY_TO_INDIVIDUALS, APPLY_TO_PARENTS]
    case ROLE_OTHER_FAMILY_MEMBERS:
      return [APPLY_TO_ENTITY_MEMBERS]
    case ROLE_CONTACT_MEMBERS:
      return [APPLY_TO_ADULTS, APPLY_TO_INDIVIDUALS, APPLY_TO_OTHER_FAMILY_MEMBERS]
  }
  return []
}

export function doesGroupApplyToRole(applyTo: string[], role: EntityRole): boolean {
  if (role.fieldsFilter) {
    // Si un filtre est associé au rôle alors ceci est prioritaire
    const roleFilters = getRoleGroups(role.fieldsFilter)
    return existsIn(roleFilters, applyTo)
  }
  return false
}

export function canViewGroup(applyTo: string[]): boolean {
  const permNames = ['fields.view_field']
  for (const applyToElt of applyTo) {
    // individus
    const individualGroups = [
      APPLY_TO_INDIVIDUALS, APPLY_TO_ADULTS, APPLY_TO_CHILDREN, APPLY_TO_BABIES,
      APPLY_TO_CHILDREN_NOT_BABIES, APPLY_TO_CHILDREN_NOT_TEENAGERS, APPLY_TO_TEENAGERS,
      APPLY_TO_CHILDREN_NOT_BABIES_NOT_TEENAGERS, APPLY_TO_PARENTS, APPLY_TO_OTHER_FAMILY_MEMBERS
    ]
    if (existsIn([applyToElt], individualGroups)) {
      permNames.push('people.view_individual')
    }
    // membres d'une famille
    const familyGroups = [
      APPLY_TO_INDIVIDUALS, APPLY_TO_ADULTS, APPLY_TO_CHILDREN, APPLY_TO_BABIES,
      APPLY_TO_CHILDREN_NOT_BABIES, APPLY_TO_CHILDREN_NOT_TEENAGERS, APPLY_TO_TEENAGERS,
      APPLY_TO_CHILDREN_NOT_BABIES_NOT_TEENAGERS, APPLY_TO_PARENTS, APPLY_TO_OTHER_FAMILY_MEMBERS,
      APPLY_TO_FAMILIES, APPLY_TO_ENTITIES
    ]
    if (existsIn([applyToElt], familyGroups)) {
      permNames.push('families.view_familyentity')
    }
    // enfants
    const childrenGroups = [
      APPLY_TO_CHILDREN, APPLY_TO_BABIES,
      APPLY_TO_CHILDREN_NOT_BABIES, APPLY_TO_CHILDREN_NOT_TEENAGERS, APPLY_TO_TEENAGERS,
      APPLY_TO_CHILDREN_NOT_BABIES_NOT_TEENAGERS
    ]
    if (existsIn([applyToElt], childrenGroups)) {
      permNames.push('families.view_child')
    }
    // contacts
    const entityGroups = [APPLY_TO_ENTITY_MEMBERS, APPLY_TO_ORGANIZATIONS]
    if (existsIn([applyToElt], entityGroups)) {
      permNames.push('people.view_entity')
    }
    // crèche
    const nurseryGroups = [APPLY_TO_BABIES]
    if (existsIn([applyToElt], nurseryGroups)) {
      permNames.push('nursery.view_nurseryfamily')
    }
  }
  return store.getters.hasAllPerms(permNames)
}

export function canChangeGroup(applyTo: string[]): boolean {
  const permNames = ['fields.view_field']
  for (const applyToElt of applyTo) {
    // individus
    const individualGroups = [
      APPLY_TO_INDIVIDUALS, APPLY_TO_ADULTS, APPLY_TO_CHILDREN, APPLY_TO_BABIES,
      APPLY_TO_CHILDREN_NOT_BABIES, APPLY_TO_CHILDREN_NOT_TEENAGERS, APPLY_TO_TEENAGERS,
      APPLY_TO_CHILDREN_NOT_BABIES_NOT_TEENAGERS, APPLY_TO_PARENTS, APPLY_TO_OTHER_FAMILY_MEMBERS
    ]
    if (existsIn([applyToElt], individualGroups)) {
      permNames.push('people.change_individual')
    }
    // membres d'une famille
    const familyGroups = [
      APPLY_TO_INDIVIDUALS, APPLY_TO_ADULTS, APPLY_TO_CHILDREN, APPLY_TO_BABIES,
      APPLY_TO_CHILDREN_NOT_BABIES, APPLY_TO_CHILDREN_NOT_TEENAGERS, APPLY_TO_TEENAGERS,
      APPLY_TO_CHILDREN_NOT_BABIES_NOT_TEENAGERS, APPLY_TO_PARENTS, APPLY_TO_OTHER_FAMILY_MEMBERS,
      APPLY_TO_FAMILIES
    ]
    if (existsIn([applyToElt], familyGroups)) {
      permNames.push('families.change_familyentity')
    }
    // enfants
    const childrenGroups = [
      APPLY_TO_CHILDREN, APPLY_TO_BABIES,
      APPLY_TO_CHILDREN_NOT_BABIES, APPLY_TO_CHILDREN_NOT_TEENAGERS, APPLY_TO_TEENAGERS,
      APPLY_TO_CHILDREN_NOT_BABIES_NOT_TEENAGERS
    ]
    if (existsIn([applyToElt], childrenGroups)) {
      permNames.push('families.change_child')
    }
    // contacts
    const entityGroups = [APPLY_TO_ENTITY_MEMBERS, APPLY_TO_ENTITIES]
    if (existsIn([applyToElt], entityGroups)) {
      permNames.push('people.change_entity')
    }
    // crèche
    const nurseryGroups = [APPLY_TO_BABIES]
    if (existsIn([applyToElt], nurseryGroups)) {
      permNames.push('nursery.change_nurseryfamily')
    }
  }
  return store.getters.hasAllPerms(permNames)
}
