<template>
  <div>
    <div class="radio-select" :class="cssClasses">
      <b-radio
        :name="'radio' + id"
        v-for="choice of choices"
        :key="choice.id"
        :value="choice"
        v-model="selectedChoice"
        :class="cssClasses"
      >
        {{ getName(choice) }}
      </b-radio>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
export default {
  name: 'RadioSelect',
  model: {
    prop: 'initialValue',
    event: 'changed',
  },
  props: {
    choices: Array,
    inline: {
      type: Boolean,
      defaultValue: false,
    },
    lineBottom: {
      type: Boolean,
      defaultValue: false,
    },
    hideAll: {
      type: Boolean,
      defaultValue: false,
    },
    nameCallback: {
      type: Function,
      defaultValue: null,
    },
    initialValue: {
      type: Object,
      defaultValue: null,
    },
    id: {
      type: String,
      defaultValue: '',
    },
  },
  data() {
    return {
      selectedChoice: null,
    }
  },
  watch: {
    choices: function() {},
    initialValue: function() { this.initChoices() },
    selectedChoice: function() { this.emitChange() },
  },
  computed: {
    cssClasses() {
      return { inline: this.inline, lineBottom: this.lineBottom, }
    },
  },
  methods: {
    emitChange() {
      this.$emit('changed', this.selectedChoice)
    },
    initChoices() {
      if (typeof this.initialValue !== 'undefined' && this.initialValue) {
        for (const initialItem of this.choices) {
          if (initialItem.id === this.initialValue.id) {
            this.selectedChoice = initialItem
            break
          }
        }
      }
    },
    getName(elt) {
      if (this.nameCallback) {
        return this.nameCallback(elt)
      } else {
        return elt.name
      }
    },
  },
  mounted() {
    this.initChoices()
  },
}
</script>
<style scoped lang="less">
.inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 2px;
}
.lineBottom {
  border-bottom: solid 1px #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.lineBottom:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>
