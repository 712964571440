<template>
  <span>
    <b-form-input
      type="text"
      v-model="textValue"
      :id="id"
      :name="id"
      class="decimal-input"
      :class="small ? 'small-input' : ''"
      :disabled="disabled"
      :required="required"
      :placeholder="placeholder"
      @wheel="$event.target.blur()"
      @keydown="filterNumbers($event)"
      @focusout="onFocusOut($event)"
    >
    </b-form-input>
    <div v-if="hasMinError" class="decimal-input-error">
      La valeur doit être supérieure à {{ min }}
      <a href @click.prevent="textValue = '' + min">corriger</a>
    </div>
    <div v-if="hasMaxError" class="decimal-input-error">
      La valeur doit être inférieure à {{ max }}
      <a href @click.prevent="textValue = '' + max">corriger</a>
    </div>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { filterNumbers } from '@/utils/numbers'
import { isNumber } from '@/utils/check'

export default {
  name: 'DecimalInput',
  prop: ['value'],
  props: {
    value: [Number, String],
    id: String,
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    allowNull: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: [String, Number],
      default: Number.MAX_SAFE_INTEGER,
    },
    small: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: [String, Number],
      default: 2,
    },
  },
  data() {
    return {
      textValue: '',
    }
  },
  computed: {
    precisionValue() {
      if (this.precision) {
        return +this.precision
      }
      return 0
    },
    hasMinError() {
      return this.min && this.value < this.min
    },
    hasMaxError() {
      return this.max && this.value > this.max
    },
  },
  watch: {
    value: function(value) {
      if (isNumber(value)) {
        // Si 10.55 -> 10.549999 -> On arrondi à la précision
        const precision = Math.pow(10, this.precisionValue)
        const roundedValue = Math.round(value * precision) / precision
        this.textValue = '' + roundedValue
      } else {
        this.textValue = '' + value
      }
    },
    textValue: function(newValue, oldValue) {
      // suppression de la virgule en fin de chaine qui ne serait pas un nombre valide
      const safeValue = ('' + newValue).replace(/,*$/, '')
      let value = +safeValue
      if (isNaN(value)) {
        if (this.allowNull) {
          value = ''
        } else {
          value = 0
          this.textValue = '' + value
        }
      }
      if (!value && this.allowNull) {
        value = ''
      }
      this.$emit('input', value)
      this.$emit('change', value)
    },
    placeholder: function() {},
    disabled: function() {},
  },
  methods: {
    filterNumbers: async function(evt) {
      this.textValue = await filterNumbers(this.textValue, evt, this.precisionValue)
    },
    onFocusOut: function(evt) {
      evt.value = this.textValue
      this.$emit('focusout', evt)
    },
  },
  mounted() {
    this.textValue = '' + this.value
  },
}
</script>
<style lang="less">
input.decimal-input::-webkit-outer-spin-button,
input.decimal-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input.decimal-input[type=number] {
    -moz-appearance: textfield;
}
.decimal-input-error {
  padding: 2px 0;
  font-size: 12px;
  color: #c83d05;
}
</style>
