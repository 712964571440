<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          id="start-date-group"
          :label="noLabel ? '' : (label || 'Du')"
          label-for="start-date"
          :description="'Du ' + startDateShort"
        >
          <b-form-input
            type="date" id="start-date" v-model="startDateValue" :disabled="disabled"
            :class="{'small-input': small}"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="end-date-group"
          :label="noLabel ? '' : 'Au'"
          label-for="end-date"
          :description="'au ' + endDateShort"
        >
          <b-form-input
            type="date" id="end-date" v-model="endDateValue" :disabled="disabled"
            :class="{'small-input': small}"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { dateToString } from '@/filters/texts'

export default {
  name: 'DateFrameSelector',
  props: {
    startDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
  },
  data() {
    return {
      startDateValue: null,
      endDateValue: null,
    }
  },
  watch: {
    startDate: function() { this.startDateValue = this.startDate },
    endDate: function() { this.endDateValue = this.endDate },
    startDateValue: function() { this.onDateRangeChanged() },
    endDateValue: function() { this.onDateRangeChanged() },
    disabled: function() {},
  },
  computed: {
    startDateShort() {
      return dateToString(this.startDate, 'dddd LL')
    },
    endDateShort() {
      return dateToString(this.endDate, 'dddd LL')
    },
  },
  created() {
    this.startDateValue = this.startDate
    this.endDateValue = this.endDate
  },
  methods: {
    onDateRangeChanged() {
      this.$emit('change', { startDate: this.startDateValue, endDate: this.endDateValue, })
    },
  },
}
</script>
<style scoped lang="less">
</style>
