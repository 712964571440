export function sum(numbers: number[]): number {
  return numbers.reduce((sum, current) => sum + current, 0)
}

export function min(numbers: number[]): number {
  return Math.min(...numbers)
}

export function max(numbers: number[]): number {
  return Math.max(...numbers)
}

export function avg(numbers: number[]): number {
  if (numbers.length) {
    const value = sum(numbers) / numbers.length
    return Math.round(value * 100) / 100
  }
  return 0
}

export function median(numbers: number[]): number {
  if (numbers.length) {
    const mid: number = Math.floor(numbers.length / 2)
    const sorted: number[] = [...numbers].sort((a, b) => a - b)
    return sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2
  }
  return 0
}

export function percentage(value: number, total: number, precision: number = 1): string {
  if (total !== 0) {
    const multiplier = Math.pow(10, precision)
    const percent = Math.round(100 * multiplier * value / total) / multiplier
    return '' + percent + '%'
  }
  return '-'
}

export function roundForStr(value: number, places: number = 2): string {
  const den = 10 ** places
  const rounded = Math.round(den * value) / den
  let text = '' + rounded
  const sep = text.indexOf('.')
  if (sep > 0) {
    let intPart = text.slice(0, sep + 1)
    const decimalPart = text.slice(sep + 1)
    let decimalOut = decimalPart
    for (let index = decimalPart.length; index >= 0; index--) {
      if (decimalPart.slice(index - 1, index) === '0') {
        decimalOut = decimalPart.slice(0, index)
      } else {
        break
      }
    }
    return intPart + decimalOut
  }
  return text
}
