import moment from 'moment'
import { getAge } from '@/utils/dates'

export function dateToString(date: any, format: string = 'L'): string {
  if (!date) {
    return '-'
  } else {
    return moment(date).format(format)
  }
}

export function defaultValue(value: any, defaultValue = '-'): any {
  if (!value) {
    return defaultValue
  } else {
    return value
  }
}

export function pluralize(value: number, singular: string, plural: string = ''): string {
  if (value > 1) {
    return plural || (singular + 's')
  } else {
    return singular
  }
}

export function age(value: any): string {
  return getAge(value)
}

export function yesno(value: any): string {
  if (value) {
    return 'Oui'
  }
  return 'Non'
}

export function currency(value: any): any {
  if (isNaN(value) || (value === null)) {
    return '---'
  }
  let roundedValue = Math.round(100 * value) / 100
  const result = '' + roundedValue + '€'
  return result.replace('.', ',')
}

export function percentage(value: any): string {
  if (isNaN(value) || (value === null)) {
    return '---'
  }
  const precision = 100000
  const percentValue = Math.round(100 * precision * +value) / precision
  return '' + percentValue + '%'
}

export function hour(value: string): string {
  if (value) {
    let slices = value.split(':')
    if (slices.length > 2) {
      slices.pop()
    }
    return slices.join(':')
  }
  return ''
}

export function duration(value: number|string): string {
  if (value) {
    let numValue = +value
    if (numValue) {
      const hours = Math.floor(numValue)
      const minutes = Math.round((numValue - hours) * 60)
      let text = hours + 'h'
      if (minutes) {
        if (minutes < 10) {
          text += '0' + minutes
        } else {
          text += minutes
        }
      }
      return text
    }
  }
  return '' + value
}

export function round(value: any, digits: number = 2): string {
  // 11.00 --> 11
  // 11.50 --> 11.5
  // 11.999 --> 12
  if (isNaN(value) || (value === null)) {
    return '-'
  }
  for (let index = digits; index > 0; index--) {
    let multiplier = Math.pow(10, digits)
    let roundedValue = Math.round(multiplier * value) / multiplier
    let asText = '' + roundedValue
    if ((asText.indexOf('.') < 0) || (asText[asText.length - 1] !== '0')) {
      return asText
    }
  }
  return '' + value
}

export function fileSize(value: number): string {
  const multipliers = ['o', 'Ko', 'Mo', 'Go']

  let scaledValue = value
  let multiplierIndex = 0
  while (scaledValue >= 1024 && multiplierIndex < multipliers.length) {
    scaledValue = scaledValue / 1024
    multiplierIndex += 1
  }
  const roundedValue = Math.round(scaledValue * 100) / 100
  const multiplier = multipliers[multiplierIndex]
  return roundedValue + multiplier
}

export function tsToDate(value: number): string {
  return moment(new Date(value)).format('DD/MM/YYYY HH:mm:ss')
}
