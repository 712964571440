<template>
  <div class="entity-sidebar" v-if="isAuthenticated">
    <b-sidebar
      id="sidebar-entity"
      right shadow width="35%" no-header
      @shown="onSideBarOpened()"
      @hidden="onSideBarClosed()"
      ref="entitySidebar"
    >
      <template #default="{ hide }">
        <div class="px-3 py-2" v-if="fullEntity">
          <ul class="app-menu">
            <li>
              <b-row>
                <b-col>
                  <span class="family-numbers">{{ fullEntity.id }}</span>
                  <b> {{ fullEntity.name }}</b>
                </b-col>
                <b-col cols="1" class="text-right">
                  <i class="fa fa-close a-like clickable" @click="hide"></i>
                </b-col>
              </b-row>
            </li>
            <li>
              <b-row>
                <b-col>
                  <div v-if="fullEntity.phone">
                      <phone-link :phone="fullEntity.phone"></phone-link>
                  </div>
                  <div v-for="line of fullEntity.addressLines()" :key="line">
                    {{ line }}
                  </div>
                  <div>
                    {{ fullEntity.zipCode }}
                    <span v-if="fullEntity.city">{{ fullEntity.city.name }}</span>
                    {{ fullEntity.cedex }}
                  </div>
                </b-col>
                <b-col class="text-right" cols="3">
                  <a
                    :href="getLinkToEntity(fullEntity)"
                    class="btn btn-primary btn-xs"
                    @click.prevent="viewEntity(fullEntity)"
                  >
                    Voir la fiche
                  </a>
                </b-col>
              </b-row>
            </li>
          </ul>
          <ul class="app-menu">
            <li>
              <div v-for="membership of memberships" :key="membership.id" class="sidebar-member">
                <b-row>
                  <b-col cols="4">
                      <div
                        class="sidebar-role-name"
                        :class="getRoleClass(membership)"
                      >
                        {{ membership.role.name }}
                      </div>
                  </b-col>
                  <b-col>
                    {{ membership.individual.firstAndLastName() }}
                    <div v-if="membership.individual.email" class="individual-email">
                      <email-link :email="membership.individual.email"></email-link>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </li>
            <li>
              <fields-detail
                :show-entity="true"
                :entity="fullEntity"
                :is-family="isFamily"
                read-only
              ></fields-detail>
            </li>
            <li>
              <entity-invoices
                :show-all="true"
                :is-family="isFamily"
                :entity="entity"
              ></entity-invoices>
            </li>
          </ul>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import FieldsDetail from '@/components/Fields/FieldsDetail'
import { makeEntity } from '@/types/people'
import router from '@/router'
import { makeFieldsGroup } from '@/types/fields'
import { mapMutations } from 'vuex'
import PhoneLink from '@/components/Controls/Links/PhoneLink.vue'
import EntityInvoices from '@/components/Entity/EntityInvoices.vue'
import EmailLink from '@/components/Controls/Links/EmailLink.vue'

export default {
  name: 'entity-sidebar',
  components: {
    EmailLink,
    EntityInvoices,
    PhoneLink,
    FieldsDetail,
  },
  mixins: [BackendMixin],
  props: {
  },
  data() {
    return {
      fullEntity: null,
      init: false,
      showActivities: false,
      isFamily: false,
    }
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    entity() {
      return store.getters.sidebarEntity
    },
    memberships() {
      if (this.fullEntity) {
        const memberships = this.fullEntity.memberships
        const children = memberships.filter(elt => elt.role.isMain && elt.role.isChild)
        const parents = memberships.filter(elt => elt.role.isMain && !elt.role.isChild)
        const others = memberships.filter(elt => !elt.role.isMain)
        return children.concat(parents).concat(others)
      }
      return []
    },
  },
  watch: {
    entity: function() {
      this.loadEntity()
    },
  },
  methods: {
    ...mapMutations(['setFieldGroups']),
    onSideBarOpened() {
      this.loadFieldsGroups()
    },
    onSideBarClosed() {
      store.commit('setSidebarEntity', null)
    },
    getRoleClass(membership) {
      if (membership.role.isMain) {
        if (membership.role.isChild) {
          return 'sidebar-role-child'
        } else {
          return 'sidebar-role-parent'
        }
      }
      return ''
    },
    async loadFieldsGroups() {
      if (!this.init) {
        if (this.hasPerm('fields.view_fieldsgroup')) {
          const backendApi = new BackendApi('get', '/api/fields/groups/')
          const resp = await backendApi.callApi()
          this.setFieldGroups(resp.data.map(elt => makeFieldsGroup(elt)))
        }
        this.init = true
      }
    },
    async loadEntity() {
      this.fullEntity = null
      if (this.entity && this.entity.id) {
        const url = '/api/families/families/' + this.entity.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.fullEntity = makeEntity(resp.data)
          this.isFamily = true
        } catch (err) {
          const url2 = '/api/people/contacts/' + this.entity.id + '/'
          const backendApi2 = new BackendApi('get', url2)
          try {
            const resp2 = await backendApi2.callApi()
            this.fullEntity = makeEntity(resp2.data)
            this.isFamily = false
          } catch (err2) {
            await this.addError(this.getErrorText(err2))
          }
        }
      }
    },
    getEntityLink(entity) {
      const params = { entityId: '' + entity.id, }
      if (this.isFamily) {
        return { name: 'families-detail', params: params, }
      } else {
        return { name: 'entities-detail', params: params, }
      }
    },
    getLinkToEntity(entity) {
      let to = this.getEntityLink(entity)
      return router.resolve(to).href
    },
    viewEntity(entity) {
      router.push(this.getEntityLink(entity))
      store.commit('setSidebarEntity', null)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  font-size: 13px !important;
}
.entity-sidebar .app-menu li {
  font-size: 14px;
}
.sidebar-role-name {
  font-size: 12px;
  padding: 2px;
  border: solid 1px #ccc;
  border-radius: 2px;
  display: block;
  background: #f0f0f0;
  text-align: center;
}
.sidebar-role-child {
  background: #f2f2a2;
}
.sidebar-role-parent {
  background: #b8e7ff;
}
.sidebar-member {
  border-bottom: solid 1px #ccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.individual-email {
  margin: 2px 0;
  font-size: 11px;
}
</style>
