<template>
  <span class="delete-payment" v-if="payment && hasPerm('payments.change_payment')">
    <b-modal dialog-class="modal-md"
      :id="getId()"
      cancel-title="Annuler"
      :ok-disabled="isLoading(returnPaymentLoadingName)"
      @ok.prevent="onOk"
      @hidden="onHidden"
      ok-variant="danger"
      ok-title="Rendre"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fa fa-hand-point-left"></i>&nbsp;
          <span v-if="deposit">Refus de la banque</span>
          <span v-else>Rendre un paiement</span>
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <loading-gif
          :loading-name="returnPaymentLoadingName"
          message="Opération en cours. Veuillez patientez"
        ></loading-gif>
        <b-col cols="12">
          <div class="warning2-text">
            <span v-if="deposit">Voulez-vous marquer comme refusé par la banque</span>
            <span v-else>Voulez-vous rendre</span>
            le paiement {{ paymentLabel }} de {{ payment.amount | currency }} du
            {{ paymentDate | dateToString }} <span v-if="entity.name">de {{ entity.name }}</span>
            <br /><br />
            Un paiement négatif à la date du jour sera enregistré pour compenser ce paiement.<br />
            La comptabilité analytique prendra en compte ce paiement négatif
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { makePayment } from '@/types/payments'

export default {
  name: 'delete-payment',
  components: { LoadingGif, },
  mixins: [BackendMixin],
  props: {
    payment: Object,
  },
  data() {
    return {
      randomId: getRandomId(),
      errorText: '',
      returnPaymentLoadingName: 'returnPaymentLoading',
    }
  },
  computed: {
    entity() {
      return this.payment.entity
    },
    paymentLabel() {
      if (this.payment) {
        const text = '' + this.payment.paymentMode.name + ' ' + this.payment.bankName + ' ' + this.payment.bankNumber
        return text.trim()
      }
      return ''
    },
    deposit() {
      return this.payment.deposit && this.payment.deposit.id
    },
    paymentDate() {
      if (this.payment) {
        const paymentDate = this.payment.paymentDate || this.payment.createdOn
        if (paymentDate) {
          return moment(paymentDate).format('YYYY-MM-DD')
        }
      }
      return ''
    },
  },
  watch: {
    payment: function() { },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-delete-payment' + this.randomId
    },
    onHidden() {
      this.$emit('hidden')
    },
    async onOk() {
      this.errorText = ''
      try {
        this.startLoading(this.returnPaymentLoadingName)
        const url = '/api/return-payment/' + this.payment.id + '/'
        const backendApi = new BackendApi('post', url)
        const resp = await backendApi.callApi({})
        this.endLoading(this.returnPaymentLoadingName)
        this.$emit('done', makePayment(resp.data))
        this.$bvModal.hide(this.getId())
        await this.addSuccess('Le paiement a été rendu')
      } catch (err) {
        this.errorText = this.getErrorText(err)
        await this.addError(this.getErrorText(err))
      }
    },
  },
  mounted() {
    this.showModal()
  },
}
</script>
<style lang="less">
.warning2-text {
  padding: 10px;
  margin-bottom: 5px;
  background: #f3e6c7;
  color: #222;
}
</style>
