import { UserPreferences } from '@/types/users'
import { loadPreferences, storePreferences } from '@/utils/preferences'

export const preferencesModule = {
  state: {
    userPreferences: loadPreferences(),
  },
  getters: {
    userPreferences: (state: any) => {
      return state.userPreferences
    },
  },
  mutations: {
    setUserPreferences(state: any, userPreferences: UserPreferences) {
      state.userPreferences = userPreferences
    },
  },
  actions: {
    setUserPreferences(context: any, userPreferences: UserPreferences) {
      storePreferences(userPreferences)
      context.commit('setUserPreferences', userPreferences)
    },
  },
  modules: {
  },
}

export default preferencesModule
