import store from '@/store'
import { BackendApi, getErrorText } from '@/utils/http'
import { FrontendInstance, makeFrontendInstance } from '@/types/auth'

export async function loadFrontendInstance() {
  const appUrl = window.location.origin
  let backendApi = new BackendApi('post', '/api/backends/frontend/')
  try {
    const resp = await backendApi.callRouterApi({ app_url: appUrl, })
    const frontendInstance: FrontendInstance = makeFrontendInstance(resp.data)
    await store.dispatch('setFrontendInstance', frontendInstance)
  } catch (err) {
    await store.dispatch('addWarning', getErrorText(err))
  }
}
