export const navModule = {
  state: {
    navTitle: '',
    navIcon: '',
  },
  getters: {
    navTitle: (state: any) => {
      return state.navTitle
    },
    navIcon: (state: any) => {
      return state.navIcon
    },
  },
  mutations: {
    changeNav(state: any, nav: any) {
      state.navTitle = nav.title
      state.navIcon = nav.icon
    },
  },
  actions: {
    changeNav(context: any, nav: any) {
      context.commit('changeNav', nav)
    },
  },
  modules: {
  },
}

export default navModule
