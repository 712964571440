import { loadUser, storeUser, setTabCounter } from '@/utils/auth'
import { User } from '@/types/auth'

export const authModule = {
  state: {
    currentUser: loadUser(),
  },
  getters: {
    isAuthenticated: (state: any) => {
      return !!state.currentUser.token
    },
    userName: (state: any) => {
      const fullName = [state.currentUser.firstName, state.currentUser.lastName].filter(elt => !!elt).join(' ')
      return fullName || state.currentUser.email
    },
    isStaff: (state: any) => {
      return !!state.currentUser.isStaff
    },
    userEmail: (state: any) => {
      return state.currentUser.email
    },
    authToken: (state: any) => {
      return state.currentUser.token
    },
    backendUrl: (state: any) => {
      return state.currentUser.backend ? state.currentUser.backend.url : ''
    },
    backendName: (state: any) => {
      return state.currentUser.backend ? state.currentUser.backend.name : ''
    },
  },
  mutations: {
    setUser(state: any, user: User) {
      state.currentUser = user
      storeUser(user)
    },
  },
  actions: {
    setUser(context: any, user: User) {
      setTabCounter(1)
      context.commit('setUser', user)
    },
    resetUser(context: any) {
      setTabCounter(0)
      context.commit('setUser', new User())
    },
  },
  modules: {
  },
}

export default authModule
