import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
import store from './store'
import {
  dateToString, defaultValue, age, yesno, currency, hour, round, fileSize, tsToDate, percentage
} from '@/filters/texts'
import * as moment from 'moment'
import VueBootstrapTypeahead from '@/components/Controls/Typeahead/VueBootstrapTypeahead.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

moment.locale('fr')
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.filter('dateToString', dateToString)
Vue.filter('defaultValue', defaultValue)
Vue.filter('age', age)
Vue.filter('yesno', yesno)
Vue.filter('currency', currency)
Vue.filter('percentage', percentage)
Vue.filter('hour', hour)
Vue.filter('round', round)
Vue.filter('fileSize', fileSize)
Vue.filter('tsToDate', tsToDate)
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.component('vue-phone-number-input', VuePhoneNumberInput)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
