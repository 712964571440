export const notesModule = {
  state: {
    notesCount: 0,
    notesUpdated: false,
  },
  getters: {
    notesCount: (state: any) => {
      return state.notesCount
    },
    notesUpdated: (state: any) => {
      return state.notesUpdated
    },
  },
  mutations: {
    setNotes(state: any, notesCount: number) {
      state.notesCount = notesCount
    },
    resetNotes(state: any) {
      state.notesCount = 0
    },
    setNotesUpdated(state: any, updated: boolean) {
      state.notesUpdated = updated
    },
  },
  modules: {
  },
}

export default notesModule
