<template>
  <span v-if="hasPerm('payments.view_invoice')">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <a
      :class="{ disabled: isLoading(loadingName)}"
      class="btn btn-secondary btn-sm"
      href download
      @click.prevent="downloadInvoice"
      v-if="!noBtn"
    >
      <i class="fa fa-file-pdf"></i> Télécharger
    </a>
    <a
      v-else
      href download
      @click.prevent="downloadInvoice"
      class="dark-color"
      :class="{ disabled: isLoading(loadingName)}"
    >
      <i class="fa fa-file-pdf"></i>
    </a>
    <div v-if="!noHtml">
      <a class="small2" href @click.prevent="downloadInvoiceHtml">Version HTML</a>
    </div>
  </span>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { openDocument } from '@/utils/http'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'invoice-download',
  components: {
    LoadingGif,
  },
  mixins: [
    BackendMixin
  ],
  props: {
    invoice: Object,
    noHtml: {
      type: Boolean,
      default: false,
    },
    noBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingName: 'invoiceDownload',
    }
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async downloadInvoice() {
      if (!this.isLoading(this.loadingName)) {
        this.startLoading(this.loadingName)
        const docUrl = '/facture/<key>/pdf/'
        const docSlug = '' + '' + this.invoice.id
        try {
          await openDocument(docUrl, docSlug)
          this.$emit('opened', { invoice: this.invoice, })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    async downloadInvoiceHtml() {
      if (!this.isLoading(this.loadingName)) {
        this.startLoading(this.loadingName)
        const docUrl = '/facture/<key>/html/'
        const docSlug = '' + '' + this.invoice.id
        try {
          await openDocument(docUrl, docSlug)
          this.$emit('opened', { invoice: this.invoice, })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
a.dark-color {
  color: #222 !important;
}
</style>
