export function cellPhoneForSms(cellPhone: string): string {
  // retourne le numéro de téléphone brut : sans espace ni séparateur
  return cellPhone.replace(/\D/g, '')
}

export function rawPhone(phone: string): string {
  return phone.replace(/[.\s-]/g, '')
}

export function formattedPhone(phone: string): string {
  const numbers = rawPhone(phone)
  const groupsOf2 = numbers.match(/.{1,2}/g)
  if (groupsOf2) {
    return groupsOf2.join('.')
  } else {
    return phone
  }
}
