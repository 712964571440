<template>
  <div v-if="isAuthenticated">
    <b-row class="warning-version" v-if="versionError">
      <b-col>
        <i class="fas fa-exclamation-triangle"></i> {{versionError}}
      </b-col>
      <b-col cols="3" class="text-right">
        <a href class="btn btn-secondary btn-sm" @click="update">Mettre à jour</a>
      </b-col>
    </b-row>
    <div class="service-messages" v-for="item of visibleMessages" :key="item.id">
      <b-row>
        <b-col>
          <i class="fa fa-bell"></i>
          {{ item.message }}
          <a class="more-info" v-if="item.url" :href="item.url" target="_blank">En savoir plus</a>
        </b-col>
        <b-col cols="2" class="text-right">
          <a href class="btn btn-secondary btn-sm" @click.prevent="hideMessage(item)">
            Ne plus afficher
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import store from '@/store'
import { mapActions } from 'vuex'

export default {
  name: 'WarningVersion',
  mixins: [BackendMixin],
  props: {
    version: String,
  },
  data() {
    return {
      versionError: '',
      messages: [],
    }
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    visibleMessages() {
      const messages = []
      for (const item of this.messages) {
        if (!localStorage.getItem('hidden-message-' + item.id)) {
          messages.push(item)
        }
      }
      return messages
    },
  },
  watch: {
    isAuthenticated: function() {
      this.versionVerification()
    },
  },
  mounted() {
    this.verificationCycle()
  },
  methods: {
    ...mapActions(['addWarning']),
    async getBackendVersion() {
      if (this.isAuthenticated) {
        const backendApi = new BackendApi('get', '/api/backends/service-checks/?structure=1')
        try {
          const resp = await backendApi.callApi()
          const backendVersion = resp.data.version
          if (this.version !== backendVersion) {
            this.versionError = 'Une nouvelle version de millibase.net est disponible'
          }
          this.messages = resp.data.messages
          await store.commit('setServiceMessages', this.messages)
        } catch (err) {
          await this.addWarning(this.getErrorText(err))
        }
      }
    },
    async versionVerification() {
      await this.getBackendVersion()
    },
    verificationCycle() {
      this.versionVerification()
      const delayInSec = 15 * 60 // 15 minutes
      setInterval(this.versionVerification, delayInSec * 1000)
    },
    update() {
      window.location.reload()
    },
    hideMessage(item) {
      localStorage.setItem('hidden-message-' + item.id, '1')
      this.messages = [].concat(this.visibleMessages)
    },
  },
}
</script>

<style scoped>
.warning-version {
  background-color: #ffca22;
  position: relative;
  padding: 5px 5px 5px 95px;
  z-index: 1;
  margin-top: -5px;
  margin-left: -60px;
}
.service-messages {
  background-color: #db6c6c;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 45px 5px 90px;
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-top: 30px;
  margin-left: -50px;
}
a.more-info {
  color: #fff !important;
  text-decoration: underline;
}
</style>
