<template>
  <span class="confirm-modal">
    <b-modal
      :id="'bv-confirm-modal:' + name"
      cancel-title="Non"
      @ok="onConfirm"
      @cancel="onCancel"
      :ok-variant="okVariant"
      ok-title="Oui"
    >
      <template v-slot:modal-title>
        <i :class="icon" v-if="icon"></i>
        {{ title }}
      </template>
      <b-form ref="form">
        <span v-html="htmlText"></span>
        <div v-if="text2" class="confirm-text2">
          <span v-html="htmlText2"></span>
        </div>
      </b-form>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { htmlText } from '@/utils/html'

export default {
  name: 'ConfirmModal',
  components: {
  },
  props: {
    name: String,
    text: String,
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      defaultValue: 'Confirmation',
    },
    object: {
      type: Object,
      defaultValue: null,
    },
    text2: {
      type: String,
      defaultValue: '',
    },
    okVariant: {
      type: String,
      defaultValue: 'primary',
    },
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
    htmlText() {
      return htmlText(this.text)
    },
    htmlText2() {
      return htmlText(this.text2)
    },
  },
  methods: {
    onConfirm() {
      this.$emit('confirmed', { name: this.name, object: this.object, })
    },
    onCancel() {
      this.$emit('cancelled', { name: this.name, object: this.object, })
    },
  },
}
</script>
<style lang="less" scoped>
.confirm-text2 {
  margin-top: 10px;
}
</style>
