import store from '@/store'
import { BackendApi, getErrorText } from '@/utils/http'
import { makeUserPreferences, UserPreferences } from '@/types/users'

export async function loadPreferences() {
  let backendApi = new BackendApi('get', '/users/api/user-preferences/')
  try {
    const resp = await backendApi.callApi()
    const userPreferences: UserPreferences = makeUserPreferences(resp.data)
    await store.dispatch('setUserPreferences', userPreferences)
  } catch (err) {
    await store.dispatch('addWarning', getErrorText(err))
  }
}
