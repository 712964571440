<template>
  <span>
    <b-modal
      dialog-class="modal-md"
      :id="getModalId()"
      hide-footer
     >
      <template v-slot:modal-title>
        <b><i class="fas fa-sign-out-alt"></i> Déconnexion automatique</b>
      </template>
      <b-row>
        <p class="main-text">
          Vous allez être automatiquement déconnecté dans {{ remainingTimeInSeconds }} secondes.
        </p>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { incTabCounter } from '@/utils/auth'

export default {
  name: 'IdleDetector',
  mixins: [BackendMixin],
  data() {
    return {
      timeoutInSeconds: 900,
      secondsTimer: null,
      secondsCounter: 0,
      warning: false,
      lastTimestamp: 0,
    }
  },
  destroyed() {
    if (this.secondsTimer) {
      window.clearInterval(this.secondsTimer)
    }
  },
  mounted() {
    this.onInit()
    document.onclick = this.onUserAction
    document.onmousemove = this.onUserAction
    document.onkeypress = this.onUserAction
    document.onscroll = this.onUserAction
    this.onPageCreated()
    window.onunload = this.onLeaveApp
  },
  computed: {
    autoLogout() {
      return +store.getters.userPreferences.autoLogout
    },
    logoutOnClose() {
      return store.getters.userPreferences.logoutOnClose
    },
    remainingTimeInSeconds() {
      return this.timeoutInSeconds - this.secondsCounter
    },
    hasUser() {
      return store.getters.isAuthenticated
    },
  },
  watch: {
    autoLogout: function() {
      this.onInit()
    },
  },
  methods: {
    onLogout() {
      this.logout().then(() => { window.location.reload() })
    },
    getSecondsSinceLastMove() {
      // vérifie la valeur du dernier mouvement stocké dans le local storage pour gestion des différents onglets
      // retourne vrai si un événement a eu lieu sur un des onglets depuis moins que le timeout
      const current = Math.round(new Date().getTime() / 1000)
      let lastMove = window.localStorage.getItem('idle-detector-last-move')
      if (lastMove) {
        lastMove = parseInt(lastMove, 10)
        if (!isNaN(lastMove)) {
          if (this.lastTimestamp === 0) {
            this.lastTimestamp = lastMove
          }
          if (lastMove > this.lastTimestamp) {
            this.secondsCounter -= (lastMove - this.lastTimestamp)
          }
        }
      }
    },
    checkIdleTime() {
      if (this.hasUser && this.autoLogout) {
        this.secondsCounter++
        if (this.remainingTimeInSeconds > 30) {
          this.hideModal()
        }
        if (this.remainingTimeInSeconds <= 30) {
          this.getSecondsSinceLastMove()
          if (this.remainingTimeInSeconds <= 0) {
            this.hideModal()
            this.onLogout()
          } else if (this.remainingTimeInSeconds <= 30) {
            this.showModal()
          }
        }
      }
    },
    showModal() {
      if (!this.warning) {
        this.warning = true
        this.$bvModal.show(this.getModalId())
      }
    },
    hideModal() {
      if (this.warning) {
        this.$bvModal.hide(this.getModalId())
        this.warning = false
      }
    },
    onUserAction() {
      if (this.hasUser && this.autoLogout) {
        this.secondsCounter = 0
        const timestamp = Math.round(new Date().getTime() / 1000)
        if ((timestamp - this.lastTimestamp) > 30) {
          this.lastTimestamp = timestamp
          window.localStorage.setItem('idle-detector-last-move', '' + timestamp)
        }
        this.hideModal()
      }
    },
    onInit() {
      const timestamp = Math.round(new Date().getTime() / 1000)
      this.secondsCounter = 0
      this.timeoutInSeconds = this.autoLogout * 60
      window.localStorage.setItem('idle-detector-last-move', '' + timestamp)
      if (this.secondsTimer) {
        window.clearInterval(this.secondsTimer)
        this.secondsTimer = null
      }
      if (this.timeoutInSeconds) {
        this.secondsTimer = window.setInterval(this.checkIdleTime, 1000)
      }
    },
    getModalId() {
      return 'bv-modal-auto-logout'
    },
    onPageCreated() {
      incTabCounter(1)
    },
    onLeaveApp() {
      const remaining = incTabCounter(-1)
      if (remaining <= 0 && this.logoutOnClose) {
        this.logout().then()
      }
    },
  },
}

</script>

<style scoped>
.main-text {
  text-align: center;
  padding: 0 15px;
  font-size: 16px;
}
</style>
