import { diffTimes } from '@/utils/time'

export function hasLunch(
  arrivalAt: string, leavingAt: string, tracks: boolean, arrivalAt2: string, leavingAt2: string
): boolean {
  // Vérifie la présence à l'heure du repas et coche la case repas si besoin
  let hasLunch = false
  if (arrivalAt && leavingAt) {
    hasLunch = (
      (diffTimes('12:00', arrivalAt) >= 0) &&
      (diffTimes(leavingAt, '12:00') > 0)
    )
    if (!hasLunch && tracks && arrivalAt2 && leavingAt2) {
      hasLunch = (
        (diffTimes('12:00', arrivalAt2) >= 0) &&
        (diffTimes(leavingAt2, '12:00') > 0)
      )
    }
  }
  return hasLunch
}
