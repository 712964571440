export const screenModule = {
  state: {
    showSearch: false,
    showAddFamily: false,
  },
  getters: {
    showSearch: (state: any) => {
      return state.showSearch
    },
    showAddFamily: (state: any) => {
      return state.showAddFamily
    },
  },
  mutations: {
    showSearch(state: any, showSearch: boolean) {
      state.showSearch = showSearch
    },
    showAddFamily(state: any, showAddFamily: boolean) {
      state.showAddFamily = showAddFamily
    },
  },
  actions: {
  },
  modules: {
  },
}

export default screenModule
