
export class TabItem {
  constructor(
    public name: string,
    public label: string,
    public icon: string = '',
    public cssStyle: string = '',
    public selector: string = '',
    public category: number = 0 // filtre activités
  ) {
    if (selector === '') {
      this.selector = this.name
    }
  }
}
